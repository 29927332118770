import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Link } from "react-router-dom";
import MediumDropdownItem from "./MediumDropdownItem";

function MediumDropdown({options, onChange, link, data = null, acc=false, title = null}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [open, setOpen] = useState(false);

    const menuItems = options.map((option, index) => <MediumDropdownItem name={option.name} key={option.id} id={option.id} value={option.value} acc={acc} onClick={changeController} last={index===options.length-1?true:false}/>)

    const [option, setOption] = useState(options[0].name);

    function changeController(e){
      setOption(e.name);
      setOpen(false)
      toggleOptions();
      onChange(e.value)
    }

    function toggleOptions(){
      setOpen(!open);
    }

    return (
      <div className={`relative w-full h-full`}>
      {link &&
      <Link to={`/campaigns/${option}`} state={data}>
      <button className="absolute top-0 w-2/3 h-full space-y-0"></button>
      </Link>}
      <div className=" grid grid-cols-1 w-full h-full z-20 ">
    <button
    className={`  ${
      dark ? "text-neutral-100  " : "text-neutral-300 "
    } ${title !== null? dark?"bg-neutral-600 text-medium":"bg-white text-medium":"text-sm"} w-full h-full min-w-[120px] space-x-2 items-center ${open? "rounded-t-lg border-t border-x":"rounded-lg border"}  ${acc? "border-accent" :dark? "border-neutral-300":"border-neutral-600"}  hover:text-accent `}
  id="select"
  onClick={toggleOptions}>
    <div  className="flex justify-between items-center">
    <p className=" pl-2 w-4/5 py-1">{title === null ? option: title}</p>
    <div className="w-1/5 flex justify-center items-center">
    <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </div>
    </div>
  </button>
  <div>
    {open && <div className={`absolute w-full h-max min-w-[120px] space-y-2 border-b border-x rounded-b-lg ${acc?"border-accent":dark?"border-neutral-300 bg-neutral-900":"bg-white border-neutral-600"} `}><ul >{menuItems}</ul></div>}
    </div>
    </div>
  </div>)
}

export default MediumDropdown;