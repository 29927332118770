import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function Bell({active, onClick}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return <div onClick={onClick} className="relative flex group">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" fill="current" className={`absolute hover:animate-ping ${active ? "fill-red-1000" : (dark ? "fill-neutral-600" : "fill-neutral-200")}`}>
        {active && <path  d="M10.5 0C9.67 0 9 .67 9 1.5v.9a7.503 7.503 0 0 0-6 7.35v.881c0 2.203-.811 4.332-2.274 5.982l-.346.389a1.506 1.506 0 0 0-.249 1.612c.24.54.778.886 1.369.886h18c.59 0 1.125-.347 1.369-.886a1.496 1.496 0 0 0-.249-1.612l-.347-.39A9.013 9.013 0 0 1 18 10.633V9.75a7.503 7.503 0 0 0-6-7.35v-.9c0-.83-.67-1.5-1.5-1.5Zm2.123 23.123A3 3 0 0 0 13.5 21h-6a3 3 0 0 0 .876 2.123A3.001 3.001 0 0 0 10.5 24c.797 0 1.56-.314 2.123-.877Z"/>}
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" fill="current" className={`${active ? "fill-red-1000" : (dark ? "fill-neutral-300" : "fill-neutral-200")}`}>
        <path  d="M10.5 0C9.67 0 9 .67 9 1.5v.9a7.503 7.503 0 0 0-6 7.35v.881c0 2.203-.811 4.332-2.274 5.982l-.346.389a1.506 1.506 0 0 0-.249 1.612c.24.54.778.886 1.369.886h18c.59 0 1.125-.347 1.369-.886a1.496 1.496 0 0 0-.249-1.612l-.347-.39A9.013 9.013 0 0 1 18 10.633V9.75a7.503 7.503 0 0 0-6-7.35v-.9c0-.83-.67-1.5-1.5-1.5Zm2.123 23.123A3 3 0 0 0 13.5 21h-6a3 3 0 0 0 .876 2.123A3.001 3.001 0 0 0 10.5 24c.797 0 1.56-.314 2.123-.877Z"/>
        </svg>
    </div>
}

export default Bell;