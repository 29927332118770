import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function IndCallRow({ data, openModal, outcomes, type }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();

    const o = outcomes.filter(c => c.id === data.callOutcomeId);

    function openModalButton() {
        openModal(data.callComments)
    }

    const date = new Date(data.createdAt);

    return(
        <tr className={`w-min py-2 ${dark?"odd:bg-neutral-600 bg-neutral-900":"odd:bg-neutral-75 bg-white"} text-sm ${width<=640?"flex flex-col border-neutral-300 border":"text-center"} ${dark? "text-white" : "text-neutral-600"}`}>
    <td className={`${width>640?"pl-2 text-left py-2 ":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Date: </span>}{date.toLocaleDateString()}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Time: </span>}{date.getHours() + ":" + date.getMinutes()}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Duration: </span>}{data.duration}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Type: </span>}{type}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>In/Outbound: </span>}{data.outbound?"Outbound":"Inbound"}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Answered: </span>}{data.answered?"Yes":"No"}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Outcome: </span>}{o[0].name}</td>
    <td className={`${width>640?"text-left ":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Notes: </span>}{<button value={data.comments} onClick={openModalButton} disabled={data.comments === null} className="">{data.comments !== null?"View":"..."}</button>}</td>
    </tr>
    )
}

export default IndCallRow;