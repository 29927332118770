import { useNavigation, Form } from "react-router-dom";
import SolidButton from "./UI/SolidButton";
import TextInput from "./UI/TextInput";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Header from "../components/UI/Header";

function LoginForm({save, forgot = null, title}) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const navigation = useNavigation();
    const isSubmitting = navigation.state === 'submitting';

    function LogIn(event){
        save(event.target.email.value,event.target.password.value,event);
    }

    function forgottenPassword(){
        forgot();
    }

    return (
        <div className="w-full mt-10">
            <Form method="post" onSubmit={LogIn}>
                <Header>{title}</Header>
                <div className="mt-2 flex w-full space-y-2">
                    <div className="flex flex-col w-full gap-2">
                        <TextInput type="email" name="email" />
                        <TextInput type="password" name="password" />
                    </div>
                </div>
                {forgot!==null&&<p onClick={forgottenPassword} className={`pt-2 ${dark ? "text-neutral-300" : "text-neutral-300"} hover:underline cursor-pointer text-sm hover:text-accent`}>Forgot Password</p>}
                <div className="pt-6">
                    <SolidButton type="submit" disabled={isSubmitting} text={isSubmitting ? 'Submitting...' : 'Login'} />
                </div>
            </Form>
        </div>
    )
}

export default LoginForm;