import Toggle from "./UI/Toggle";
import { ThemeContext } from "../context/ThemeContext";
import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LargeCircleImage from "./UI/LargeCircleImage";
import TextInputLabeled from "./UI/TextInputLabeled";
import RoundedButton from "./UI/RoundedButton";
import { ChangeCurrentUserData, changePassword } from "../store/currentUser-actions";
import SolidButton from "./UI/SolidButton";

function SetTwoFactor() {
  const theme = useContext(ThemeContext);
  const { dark } = theme
  const userData = useSelector((state) => state.currentUser.item);
  const dispatch = useDispatch()
  const [user, setUser] = useState({email:userData.email,mobileNumber:userData.phoneNumber,enable2fa:userData.twoFactorEnabled});
  const [message, setMessage] = useState('')
  
  console.log(user);

  function changeDetails(e) {
    e.preventDefault();
    if(user.mobileNumber !== null || user.enable2fa === false){
      dispatch(ChangeCurrentUserData(user));
      if(user.enable2fa === false){
        setMessage("Two-factor authentification disabled.")
      }
      if(user.enable2fa === true){
        setMessage("Two-factor authentification enabled.")
      }
    }
  }

  function onChange(e) {
    setUser(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  function toggle(t){
    setUser(prevState => ({
      ...prevState,
      enable2fa: t
    }))
  }

  return (
    <div className="fixed h-screen w-screen mt-20">
      <div className="relative w-full z-10">
        <LargeCircleImage image={"M137.808 63.6512C137.762 62.9012 137.467 62.1889 136.972 61.6282C136.477 61.0675 135.81 60.6908 135.079 60.5585C117.122 57.3021 109.625 54.9479 92.8681 47.298C92.4381 47.1016 91.9718 47 91.5 47C91.0282 47 90.5618 47.1016 90.1319 47.298C73.3748 54.9479 65.8785 57.3021 47.9214 60.5585C47.1896 60.6908 46.5228 61.0675 46.0279 61.6282C45.533 62.1889 45.2385 62.9012 45.1915 63.6512C44.3923 76.4731 46.0966 88.4202 50.2631 99.1607C53.675 107.922 58.7243 115.936 65.1353 122.767C76.2355 134.67 88.0207 139.84 90.269 140.755C91.0679 141.082 91.9612 141.082 92.7601 140.755C95.0084 139.84 106.794 134.67 117.894 122.767C124.294 115.934 129.334 107.919 132.737 99.1607C136.903 88.4202 138.608 76.4731 137.808 63.6512ZM110.613 79.4106L87.6117 106.267C87.316 106.614 86.9533 106.895 86.546 107.095C86.1386 107.294 85.6953 107.407 85.2431 107.428H85.106C84.2367 107.428 83.402 107.084 82.781 106.469L72.5672 96.3555C72.2556 96.0469 72.0072 95.6793 71.8362 95.2736C71.6651 94.868 71.5748 94.4323 71.5704 93.9914C71.5614 93.1009 71.9028 92.2433 72.5195 91.6073C73.1361 90.9712 73.9775 90.6088 74.8585 90.5998C75.7396 90.5907 76.5881 90.9358 77.2174 91.559L84.8984 99.1733L105.596 75.017C106.172 74.3437 106.99 73.9294 107.868 73.8652C108.747 73.8011 109.615 74.0924 110.281 74.675C110.947 75.2576 111.357 76.0839 111.421 76.972C111.484 77.8601 111.196 78.7373 110.62 79.4106H110.613Z"} />
      </div>
      <div
        className={`relative my-6 mx-auto max-w-7xl  mt-32 pt-28 ${dark ? "bg-neutral-900" : "bg-white"} px-6 py-2 rounded-lg`}>
        <div className={`mb-6 space-y-4`}>
          <h2 className={`text-accent font-bold text-lg`}>
            Two-factor authentication
          </h2>
          <p className={`${dark ? "text-white" : "text-neutral-600"} text-sm`}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.{" "}
          </p>
          <form onSubmit={changeDetails}>
          <div className="flex justify-start space-x-6 items-center">
            <TextInputLabeled required={false} onChange={onChange} id={"phoneNumber"} key="phoneNum" name="Input your phone number:" />
            <div className="flex flex-col mb-2">
              <label className={` text-sm text-neutral-300 pb-2`}>Permission:</label>
              <Toggle onChange={toggle}  initial={user.enable2fa}/>
            </div>
          </div>
            <div className="pt-2 flex space-x-4 items-end">
              <SolidButton text={"Confirm"} />
              <p className={` ${dark?"text-white":"text-black"}`}>{message}</p>
              </div>
              </form>
        </div>
      </div>
    </div>
  )
}

export default SetTwoFactor;