import Sidebar from "../Sidebar";
import CampaignHolder from "./CampaignHolder";
import CampaignHeader from "./CampaignHeader";
import { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { campaignActions } from "../../store/campaign-slice";
import { useDispatch } from "react-redux";
import CampaignFilter from "./CampaignFilter";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useSelector } from "react-redux";
import { groupBy } from "../../hooks/groupBy";
import Sort from "../UI/Sort";
import { GetReportingCount, GetIndividualCount } from "../../store/reporting-actions";

const listElements = [
    {
        name: "All",
        image: "M2 6C2 5.4696 2.2107 4.9609 2.5858 4.5858 2.9609 4.2107 3.4696 4 4 4H8L10 6H14C14.5304 6 15.0391 6.2107 15.4142 6.5858 15.7893 6.9609 16 7.4696 16 8V9H8C7.2043 9 6.4413 9.3161 5.8787 9.8787 5.3161 10.4413 5 11.2044 5 12V13.5C5 13.8978 4.842 14.2794 4.5607 14.5607 4.2794 14.842 3.8978 15 3.5 15 3.1022 15 2.7206 14.842 2.4393 14.5607 2.158 14.2794 2 13.8978 2 13.5V6ZM6 12C6 11.4696 6.2107 10.9609 6.5858 10.5858 6.9609 10.2107 7.4696 10 8 10H16C16.5304 10 17.0391 10.2107 17.4142 10.5858 17.7893 10.9609 18 11.4696 18 12V14C18 14.5304 17.7893 15.0391 17.4142 15.4142 17.0391 15.7893 16.5304 16 16 16H2 4C4.5304 16 5.0391 15.7893 5.4142 15.4142 5.7893 15.0391 6 14.5304 6 14V12Z",
        id: "all",
    },
    {
        name: "Ongoing",
        image: "M8 2C7.7348 2 7.4804 2.1054 7.2929 2.2929 7.1054 2.4804 7 2.7348 7 3 7 3.2652 7.1054 3.5196 7.2929 3.7071 7.4804 3.8946 7.7348 4 8 4H10C10.2652 4 10.5196 3.8946 10.7071 3.7071 10.8946 3.5196 11 3.2652 11 3 11 2.7348 10.8946 2.4804 10.7071 2.2929 10.5196 2.1054 10.2652 2 10 2H8ZM3 5C3 4.4696 3.2107 3.9609 3.5858 3.5858 3.9609 3.2107 4.4696 3 5 3 5 3.7957 5.3161 4.5587 5.8787 5.1213 6.4413 5.6839 7.2043 6 8 6H10C10.7956 6 11.5587 5.6839 12.1213 5.1213 12.6839 4.5587 13 3.7957 13 3 13.5304 3 14.0391 3.2107 14.4142 3.5858 14.7893 3.9609 15 4.4696 15 5V11H10.414L11.707 9.707C11.8892 9.5184 11.99 9.2658 11.9877 9.0036 11.9854 8.7414 11.8802 8.4906 11.6948 8.3052 11.5094 8.1198 11.2586 8.0146 10.9964 8.0123 10.7342 8.01 10.4816 8.1108 10.293 8.293L7.293 11.293C7.1055 11.4805 7.0002 11.7348 7.0002 12 7.0002 12.2652 7.1055 12.5195 7.293 12.707L10.293 15.707C10.4816 15.8892 10.7342 15.99 10.9964 15.9877 11.2586 15.9854 11.5094 15.8802 11.6948 15.6948 11.8802 15.5094 11.9854 15.2586 11.9877 14.9964 11.99 14.7342 11.8892 14.4816 11.707 14.293L10.414 13H15V16C15 16.5304 14.7893 17.0391 14.4142 17.4142 14.0391 17.7893 13.5304 18 13 18H5C4.4696 18 3.9609 17.7893 3.5858 17.4142 3.2107 17.0391 3 16.5304 3 16V5ZM15 11H17C17.2652 11 17.5196 11.1054 17.7071 11.2929 17.8946 11.4804 18 11.7348 18 12 18 12.2652 17.8946 12.5196 17.7071 12.7071 17.5196 12.8946 17.2652 13 17 13H15V11Z",
        id: "ongoing",
    },
    {
        name: "Completed",
        image: "M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z",
        id: "complete",
    },
]

function Campaigns() {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({ filter: "", search: "", typeFilter: [] });
    const [showFilter, setShowFilter] = useState(false);
    const { width } = useWindowDimensions();
    const ca = useSelector((state) => state.campaign.campaigns);
    const siteCampaigns = useSelector((state) => state.campaign.siteCampaigns);
    const types = useSelector((state) => state.campaign.type);
    const siteCampaignCommTemplates = useSelector((state) => state.campaign.siteComms);
    const reports = useSelector((state) => state.reporting.counts);
    const site = useSelector((state) => state.currentUser.currentSite)
    const time = useSelector((state) => state.time.searchTime);
    const [campaigns] = useState(ca!==undefined?ca:[]);
    const [act, setAct] = useState(true);
    const [filteredCamps,setFilteredCamps] = useState([]);

    useEffect(()=>{
        changeFilters();
    },[])

    useEffect(()=>{
        changeFilters();
    },[campaigns, setFilteredCamps, filters, reports, siteCampaigns, siteCampaignCommTemplates])

    function changeFilters(){
        if(campaigns.length !== 0){
            if(filters.filter ==="" || filters.filter ==="all"){
                setFilteredCamps(campaigns)
            }else if(filters.filter === "ongoing"){
                const filteredReports = reports.filter(r => r.num !== 0)
                const reportNames = filteredReports.map(r => r.id)
                const camp = campaigns.filter(c=>reportNames.includes(c.id));
                
                const list = []
                camp.forEach(element => {
                    const ca = siteCampaigns.find(c => c.siteId === site && c.campaignId === element.id);
                    const rightComm = siteCampaignCommTemplates.find(c => c.siteCampaignsId === ca.id);
                    const sentRep = reports.find(r => r.id === rightComm.id);
                    const rep = filteredReports.find(r => r.id === element.id);
                    if(sentRep.num < rep.num){
                        list.push(element);
                    }
                });

                setFilteredCamps(list);
            }else{
                const filteredReports = reports.filter(r => r.num !== 0)
                const reportNames = filteredReports.map(r => r.id)
                const camp = campaigns.filter(c=>reportNames.includes(c.id));
                
                const list = []
                camp.forEach(element => {
                    const ca = siteCampaigns.find(c => c.siteId === site && c.campaignId === element.id);
                    const rightComm = siteCampaignCommTemplates.find(c => c.siteCampaignsId === ca.id);
                    const sentRep = reports.find(r => r.id === rightComm.id);
                    const rep = filteredReports.find(r => r.id === element.id);
                    if(sentRep.num === rep.num){
                        list.push(element);
                    }
                });

                setFilteredCamps(list);
            }
        }
    }

    const a = types.filter(t => t.action === true);
    const actionIds = a.map(t => t.id);
    const cam = act ? filteredCamps.filter(c => actionIds.includes(c.id)) : filteredCamps.filter(c => !actionIds.includes(c.id));

    function toggleAction() {
        setAct(!act);
    }

    const yearCamps = groupBy(cam, "year");
    const y = Object.values(yearCamps);
    const years = Object.keys(yearCamps);
    const map = y.map((i, index) => <CampaignHolder key={index} campaigns={i} years={years[index]} toggleAction={toggleAction} act={act} siteCampaignCommTemplates={siteCampaignCommTemplates} />)

    const updates = filteredCamps.map(i => new Date(i.modifiedAt));
    updates.sort((a, b) => b - a);

    const [sortFilter, setSortFilter] = useState(updates);

    function sort(s) {
        setSortFilter(s)
    }

    function changeFilter(e) {
        setFilters(prevFilter => {
            return {
                ...prevFilter,
                filter: e
            }
        });
    }

    function changeSearch(e) {
        setFilters(prevFilter => {
            return {
                ...prevFilter,
                search: e
            }
        });
    }

    function changeType(e) {
        if (filters.typeFilter.includes(e)) {
            setFilters(prevFilter => {
                return {
                    ...prevFilter,
                    typeFilter: prevFilter.typeFilter.filter(f => f !== e)
                }
            });
        } else {
            setFilters(prevFilter => {
                return {
                    ...prevFilter,
                    typeFilter: prevFilter.typeFilter.concat(e)
                }
            });
        }
    }

    function openFilter() {
        setShowFilter(!showFilter);
    }


    return (
        <div className={`relative mx-auto ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg max-w-7xl mb-10 justify-center items-center shadow-md mt-20`}>
            {showFilter && <CampaignFilter open={openFilter} filter={changeType} />}
            {width >= 640 ? <div className="flex h-auto ">
                <div className={`w-1/6 left-0 relative border-r-2 ${dark ? "border-neutral-800" : "border-neutral-200"}`}>
                    <div className="py-6">
                        <h1 className={`text-accent text-lg font-bold pl-6`}>View by status</h1>
                        <Sidebar listElements={listElements} filter={changeFilter} />
                    </div>
                </div>
                <div className="w-5/6 py-6">
                    <CampaignHeader filter={changeSearch} toggleAction={toggleAction} open={openFilter} width={width} sort={sort} years={years} updates={updates} act={act} />
                    <div className="right-0 py-2 min-h-screen w-full">
                        <Sort by={sortFilter}>
                            {map}
                        </Sort>
                    </div>
                </div>
            </div>
                :
                <div className="flex flex-col h-auto w-full">
                    <div className={` border-b-2 ${dark ? "border-neutral-800" : "border-neutral-200"}`}>
                        <div className="py-4 w-full">
                            <h1 className={`text-accent w-full text-center text-lg font-bold`}>View by status</h1>
                            <Sidebar listElements={listElements} filter={changeFilter} />
                        </div>
                    </div>
                    <div className=" py-8">
                        <CampaignHeader filter={changeSearch} open={openFilter} width={width} />
                        <div className="right-0 py-2 min-h-screen w-full">
                            {map}
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default Campaigns;