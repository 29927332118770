import { Fragment } from "react";
import SkeletonInput from "../UI/SkeletonInput";

function TableCell({ cont }) {
    return (
        <Fragment>
            {cont !== null && cont !== "" && cont !== undefined ?
                <td className="px-4 py-4">{cont}</td> :
                <td className="px-4 py-4"><SkeletonInput /></td>
            }
        </Fragment>
    )
}

export default TableCell;