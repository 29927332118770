import { useContext, useEffect, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { GetIndividualCount } from "../../store/reporting-actions";
import CampClicks from "./CampClicks";
import ProgressBar from "../UI/ProgressBar";

function IndividualCampaignEvent({siteComms,width,campaignId}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const dispatch = useDispatch();
    const comms = useSelector((state) => state.campaign.comms);
    const reports = useSelector((state) => state.reporting.counts);
    const opensClicks = useSelector((state) => state.reporting.opensClicks);
    const time = useSelector((state) => state.time.searchTime);
    const [rep,setRep]= useState({num:0});
    const [camp,setCamp]=useState({num:100});
    const [clickOpen,setClickOpen]=useState({opens:0,clicks:0});
    const [curComm] = useState(comms[0]!==undefined?comms:[])

    const comm = curComm.filter(c => c.id === siteComms.campaignCommsId)[0];

    useEffect(()=>{
        //dispatch(GetIndividualCount({siteCommId:siteComms,date:time}));

        if(reports.length !== 0&&reports[0]!==null&&reports[0]!==undefined){
            const campaignAmount = reports.find(c => c.id === campaignId);
            const peopleCampaigned = reports.find(c => c.id === siteComms.id);
            const opcl = opensClicks.find(c => c.id === siteComms.id);
            if(campaignAmount!==undefined){
                setCamp(campaignAmount);
            }
            if(peopleCampaigned!==undefined){
                setRep(peopleCampaigned);
            }
            if(opcl!==undefined){
                setClickOpen(opcl);
            }
        }


    },[dispatch, time, siteComms, comm, reports, opensClicks, campaignId])

    
    const prog = (rep.num / 100) * camp.num;

    return (
        <li className={`flex px-4 pb-1 items-center justify-between ${dark ? "bg-neutral-800 odd:bg-neutral-750 " : " odd:bg-neutral-100 bg-neutral-75"}`}>
            <div className="flex items-center w-full">
            <p className="w-1/12 text-accent font-semibold">{comm!==undefined&&comm.type}</p>
            <p className={`pl-4 text-sm ${dark?"text-neutral-300":""}`}>{rep.num+" sent"}</p>
            </div>
            <div className="w-1/3 flex justify-end pr-4">
                {comm!==undefined&&comm.type === "EMAIL" && <CampClicks opens={clickOpen.opens} clicks={clickOpen.clicks} recipients={camp.num} width={width} />}
            </div>
            <div className="w-2/3 ">
                <p className={`${dark ? "text-neutral-200" : "text-neutral-800"} text-sm pt-1 pb-0.5`}>Progress:</p>
                <ProgressBar colour={"bg-yellow-400"} progress={prog} />
            </div>
        </li>
    )
}

export default IndividualCampaignEvent;