import AccordionHeader from "./AccordionHeader";
import { useState } from "react";

function Accordion({items}){
        const [active, setActive] = useState()

    function changeActive(id){
        setActive(id)
    }

    const mappedItems = items.map((i, index) => <AccordionHeader key={i.title} length={items.length} index={index} content={i.content} title={i.title} onChange={changeActive} active={active}/>)

    return(

<ul className="relative my-6 mx-auto max-w-7xl overflow-x-auto">
        {mappedItems}
</ul>
)
}

export default Accordion;