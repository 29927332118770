import AccordionBody from "./AccordionBody";
import { useState } from "react";
import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function AccordionHeader({title, content, index, length, active, onChange}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
    const [open,setOpen] = useState(false);

    function openHandler(){
      if(active !== title){
        setOpen(true);
      }
      else{
        setOpen(!open);
      }
        onChange(title)
    }  

    return(
        <li key={index}>
        <h2 id={title}>
        <button type="button" onClick={openHandler} className={`flex items-center justify-between w-full py-2 px-5 font-medium text-left ${dark ? "bg-neutral-900 text-white focus:ring-neutral-600 border-neutral-300 hover:bg-neutral-800" : "bg-white text-neutral-900 focus:ring-gray-200 border-gray-200 hover:bg-neutral-100"} border  ${index===0?"rounded-t-xl":""} ${index === (length - 1) && !open ? "rounded-b-xl" : "border-b-0"} focus:ring-4 `} data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
          <span>{title}</span>
          <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
      </h2>
      {active === title && open && <AccordionBody title={title} content={content}/>}
      </li>
    )
}

export default AccordionHeader;