import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TickBoxDropdownItem({id, name, value, onClick, ticked, last}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(ticked.includes(name));

  function clickHandler(){
      setClicked(!clicked);
      onClick({name, value});
  }

    function hoverOn(){
      setHover(true)
    }
    function hoverOff(){
      setHover(false)
    }

    return (
    <li onMouseEnter={hoverOn} onMouseLeave={hoverOff} key={id} className={`my-2 h-full w-full flex justify-center ${last&&" rounded-b-lg "}`}>
      <div className="w-full h-full pl-2">
      <button className="inline-flex hover:cursor-pointer items-center w-full " onClick={clickHandler}>
            <div className={`mt-1 ml-0.5`}>
     <div className={`relative w-4 h-4 border-2 ${hover?"border-accent":"border-neutral-300"} inline-block `}>
     {clicked && 
        <div className={`absolute w-full h-full font-bold bottom-2 ${hover?"text-accent":"text-neutral-300"}`}>x</div>
     }
     </div>
        </div>
            <p className={`text-left ml-4 text-md ${dark? "text-neutral-100" : "text-neutral-600"}`}>{name}</p>
        </button>
      </div>
    </li>
    )
}

export default TickBoxDropdownItem;