import { useState, Fragment } from "react";
import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function Toggle({onChange, initial}) {
  const [on, setOn] = useState(initial);
  const theme = useContext(ThemeContext);
  const {dark} = theme;

  function changeHandler(){
    onChange(!on)
    setOn(!on)
  }

  return (
    <Fragment>
    <label className="relative inline-flex items-center  cursor-pointer">
      <input
        type="checkbox"
        value={on}
        className="sr-only peer"
        onChange={changeHandler}
      />
      <div
        className={`inline-flex w-20 transform items-center text-neutral-300 ${!on && 'text-white after:translate-x-16 after:right-0 after:border-white'} h-6  ${on ? "bg-green-1000": "bg-red-1000"} rounded-full peer-focus:ring-4 ${dark ? "peer-focus:ring-neutral-600" : "peer-focus:ring-neutral-300"} after:content-[''] after:absolute after:top-0.5 after:left-0 after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all`}
      >
      </div>
      <div className={`absolute font-medium select-none text-xs pl-1 left-1 text-white ${!on && 'text-transparent'}`}>
        {!on&&" Disabled "}
      </div>
      <div className="absolute font-medium select-none text-xs right-1 px-1 text-transparent text-white">
        {on&&" Enabled "}
      </div>
    </label>
    </Fragment>
  );
}

export default Toggle;
