import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

function ChatOption({ onClick, option, active }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;

    function clickHandler() {
        onClick(option)
    }

    return (
        <li onClick={active ? clickHandler : () => {  }} className={` text-sm last:rounded-b-md border-t ${dark ? active ? "bg-neutral-900 hover:bg-neutral-600 text-white border-neutral-500" : "bg-neutral-900  text-white border-neutral-500" : (active ? "bg-white  text-neutral-300" : "bg-white hover:bg-neutral-200 text-neutral-300")} p-0.5 pl-1`}>{option}</li>
    )
}

export default ChatOption;