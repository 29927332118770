import NotifHistory from "../components/NotifHistory";
import TransitionWrapper from "../components/TransitionWrapper";

function HistoryPage({ show }) {
    return (
        <TransitionWrapper show={show}>
            <NotifHistory />
        </TransitionWrapper>
    )
}

export default HistoryPage;