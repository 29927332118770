import { createSlice } from "@reduxjs/toolkit";

const initialVehicleState = {
    items: [],
    mots: [],
    maps: [],
    dvla: [],
    purchases: [],
    services: []
}

const vehicleSlice = createSlice({
    name: 'vehicles',
    initialState: initialVehicleState,
    reducers: {
        replaceVehicle(state, action) {
            const newMap = action.payload.maps;
            const mapIds = state.maps.map(a => a.id);
            for (let index = 0; index < newMap.length; index++) {
                const element = newMap[index];
                if (!mapIds.includes(element.id)) {
                    state.maps.push(element);
                }
            }
            const newItems = action.payload.items;
            const itemIds = state.items.map(i => i.id);
            for (let index = 0; index < newItems.length; index++) {
                const element = newItems[index];
                if (!itemIds.includes(element.id)) {
                    state.items.push(element);
                }

            }
            const newMot = action.payload.mots;
            const motIds = state.mots.map(i => i.id);
            for (let index = 0; index < newMot.length; index++) {
                const element = newMot[index];
                if (!motIds.includes(element.id)) {
                    state.mots.push(element);
                }
            }
            const newDvla = action.payload.dvla;
            const dvlaIds = state.dvla.map(i => i.registrationNumber);
            for (let index = 0; index < newDvla.length; index++) {
                const element = newDvla[index];
                if (!dvlaIds.includes(element.registrationNumber)) {
                    state.dvla.push(element);
                }
            }
            const newPurchases = action.payload.purchases;
            const purIds = state.purchases.map(i => i.id);
            for (let index = 0; index < newPurchases.length; index++) {
                const element = newPurchases[index];
                if (!purIds.includes(element.id)) {
                    state.purchases.push(element);
                }
            }
            const newServices = action.payload.services;
            const serIds = state.services.map(i => i.id);
            for (let index = 0; index < newServices.length; index++) {
                const element = newServices[index];
                if (!serIds.includes(element.id)) {
                    state.services.push(element);
                }
            }
        },
        addVehicle(state, action) {
            const newItem = action.payload.vehicle;
            newItem.createdAt = newItem.createdAt.toString();
            state.items.push(newItem);
            const newMap = action.payload.vehicleMap;
            newMap.createdAt = newMap.createdAt.toString();
            state.maps.push(newMap);
        },
        editVehicle(state, action) {
            const newItem = action.payload.vehicle;
            const exsistingItem = state.items.find(item => item.id === newItem.id);
            if (exsistingItem && newItem !== exsistingItem) {
                newItem.modifiedAt = newItem.modifiedAt.toString();
                state.items = state.items.filter(item => item.id !== exsistingItem.id);
                state.items.push(newItem);
            }

        },
        removeFromProspecting(state, action) {
            const id = action.payload;
            state.changed = true;
            state.items = state.items.filter(item => item.id !== id);
        }
    }
});

export const vehicleActions = vehicleSlice.actions;
export default vehicleSlice;