import { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TableColumn({ name, id, onClick, multiple }) {
  const [dir, setDir] = useState("none");
  const [on, setOn] = useState(false);
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  function clickHandler() {
    if (!multiple) {
      if (dir === "asc") {
        setDir("des");
      } else if (dir === "none") {
        setDir("asc");
      } else {
        setDir("none")
      }
      onClick(dir, id);
    }
    else {
      if (on) {
        onClick(dir, id, false);
        setOn(false);
      } else {
        setOn(true)
        onClick(dir, id, true);
      }

    }
  }

  return (
    <th scope="col" className="px-4 py-3 w-max">
      <div className="flex items-center">
        <button id={name} className={`text-sm flex items-center ${dark ? "text-neutral-300" : "text-neutral-300"}  `} type="button" onClick={clickHandler}>
          {name}
          {multiple !== true && <svg data-accordion-icon className={`w-6 h-6 pt-1 ${dir === "des" ? "rotate-180" : ""} shrink-0 ${dir !== "none" ? "fill-accent" : "fill-neutral-300 opacity-50"} `} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>}
          {multiple === true && on && <svg data-accordion-icon className={`w-6 h-6 pt-1 shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>}
        </button>
      </div>
    </th>
  );
}

export default TableColumn;