import { createSlice } from "@reduxjs/toolkit";

const initialTimeState = {
    currentTime: new Date().toISOString(),
    searchTime: new Date().toISOString(),
}

const timeSlice = createSlice({
    name: 'time',
    initialState: initialTimeState,
    reducers: {
        changeSearchTime(state, action) {
            console.log(action.payload);
            state.searchTime = action.payload;
        },
    }
});

export const timeActions = timeSlice.actions;
export default timeSlice;