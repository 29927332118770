import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import ColouredSolidButton from "../components/UI/ColouredSolidButton"
import { useDispatch, useSelector } from "react-redux";
import TitleColonMessage from "../components/UI/TitleColonMessage";
import { CreateAppointment, DeleteAppointment } from "../store/booking-actions";

function BookingAlreadyPage(){
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const { dark } = useContext(ThemeContext);
    const cur = useSelector(state => state.booking.currentAppointment);
    const info = useSelector(state => state.booking.foundAppointment);
    console.log(info)
    console.log(cur)

    const date = new Date(info.at).toLocaleDateString();
    const hours = new Date(info.at).getHours();
    const minutes = new Date(info.at).getMinutes();

    function confirmed(){
      navigate("/prospecting");
    }
    
    function deleteApp(){
      dispatch(DeleteAppointment(info));
      dispatch(CreateAppointment(cur));
      navigate("/prospecting");
    }

    return(
        <div className="flex justify-center mt-20">
        <div className={`relative mx-auto p-8 ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg w-2/3 mb-10 shadow-md`}>
        <div className="h-full w-full flex items-center">
        <div className="h-fit w-full">
          <div className="md:w-1/2  h-4/6 mx-auto  relative">
            <div className={`w-full h-full px-4 border md:p-2 pb-4 rounded-lg border-neutral-300 relative`}>
              <p className={`font-semibold text-lg ${dark?"text-white":"text-neutral-900"}`}>An appointment has already been booked for this customer, keep it instead?</p>
            <div className="grid grid-cols-2 mx-auto gap-4 md:py-2">
            <TitleColonMessage title={"Surname"} message={info.lastName}/>
            <TitleColonMessage title={"Email"} message={info.email}/>
            <TitleColonMessage title={"Mobile"} message={info.tel}/>
            <TitleColonMessage title={"Service"} message={info.serviceName}/>
            <TitleColonMessage title={"Date"} message={date}/>
            <TitleColonMessage title={"Time"} message={hours+":"+minutes}/>
            </div>
          <div className="pt-1 md:pt-0 mx-auto w-3/4">
          <ColouredSolidButton text={"Keep Old Appointment"} colour={"bg-green-1000"} onClick={confirmed}/>
          <ColouredSolidButton text={"Delete Old Appointment"} colour={"bg-red-1000"} onClick={deleteApp}/>
          </div>
            </div>
          </div>
          </div>
        </div>
        </div>
        </div>
    )
}

export default BookingAlreadyPage;