import Sidebar from "../Sidebar";
import Header2 from "../UI/Header2";
import Header3 from "../UI/Header3";
import { useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Link } from "react-router-dom";
import TitleColonMessage from "../UI/TitleColonMessage";
import SpreadStats from "../SpreadStats";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { GetUnsubs, GetIndividualCount } from "../../store/reporting-actions";

const listElements = [
    {
        name: "Back",
        image: "M9.70679 16.7074C9.51926 16.8949 9.26495 17.0002 8.99979 17.0002C8.73462 17.0002 8.48031 16.8949 8.29279 16.7074L2.29279 10.7074C2.10532 10.5199 2 10.2656 2 10.0004C2 9.73524 2.10532 9.48094 2.29279 9.29341L8.29279 3.29341C8.48139 3.11125 8.73399 3.01045 8.99619 3.01273C9.25838 3.01501 9.5092 3.12018 9.6946 3.30559C9.88001 3.491 9.98518 3.74181 9.98746 4.00401C9.98974 4.2662 9.88894 4.5188 9.70679 4.70741L5.41379 9.00041H16.9998C17.265 9.00041 17.5194 9.10576 17.7069 9.2933C17.8944 9.48084 17.9998 9.73519 17.9998 10.0004C17.9998 10.2656 17.8944 10.52 17.7069 10.7075C17.5194 10.8951 17.265 11.0004 16.9998 11.0004H5.41379L9.70679 15.2934C9.89426 15.4809 9.99957 15.7352 9.99957 16.0004C9.99957 16.2656 9.89426 16.5199 9.70679 16.7074Z",
        id: "back",
        link: "/campaigns"
    }
]

const DUMMY_LINK = [
    {
        link: "http://HTML:EVENT23|",
        clicks: 8
    },
    {
        link: "https://www.dealership.vauxhall.com/event",
        clicks: 0
    },
]

function CampaignDetails() {
    let { state } = useLocation();
    const dispatch = useDispatch();
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();
    const rep = useSelector((state) => state.reporting.counts);
    const badRep = useSelector((state) => state.reporting.unsubscribeSpamBounce);
    const opens = useSelector((state) => state.reporting.opensClicks);
    const [report] = useState(rep!==undefined?rep:[])
    const amt = report.find(r => r.id === state.campaign.id);
    const [bad, setBad] = useState({unsub: 0,bounce:0,spam:0});
    const [opensClicks, setOpensClicks] = useState({opens: 0,clicks:0});

    useEffect(()=>{
        if(badRep.length!== 0){
            const list = [];
            state.comms.forEach(element => {
                const f = badRep.find(b => element.id.includes(b.id));
                if(f!==undefined){
                list.push(f);
                }
            });
        if(list.length !== 0){
            let u = 0;
            let b = 0;
            let s = 0;
            list.forEach(element => {
                u += element.unsub;
                b += element.bounce;
                s += element.spam;
            });
            setBad({unsub:u,bounce:b,spam:s});
        }
        }
    },[badRep, state.comms])
    
    useEffect(()=>{
        if(opens.length!== 0){
            const list = [];
            state.comms.forEach(element => {
                const f = opens.find(b => element.id.includes(b.id));
                if(f!==undefined){
                list.push(f);
                }
            });
        if(list.length !== 0){
            let o = 0;
            let c = 0;
            list.forEach(element => {
                o += element.opens;
                c += element.clicks;
            });
            setOpensClicks({opens:o,clicks:c});
        }
        }
    },[opens, state.comms])

    useEffect(()=>{
        state.comms.forEach(element => {
        dispatch(GetIndividualCount({siteCommId:element.id,date:state.date}))
        });
    },[dispatch, state])

    useEffect(()=>{
        state.comms.forEach(element => {
            dispatch(GetUnsubs({siteCommId: element.id, date: state.date }))
        });
    },[state, dispatch])

    const DUMMY_EMAIL_STATS = [
        {
            name: "Recipients",
            number: amt!==undefined?amt.num:0,
            time: null,
        },
        {
            name: "Unsubscribed",
            number: bad.unsub,
            time: null,
        },
        {
            name: "Bounced",
            number: bad.bounce,
            time: null,
        },
        {
            name: "Opened",
            number: opensClicks.opens,
            time: null,
        },
    ]
    const DUMMY_CLICK_STATS = [
        {
            name: "Clicked",
            number: opensClicks.opens,
            time: null,
        },
        {
            name: "Appointments",
            number: 0,
            time: null,
        },
        {
            name: "Spam Reports",
            number: bad.spam,
            time: null,
        },
        {
            name: "Didn't Open",
            number: amt!==undefined?amt.num - opensClicks.opens:0,
            time: null,
        },
    ]    

    const linksClicked = DUMMY_LINK.map((link) => (<li key={link.link} className="flex flex-wrap py-2"><p className={`font-bold ${dark ? "text-white" : "text-black"} pr-2 `}>{link.clicks + " -"}</p><p className={`${dark ? "text-white" : "text-black"} text-ellipsis overflow-hidden`}>{link.link}</p></li>))

    const date = new Date(state.time);

    return (
        <div className="w-full h-full fixed mt-20">
            <div className="w-full flex items-center mt-20">
                {width > 640 ? <div className={`relative max-w-5xl mx-auto ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg w-3/4 mb-10 justify-center items-center shadow-md`}>
                    <div className="flex">
                        <div className={`relative w-1/6 left-0 border-r-2 pt-10 ${dark ? "border-neutral-800" : "border-neutral-200"}`}>
                            <Sidebar listElements={listElements} />
                        </div>
                        <div className="w-5/6 ">
                            <div className="border-b pb-2 pt-6 pl-8 border-neutral-300">
                                <h1 className={`${dark ? "text-white" : "text-black"} font-bold text-lg`}>{state.campaign.name}</h1>
                                <Header2>Overview Activity</Header2>
                            </div>
                            <SpreadStats stats={DUMMY_EMAIL_STATS} />
                            <div className="grid grid-cols-2 gap-4 pb-6 border-b mb-4 pl-8 border-neutral-300">
                                <TitleColonMessage title="Name" message={state.campaign.name} />
                                <div className="flex">
                                    <Link><p className={` text-red-1000 pr-2 hover:underline`}>View Email</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                    <Link><p className={` text-red-1000 pr-2 hover:underline`}>Download</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                    <Link><p className={` text-red-1000 pr-2 hover:underline`}>Print</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                    <Link><p className={` text-red-1000 pr-2 hover:underline`}>Share</p></Link>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 pb-6 pt-2 border-b pl-8 border-neutral-300">
                                <TitleColonMessage title="Successful Deliveries" message={state.recipients} />
                                <TitleColonMessage title="Delivered" message="und" />
                                <TitleColonMessage title="Total Opens" message={opensClicks.opens} />
                                <TitleColonMessage title="Total Clicks" message={opensClicks.clicks} />
                            </div>
                            <SpreadStats stats={DUMMY_CLICK_STATS} />
                            <div className="pl-8 pb-6">
                                <Header2>Top links clicked</Header2>
                                <ul>
                                    {linksClicked}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                    :
                    <div className={`relative mx-auto py-8 ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg w-3/4 mb-10 justify-center items-center shadow-md`}>
                        <div className="flex flex-col">
                            <div className={`relative border-b-2 ${dark ? "border-neutral-800" : "border-neutral-200"}`}>
                                <Sidebar listElements={listElements} />
                            </div>
                            <div className=" p-2 ">
                                <div className="border-b-2 border-neutral-300">
                                    <Header3>{state.name}</Header3>
                                    <Header2>Overview Activity</Header2>
                                </div>
                                <SpreadStats stats={DUMMY_EMAIL_STATS} />
                                <div className="grid grid-cols-1 gap-4 pb-4 border-b mb-4 border-neutral-300">
                                    <TitleColonMessage title="Audience" message={state.name} />
                                    <TitleColonMessage title="Delivered" message={date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear()} />
                                    <TitleColonMessage title="Subject" message="aaaa" />
                                    <div className="flex">
                                        <Link><p className={` text-red-1000 pr-2 hover:underline`}>View Email</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                        <Link><p className={` text-red-1000 pr-2 hover:underline`}>Download</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                        <Link><p className={` text-red-1000 pr-2 hover:underline`}>Print</p></Link> <p className={`${dark ? "text-white" : "text-black"} pr-2`}>-</p>
                                        <Link><p className={` text-red-1000 pr-2 hover:underline`}>Share</p></Link>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-4 pb-4 border-b border-neutral-300">
                                    <TitleColonMessage title="Successful Deliveries" message={state.recipients} />
                                    <TitleColonMessage title="Delivered" message="und" />
                                    <TitleColonMessage title="Total Opens" message={state.opens} />
                                    <TitleColonMessage title="Total Clicks" message={state.clicks} />
                                    <TitleColonMessage title="Last Opened" message="und" />
                                    <TitleColonMessage title="Last Clicked" message="und" />
                                    <TitleColonMessage title="Forwarded" message="und" />
                                    <TitleColonMessage title="Abuse Reports" message="und" />
                                </div>
                                <SpreadStats stats={DUMMY_CLICK_STATS} />
                                <Header2>Top links clicked</Header2>
                                <ul className="w-full relative ">
                                    {linksClicked}
                                </ul>
                            </div>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default CampaignDetails;