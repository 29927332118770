import { reportingActions } from "./reporting-slice";

export function GetReportingCount(value) {
    return async (dispatch) => {
        const fetchWorkshop = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/CountByWorkshopVisit",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch workshop count data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchMot = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/CountByMOT",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch mot count data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchPurchase = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/CountByPurchase",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch purchase count data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchWarranty = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/CountByWarranty",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch workshop count data.');
            };

            const data = await response.json();
            return data;
        }


        let count = 0

        try{
        switch (value.url) {
            case "LastWorkshop":
                count = await fetchWorkshop({ siteId: value.siteId, fromDays: value.fromDays, searchDate: value.date });
                break;

            case "LastPurchase":
                count = await fetchPurchase({ siteId: value.siteId, fromDays: value.fromDays, searchDate: value.date });
                break;

            case "WarrantyEnds":
                count = await fetchWarranty({ siteId: value.siteId, fromDays: value.fromDays, searchDate: value.date });
                break;

            case "MotDue":
                count = await fetchMot({ siteId: value.siteId, fromDays: value.fromDays, searchDate: value.date });
                break;

            default:
                count = 0;
        }
        }
        catch(e){
            console.log(e)
            count = 0;
        }

        dispatch(reportingActions.addToReporting({ counts: { id: value.id, num: count, date: value.date } || null }))

    }
}

export function GetIndividualCount(value) {
    return async (dispatch) => {
        const fetchCount = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/CustomersCampaigned",
                requestOptions
            );

            if (!response.ok) {
                console.log('Could not fetch count data.');
            };
            

            const data = await response.json();

            if (response.ok) {
            return data;
            }
        }
        const fetchClicks = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/EmailClickedCount",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch clicks data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchOpens = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/EmailOpenedCount",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch opens data.');
            };

            const data = await response.json();
            return data;
        }
        
        const count = await fetchCount({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });
        
        dispatch(reportingActions.addToReporting({ counts: { id: value.siteCommId, num: count, date: value.date } || null }))

        const clicks = await fetchClicks({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });
        const opens = await fetchOpens({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });

        dispatch(reportingActions.addClicksAndOpens({ counts: { id: value.siteCommId, clicks: clicks, opens:opens, date: value.date } || null }))

    }
}

export function GetUnsubs(value) {
    return async (dispatch) => {
        const fetchUnsub = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/EmailUnsubscribeCount",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch opens data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchSpam = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/EmailSpamCount",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch opens data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchBounce = async (i) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(i);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Reporting/EmailBounceCount",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch opens data.');
            };

            const data = await response.json();
            return data;
        }

        const unsub = await fetchUnsub({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });
        const spam = await fetchSpam({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });
        const bounce = await fetchBounce({ siteCampaignCommsTemplateId: value.siteCommId, date: value.date });

        dispatch(reportingActions.addUnsubscribeSpamBounce({ counts: { id: value.siteCommId, unsub: unsub, spam:spam, bounce:bounce, date: value.date } || null }))

    }
}
// function createProgress(startDate, endDate) {
//     const today = new Date();
//     const start = new Date(startDate);
//     const end = new Date(endDate);

//     const oneDay = 24 * 60 * 60 * 1000;
//     const campLength = Math.round(Math.abs((end - start) / oneDay));
//     const checkTime = Math.round(Math.abs((start - today) / oneDay));

//     var progress = Math.round((checkTime / campLength) * 100);
//     if (progress > 100) {
//         progress = 100;
//     }

//     return progress;
// }

