import { useContext, useEffect, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SmCalDay({day,id, onClick, range, act, current = true}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [selected, setSelected] = useState(false);
    const [sPos, setSPos] = useState(1);

    useEffect(()=>{
        if(range === true){
            if(act.includes(id.toString())){
            setSelected(true)
            if(act[0] === id.toString()){
                setSPos(0);
            }else if(act[act.length-1] === id.toString()){
                setSPos(2);
            }else{
                setSPos(1);
            }
            }
            else{
                setSelected(false);
            }
        }else{
        if(id.toString() !== act){
        setSelected(false);
        }
        else{
            setSelected(true)
        }
    }
    },[act, day])

    function selectManager(e){
        if(onClick != null){
            onClick(e.target.id);
        }

    }
    return(
            <span id={id} className={`${selected ? (range ? (sPos === 0 ? "bg-accent rounded-l-full border-accent " : (sPos === 2 ? "bg-accent border-accent rounded-r-full ": " bg-accent border-accent")) : " bg-accent rounded-full ") + " text-secondary " :" hover:text-accent px-1" } py-1 flex justify-center items-center cursor-pointer ${!selected&& dark ? current ? "text-white" : "text-neutral-300 font-normal" : current?"text-black": "text-neutral-300 font-normal"} `}  onClick={selectManager}>
            {day}
            </span>
    )
}

export default SmCalDay;