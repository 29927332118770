import { useState, useContext } from "react";
import TextInputLabeled from "../UI/TextInputLabeled";
import MediumDropdownItem from "../UI/MediumDropdownItem";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ThemeContext } from "../../context/ThemeContext"
import { CreateProspectingData } from "../../store/prospecting-actions";
import { CreateNewCustomer } from "../../store/customer-actions";
import { Form } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import getUniqueId from "../../hooks/getUniqueId";
import { CreateVehicle } from "../../store/vehicle-actions";

function AddToTable({ modalToggle }) {
    const userSites = useSelector((state) => state.currentUser.site);
    const sites = useSelector((state) => state.organisation.sites.filter((s) => userSites.includes(s.id)), shallowEqual);
    const dispatch = useDispatch()
    const theme = useContext(ThemeContext);
    const user = useSelector((state) => state.currentUser.item);
    const { dark } = theme;
    const [customer] = useState({ id: getUniqueId(), ucrn: "", notes: null, createdBy: user.email, createdAt: new Date(), modifiedAt: null, modifiedBy: null })
    const [vehicle, setVehicle] = useState({ id: getUniqueId(), registration: "", make: "", model: "", firstUsedDate: null, fuelType: null, primaryColour: null, vin: null, regDate: null, manufactureDate: null, engineSize: null, createdAt: new Date(), createdBy: user.email, modifiedAt: null, modifiedBy: null, motTests: null })
    const [orgMap] = useState({ customerId: customer.id, organisationId: null, siteId: "", createdBy: user.email, createdAt: new Date(), modifiedAt: null, modifiedBy: null })
    const [vehicleMap] = useState({ id: getUniqueId(), customerId: customer.id, vehicleId: vehicle.id, createdBy: user.email, createdAt: new Date(), modifiedAt: null, modifiedBy: null })
    const [prospect] = useState({ id: getUniqueId(), customerId: customer.id, points: 1, campaignId: "a062d2d5-e96e-4434-9d40-2aef9c07f3d7", orgSiteId: null, createdBy: user.email, createdAt: new Date(), modifiedAt: null, modifiedBy: null })
    const [details, setDetails] = useState({ id: getUniqueId(), title: "", firstName: "", lastName: "", notes: "", customer: customer.id, createdAt: new Date(), createdBy: user.email, modifiedAt: null, modifiedBy: null })
    const [contact, setContact] = useState({ id: getUniqueId(), email: "", altEmail: "", tel: "", altTel: "", customer: customer.id, createdAt: new Date(), createdBy: user.email, modifiedAt: null, modifiedBy: null })
    const [address, setAddress] = useState({ id: getUniqueId(), addressL1: "", addressL2: null, addressL3: null, addressL4: null, addressL5: null, postcode: "", customer: customer.id, createdAt: new Date(), createdBy: user.email, modifiedAt: null, modifiedBy: null })
    const [priority] = useState({ id: getUniqueId(), customerId: customer.id, points: 10, type:"none", reason: "created in prospecting", campaignId: "a062d2d5-e96e-4434-9d40-2aef9c07f3d7", relatedId: null, createdBy: user.email, createdAt: new Date(), modifiedAt: null, modifiedBy: null })
    const { width } = useWindowDimensions();
    const [open, setOpen] = useState(false)
    const [option, setOption] = useState(sites[0]);

    const menuItems = sites.map((option, index) => <MediumDropdownItem name={option.name} id={option.id} value={option.id} acc={false} onClick={changeController} last={index === sites.length - 1 ? true : false} />)

    function createCustomer(e) {
        e.preventDefault();

        dispatchActions();
    }

    function dispatchActions() {
        let myPromise = new Promise(async function a(resolve, reject) {
            dispatch(CreateProspectingData({ customer: customer, prospect: prospect, details: details, site: option.id, orgMap: orgMap, priority: priority }));
            return resolve();
        });
        myPromise.then(async function b() {
            dispatch(CreateNewCustomer({ details: details, contact: contact, address: address, customer: customer.id }));
            dispatch(CreateVehicle({ vehicleMap: vehicleMap, vehicle: vehicle }));
        });
        modalToggle();
    }

    function toggleOptions() {
        setOpen(!open);
    }

    function changeController(e) {
        setOption(e.target);
        setOpen(false)
    }

    function onChangeAddress(e) {
        const addr = e.target.value.split(", ");

        setAddress(prevState => ({
            ...prevState,
            addressL1: addr[0]
        }))
        if (addr[1] !== "") {
            setAddress(prevState => ({
                ...prevState,
                addressL2: addr[1]
            }))
        }
        if (addr[2] !== "") {
            setAddress(prevState => ({
                ...prevState,
                addressL3: addr[2]
            }))
        }
        if (addr[3] !== "") {
            setAddress(prevState => ({
                ...prevState,
                addressL4: addr[3]
            }))
        }
        if (addr[4] !== "") {
            setAddress(prevState => ({
                ...prevState,
                addressL5: addr[4]
            }))
        }
    }
    function onChangeDetail(e) {
        setDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangeContact(e) {
        setContact(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangePostcode(e) {
        setAddress(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangeVehicle(e) {
        setVehicle(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    return (
        <Form>
            <div className="px-6 space-y-6 pt-2">
                <div className="justify-start w-full items-center h-full">
                    <p className={` pb-1 text-sm ${dark ? "text-neutral-300" : "text-neutral-300"}`}>Site:</p>

                    <div className={`relative w-full h-1/2`}>
                        <div className=" grid grid-cols-1 w-full  z-20 ">
                            <button
                                className={`font-semibold  ${dark ? "text-neutral-100  " : "text-neutral-300 "
                                    }  w-full h-full text-sm space-x-2 items-center ${open ? "rounded-t-lg border-t border-x" : "rounded-lg border"} py-1 hover:text-accent `}
                                id="select"
                                onClick={toggleOptions}>
                                <div className="flex justify-between items-center">
                                    <p className="pl-6 w-full">{option.name}</p>
                                    <div className=" flex justify-right pr-2 items-center">
                                        <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </div>
                                </div>
                            </button>
                            <div>
                                {open && <div className={`absolute w-full h-max space-y-2 border-b border-x rounded-b-lg ${dark ? "bg-neutral-900" : "bg-white"} `}><ul >{menuItems}</ul></div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={` ${width <= 640 ? "flex flex-col" : "grid grid-cols-3 gap-3 grid-rows-4 my-auto "}`}>
                    <TextInputLabeled name={"Title:"} id={"title"} onChange={onChangeDetail} />
                    <TextInputLabeled name={"First name:"} onChange={onChangeDetail} id={"firstName"} />
                    <TextInputLabeled name={"Surname:"} onChange={onChangeDetail} id={"lastName"} />
                    <TextInputLabeled name={"Email address:"} onChange={onChangeContact} id={"email"} />
                    <TextInputLabeled name={"Contact number:"} onChange={onChangeContact} id={"tel"} />
                    <TextInputLabeled name={"Alternative contact number:"} onChange={onChangeContact} id={"altTel"} />
                    <TextInputLabeled name={"Vehicle registration (optional):"} onChange={onChangeVehicle} id={"registration"} />
                    <TextInputLabeled name={"Make:"} onChange={onChangeVehicle} id={"make"} />
                    <TextInputLabeled name={"Model:"} onChange={onChangeVehicle} id={"model"} />
                    <div className="col-span-2 ">
                        <TextInputLabeled name={"Address (separate with commas):"} onChange={onChangeAddress} id={"address"} />
                    </div>
                    <TextInputLabeled name={"Postcode:"} onChange={onChangePostcode} id={"postcode"} />
                </div>
            </div>
            <div className={`flex items-center p-6 space-x-2 `}>
                <button onClick={createCustomer} data-modal-hide="defaultModal" type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-1.5 text-center hover:bg-green-600 bg-green-1000 focus:outline-accent`}>Create new customer</button>
                <button onClick={modalToggle} type="button" className={` focus:ring-4 focus:outline-none rounded-lg border border-neutral-200 text-sm font-medium px-5 py-1.5 focus:z-10 ${dark ? "bg-neutral-900 border-neutral-500 text-white hover:bg-neutral-600 focus:ring-neutral-600" : "text-neutral-900 bg-white hover:bg-neutral-100 border-neutral-200 focus:outline-accent"}`}>Cancel</button>
            </div>
        </Form>
    )
}

export default AddToTable;