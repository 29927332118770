import ChatBoxSender from "./ChatBoxSender";
import { useContext, useState, useEffect, useRef } from "react";
import { ThemeContext } from "../context/ThemeContext";
import ChatOption from "./ChatOption";

const options = [
    "option 1",
    "option 2",
    "option 3",
    "option 4"
]

function ChatHolder({ onChoose, chats }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const [optionsActive, setOptions] = useState(true)
    const messagesEndRef = useRef(null);

    function handleOptions(op) {
        onChoose(op);
        setOptions(false);
    }

    const optionList = options.map((option) => (<ChatOption onClick={handleOptions} option={option} active={optionsActive} />))

    const messages = chats.map(chat => <ChatBoxSender text={chat} />)

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(scrollToBottom, [messages]);

    return (
        <div className="p-6 space-y-6 h-80 flex flex-col flex-nowrap overflow-scroll">
            <div className="flex w-full mt-2 space-x-3 max-w-xs">

                <svg width="26" height="26" viewBox="0 0 26 26" className={`flex-shrink-0 h-10 w-10 fill-accent`} >
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.4001 13.0001C23.4001 15.7583 22.3044 18.4036 20.354 20.354C18.4036 22.3044 15.7583 23.4001 13.0001 23.4001C10.2418 23.4001 7.59657 22.3044 5.64619 20.354C3.69581 18.4036 2.6001 15.7583 2.6001 13.0001C2.6001 10.2418 3.69581 7.59657 5.64619 5.64619C7.59657 3.69581 10.2418 2.6001 13.0001 2.6001C15.7583 2.6001 18.4036 3.69581 20.354 5.64619C22.3044 7.59657 23.4001 10.2418 23.4001 13.0001ZM15.6001 9.1001C15.6001 9.78966 15.3262 10.451 14.8386 10.9386C14.351 11.4262 13.6897 11.7001 13.0001 11.7001C12.3105 11.7001 11.6492 11.4262 11.1616 10.9386C10.674 10.451 10.4001 9.78966 10.4001 9.1001C10.4001 8.41053 10.674 7.74921 11.1616 7.26162C11.6492 6.77402 12.3105 6.5001 13.0001 6.5001C13.6897 6.5001 14.351 6.77402 14.8386 7.26162C15.3262 7.74921 15.6001 8.41053 15.6001 9.1001ZM13.0001 14.3001C11.7555 14.2998 10.537 14.6569 9.48934 15.3289C8.44172 16.0009 7.60905 16.9595 7.0903 18.0909C7.82178 18.9419 8.72865 19.6246 9.74872 20.0922C10.7688 20.5598 11.8779 20.8013 13.0001 20.8001C14.1223 20.8013 15.2314 20.5598 16.2515 20.0922C17.2715 19.6246 18.1784 18.9419 18.9099 18.0909C18.3911 16.9595 17.5585 16.0009 16.5109 15.3289C15.4632 14.6569 14.2447 14.2998 13.0001 14.3001Z" />
                </svg>
                <div>
                    <ul>
                        <p className={`text-gray-400 rounded-tr-md ${dark ? "bg-neutral-900" : "bg-white"} text-sm  h-max p-0.5 pl-1`}>Welcome! Select one of the following options that best suit your needs:</p>
                        {optionList}
                    </ul>
                </div>
            </div>
            {messages}
            <div ref={messagesEndRef}></div>
        </div>
    )
}

export default ChatHolder;