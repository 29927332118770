import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SkeletonInput(){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return (
        <div className={`px-6 py-4 divide-gray-300 rounded-full shadow animate-pulse ${dark ? "bg-neutral-500 ":"bg-neutral-200 " }`}></div>
    )
}

export default SkeletonInput;