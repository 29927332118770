import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const theme = JSON.parse(localStorage.getItem('theme'));
  const [darkMode, setDarkMode] = useState(theme);
  if (theme === null) {
    setDarkMode(false);
  }

  function toggle() {
    setDarkMode(!darkMode);
  }

  var bodyStyles = document.body.style;
  if (darkMode) {
    bodyStyles.setProperty('background-color', '#202125');
  }
  else {
    bodyStyles.setProperty('background-color', '#E9ECEF')
  }

  useEffect(() => {
    localStorage.setItem('theme', darkMode)
  }, [darkMode])

  return {
    darkMode,
    toggle,
  }
};

export default useDarkMode;