import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function AccordionBodyInput({title, children}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return (
        <div id={title}  aria-labelledby={title}>
        <div className={`p-5 border border-t-0 ${dark ? "border-neutral-300 text-white bg-neutral-900" : "border-neutral-200 text-neutral-900 bg-white"}`}>
            {children}
        </div>
      </div>
    )
}

export default AccordionBodyInput;