import { Fragment } from "react";
import TableRow from "./TableRow";

function TableBody({ hotFilter, tableData, max, openModal, filterCampaign, filterSite, filterCallperson, filterDate, filterDateRange }) {

  let t = (filterCallperson !== null && filterCallperson !== "") ? tableData.filter(t => t.lastSalesExec === filterCallperson) : tableData;
  t = filterSite.length !== 0 ? tableData.filter(s => filterSite.includes(s.orgSiteId)) : t;
  t = filterCampaign.length !== 0 ? tableData.filter(s => filterCampaign.includes(s.campaignId)) : t;
  t = filterDateRange.length !== 0 ? tableData.filter(s => filterDateRange.includes(s.createdAt)) : t;
  t = (filterDate !== null && filterDate !== "none") ? tableData.filter(t => (new Date(t.createdAt).getFullYear() + new Date(t.createdAt).getDay() + new Date(t.createdAt).getMonth())=== filterDate) : t;

  const mappedData = t.map((data) => (
    (hotFilter.includes("hot") && data.points >= Math.ceil(max / 4) + Math.ceil(max / 2)) ||
      (hotFilter.includes("cold") && data.points <= Math.ceil(max / 4))
      || (hotFilter.includes("warm") && data.points > Math.ceil(max / 4) && data.points < Math.ceil(max / 4) + Math.ceil(max / 2))
      || (hotFilter.length === 0) ?
      <TableRow
        id={data.id}
        custId={data.customerId}
        data={data}
        hot={data.points}
        max={max}
        key={data.id}
        openModal={openModal}
      />
      : null));
  return <Fragment>{mappedData}</Fragment>;
}

export default TableBody;
