import { useEffect, useState } from "react";
import CountUp from "./UI/CountUp";
import Spinner from "./UI/Spinner";

function TextStatistic({ statNumber, statName, time, accent, loaded }) {
    const [acc, setAcc] = useState(false);

    useEffect(() => {
        if (accent !== null) {
            setAcc(true)
        } else {
            setAcc(false)
        }
    }, [accent])

    return (
        <div key={statName} className={`place-content-center px-6 w`}>
            {loaded?<CountUp maxNumber={statNumber} duration={10} time={time} acc={acc} />:<div className="w-full flex justify-center"><Spinner/></div>}
            <p className="text-neutral-300 text-center">{statName}</p>
        </div>
    )
}

export default TextStatistic;