import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useSelector } from "react-redux";

function CampWords({ name, time, width, id }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const reports = useSelector((state) => state.reporting.counts)
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if (reports.length !== 0) {
            const num = reports.find(r => r.id === id);
            if (num !== undefined) {
                setAmount(num.num);
            }
        }
    }, [id, reports])

    return (
        <div className={`${width <= 640 ? "" : "w-2/4"} pt-1`}>
            <h5 className={`font-bold ${dark ? 'text-white' : 'text-black'}`}>{name}</h5>
            <p className={`${dark ? 'text-neutral-200' : 'text-black'} truncate`}>Scheduled for <span className="text-red-1000">{amount}</span> recipients</p>
        </div>
    )
}

export default CampWords;