import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SmCalDayTitle({name}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
<span className={` text-center font-normal ${dark? "text-neutral-300":"text-black"}`}>
{name}
</span>
    )
}

export default SmCalDayTitle;