import LoginForm from "../components/LoginForm";
import CardFit from "../components/UI/CardFit";
import { redirect } from "react-router-dom";
import RoundedButton from "../components/UI/RoundedButton";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { currentUserActions } from "../store/currentUser-slice";
import { ResetPassword, forgottenPassword } from "../store/currentUser-actions";
import { useSearchParams } from "react-router-dom";

function ResetPasswordPage({toggle}) {
  const { dark } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("id");
  console.log(token.replace(/\s/g,"+"));

  function saveDetails(email,password,event){
    event.preventDefault();
    dispatch(ResetPassword({email:email,newPassword:password,token:token.replace(/\s/g,"+")}));
  }


  return (
    <div className="h-full w-full z-10 my-auto">
      <div className="w-full h-full  flex justify-center items-center">
        <div className="absolute w-80 mb-96">
          <div className={`bg-accent flex justify-center items-center m-auto h-auto aspect-square w-1/3 rounded-full`}>
            <svg viewBox="0 0 183 183" className={` fill-secondary`}>
              <path d="m 92 90 c 13.8875 0 25.1429 -11.1914 25.1429 -25 c 0 -13.8086 -11.2554 -25 -25.1429 -25 c -13.8875 0 -25.1429 11.1914 -25.1429 25 c 0 13.8086 11.2554 25 25.1429 25 z m -8.9768 9.375 c -19.3482 0 -35.0232 15.5859 -35.0232 34.8242 c 0 3.2031 2.6125 5.8008 5.8339 5.8008 h 76.3322 c 3.2214 0 5.8339 -2.5977 5.8339 -5.8008 c 0 -19.2383 -15.675 -34.8242 -35.0232 -34.8242 h -17.9536 z" />
            </svg>
          </div>
        </div>
        <CardFit>
          <LoginForm save={saveDetails} title={"Change Login Details"}/>
        </CardFit>
        <div className="absolute bottom-0 left-0 m-10">
          <RoundedButton text={dark ? "Light Mode" : "Dark Mode"} onClick={toggle} />
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;