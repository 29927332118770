import TextStatistic from "./TextStatistic";
import { Fragment, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

function SpreadStats({ stats }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();

    const statList = stats.map((s, index) => index !== stats.length - 1 ? <Fragment key={index}><div className={` ${width <= 640 ? "" : "border-r"} w-full ${dark ? " border-neutral-300" : "border-neutral-300"}`}><TextStatistic key={s.name} accent={true} statName={s.name} statNumber={s.number} time={s.time} /></div></Fragment> : <div className="w-full" key={index}><TextStatistic key={s.name} accent={true} statName={s.name} statNumber={s.number} time={s.time} /></div>)

    return (
        <div className={`flex border-b justify-between mb-6 ${dark ? "bg-neutral-600 border-neutral-300" : "bg-[#F7F8F9] border-neutral-300"} ${width <= 640 ? "grid grid-cols-2" : "space-x-6"} shadow-md max-w-7xl min-w-min h-min justify-center items-center mx-auto py-4 `}>
            {statList}
        </div>
    )
}

export default SpreadStats;