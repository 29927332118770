import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TitleColonMessage({title,message,subtle=false,stacked = false}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return(
        <div className={` ${!stacked &&  "flex"}`}>
        <p className={`text-md font-semibold ${subtle? "text-neutral-300":dark ? "text-white" : "text-black"} pr-2`}>{title + ":"}</p>
        <p className={` ${dark ? "text-neutral-100" : "text-black"}`}>{message}</p>
        </div>
    )
}

export default TitleColonMessage;