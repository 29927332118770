import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import MediumDropdown from "../UI/MediumDropdown";
import SearchBar from "../UI/SearchBar";
import FilterButton from "../UI/FilterButton";

function CampaignHeader({ filter, width, open, sort, years, updates, act, toggleAction}) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;

    const options = [
        {
            name: "Last Updated",
            id: "lu",
            value: updates
        },
        {
            name: "Year",
            id: "y",
            value: years
        }
    ]

    function searchBarSubmit(input) {
        filter(input);
    }

    function sortMenu(item) {
        sort(item)
    }

    return (
        <div>
            <div className="flex gap-4">
            <p className="text-lg font-bold text-accent pl-6">{act ? "Action Campaigns" : "Non-Action Campaigns"}</p>
            <button onClick={toggleAction} className=" bg-accent rounded-lg p-0.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-6 h-6 hover:rotate-180 duration-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            </button>
            </div>
            <div className={`  ${width <= 640 ? "w-full" : "flex pr-6 justify-between space-x-1"}`}>
                {width > 640 ? <div className=" flex w-full justify-between items-center">
                    <div className="w-3/5">
                        <SearchBar submit={searchBarSubmit} previewText={"Search campaign by name, type or date"} />
                    </div>
                    <div className="w-1/5 h-1/2 flex justify-start items-center">
                        <p className={` w-1/3 text-md ${dark ? " text-neutral-300" : " text-neutral-300"}`}>Sort by</p>
                        <div className="mt-1">
                            <MediumDropdown options={options} onChange={sortMenu} />
                        </div>
                    </div>
                    <FilterButton openFilter={open} />
                </div>
                    :
                    <div className=" flex flex-col justify-center items-center">
                        <div className="w-full">
                            <SearchBar submit={searchBarSubmit} previewText={"Search campaigns"} />
                        </div>
                        <div className="flex mx-auto h-min pt-4 space-x-2">
                            <p className={`pt-1 text-md ${dark ? " text-neutral-300" : " text-neutral-300"}`}>Sort by</p>
                            <MediumDropdown options={options} />
                        </div>
                        <div className="relative h-full ">
                            <FilterButton openFilter={open} />
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default CampaignHeader;