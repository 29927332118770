import { useContext, useEffect, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import DropDownMenu from "./DropDownMenu";

function SmallDropdown({options, onChange, bigText, accent = false}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [isComponentVisible, setIsComponentVisible] = useState(false)
  const [option, setOption] = useState(options.length !== 0? options[0].name:"");
  const [open, setOpen] = useState(false);
    function toggleOptions(){
      setIsComponentVisible(!isComponentVisible)
  }
  function change(item){
    onChange(item)
    setOption(item.name);
  }

  useEffect(()=>{
    if(options.length!==0){
    setOption(options[0].name);
    }
  },[options])

    return (<div className="w-full  flex flex-col justify-center "><button
    className={`font-semibold ${ accent? "text-secondary":
      dark ? "text-neutral-300 focus:border-neutral-200 hover:text-accent " : "text-neutral-300 focus:border-neutral-700 hover:text-accent "
    } w-max pl-2 mt-1 rounded-md ${bigText ? "text-md" : "text-sm"} bg-transparent border-0 appearance-none focus:outline-none focus:ring-0 peer`}
    onClick={toggleOptions}
  >
    <div className="flex justify-center w-full">
    {option}
    <svg data-accordion-icon className={`h-6 ${open ? "rotate-180" : ""} shrink-0 pt-1 ${accent?"fill-secondary":"fill-accent"}`} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </div>
    </button>
  {isComponentVisible && <div className="h-full "><DropDownMenu toggle={toggleOptions} items={options} onClick={change} closeable={false}/></div>}
  </div>)
}

export default SmallDropdown;