import { createSlice } from "@reduxjs/toolkit";

const initialHistoryState = {
    items: [],
    changed: false
}

const historySlice = createSlice({
    name: 'hist',
    initialState: initialHistoryState,
    reducers: {
        replaceHistory(state, action) {
            state.items = action.payload;
        },
        addToHistory(state, action) {
            const newItem = action.payload;
            state.changed = true;
            state.items.push({ id: newItem.id, body: newItem.body });

        },
        removeFromHistory(state, action) {
            const id = action.payload;
            state.changed = true;
            state.items = state.items.filter(item => item.id !== id);
        },
        seenHistory(state, action) {
            const id = action.payload;
            const seenNotif = JSON.parse(JSON.stringify(state.items.find(item => item.id === id)));
            seenNotif.seen = true;
            state.items = state.items.filter(item => item.id !== id);
            state.items.push({ id: seenNotif.id, body: seenNotif.body, seen: seenNotif.seen, flagged: seenNotif.flagged, deleted: seenNotif.deleted, time: seenNotif.time });
        },
        updateHistory(state, action) {
            const newItem = action.payload;
            const exsistingItem = state.items.find(item => item.id === newItem.id);

            if (exsistingItem && newItem !== exsistingItem) {
                state.items = state.items.filter(item => item.id !== exsistingItem.id);
                state.items.push({
                    id: newItem.id,
                    body: newItem.body,
                    time: newItem.time,
                    flagged: newItem.flagged,
                    deleted: newItem.deleted,
                    seen: newItem.seen
                });
            }

        },
        removeAllHistory(state) {
            state.changed = true;
            state.items = [];
        }
    }
});

export const historyActions = historySlice.actions;
export default historySlice;