import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import MediumDropdownItem from "./MediumDropdownItem";

function LinkDropDown({options, onChange, data = null}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [open, setOpen] = useState(false);
  
    const menuItems = options.map((option, index) => <MediumDropdownItem name={option.name} id={option.id} acc={false} onClick={changeController} last={index===options.length-1?true:false}/>)
  
      const [option, setOption] = useState(options[0].name);
      const [link, setLink] = useState(options[0].id);
  
      function changeController(e){
        setOption(e.name);
        setLink(e.value);
        setOpen(false)
        onChange(e)
      }
  
      function toggleOptions(){
        setOpen(!open);
      }
  
      return (
        <div className={`relative w-full h-full`}>
        <Link to={`/campaigns/${link}`} state={data}>
        <button className="absolute top-0 w-2/3 h-full space-y-0"></button>
        </Link>
        <div className=" grid grid-cols-1 w-full h-full z-20 ">
      <button
      className={`  ${
        dark ? "text-neutral-100  " : "text-neutral-300 "
      }  w-full h-full text-sm space-x-2 items-center hover:text-accent ${open? "rounded-t-lg border":"rounded-lg border"}  ${dark? "border-neutral-300 bg-neutral-900":"border-neutral-600 bg-white"}  hover:text-accent `}
    id="select"
    onClick={toggleOptions}>
      <div  className="flex justify-between h-full items-center">
        <div className={`border-r w-4/5 ${dark? "border-neutral-300":"border-neutral-600"}`}>
      <p className="w-full pl-2 py-1">{option}</p>
      </div>
      <div className="w-1/5 flex justify-center">
      <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
      </div>
      </div>
    </button>
    <div>
      {open && <div className={`absolute w-full h-max space-y-2 border-b border-x rounded-b-lg ${dark?"bg-neutral-900 border-neutral-300":"bg-white border-neutral-600"} `}><ul >{menuItems}</ul></div>}
      </div>
      </div>
    </div>)
  }

export default LinkDropDown;