import EChartsReact from "echarts-for-react";
import RingKey from "./RingKey";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

const RingChart = ({ data, colour, maxValue, loaded }) => {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const option = {
    polar: {
      radius: [30, "100%"],
    },
    angleAxis: {
      max: maxValue,
      startAngle: 90,
      show: false,
    },
    radiusAxis: {
      type: "category",
      show: false,
    },
    color: colour,
    tooltip: {},
    series: {
      type: "bar",
      data: data,
      coordinateSystem: "polar",
      showBackground: true,
      barWidth: "10",
      labelLine: false,
      roundCap: true,
      colorBy: "data",
    },
  };

  const array = [];
  option.series.data.forEach(element => {
    array.push(element);
  });
  const key = array.reverse().map((d) => (
    <li key={d.name}>
      <RingKey
        name={d.name}
        colour={
          d.itemStyle.color !== "auto" ? d.itemStyle.color : option.color[0]
        }
        amount={d.value}
        dark={dark}
      />
    </li>
  ));

  return (
    <div className="flex items-center justify-start flex-shrink mx-auto w-full h-full">
      <EChartsReact
        option={option}
        opts={{ renderer: "svg" }}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
      <ul className={`w-full mr-6 ${array.length > 3 ? " grid grid-cols-3 " : ""}`}>{loaded&&key}</ul>
    </div>
  );
};

export default RingChart;
