import { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SquareRadio({id, name, boxCol, crossCol, onClick, def, textCol = null}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [clicked, setClicked] = useState(def);

    function clickHandler(){
        setClicked(!clicked);
        onClick(id);
    }

    return (
        <button className="inline-flex hover:cursor-pointer items-center w-full " onClick={clickHandler}>
            <div className={`mt-1 ml-0.5`}>
     <div className={`relative w-4 h-4 border-2 ${boxCol} inline-block `}>
     {clicked && 
        <div className={`absolute w-full h-full font-bold bottom-2 ${crossCol}`}>x</div>
     }
     </div>
        </div>
            <p className={`text-left ml-4 ${textCol!==null? textCol + " font-semibold" :dark? "text-neutral-200" : "text-neutral-600"}`}>{name}</p>
        </button>
    )
}

export default SquareRadio;