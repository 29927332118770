import { Fragment } from "react";
import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SkeletonTable(){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
        <Fragment>
    <tr role="status" className="justify-between w-full p-4 divide-gray-200 rounded shadow animate-pulse">
        <td colSpan={10} className={` h-12 m-2 ${dark ? "bg-neutral-700 ":"bg-neutral-200 " } w-full py-2`}></td>
    </tr>
    <tr role="status" className="justify-between w-full p-4 divide-gray-200 rounded shadow animate-pulse">
        <td colSpan={10} className={` h-12 m-2 ${dark ? "bg-neutral-800 ":"bg-neutral-300 " } w-full py-2`}></td>
    </tr>
    <tr role="status" className="justify-between w-full p-4 divide-gray-200 rounded shadow animate-pulse">
        <td colSpan={10} className={` h-12 m-2 ${dark ? "bg-neutral-700 ":"bg-neutral-200 " } w-full py-2`}></td>
    </tr>
    <tr role="status" className="justify-between w-full p-4 divide-gray-200 rounded shadow animate-pulse">
        <td colSpan={10} className={` h-12 m-2 ${dark ? "bg-neutral-800 ":"bg-neutral-300 " } w-full py-2`}></td>
    </tr>
</Fragment>
    )
}

export default SkeletonTable;