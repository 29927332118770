import Card from "../UI/Card";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DonutChart from "./DonutChart";
import LoadingDots from "../UI/LoadingDots";

function DonutChartBacker({ maxValue, data, title, loaded }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    useWindowDimensions();

    return (
        <Card>
            <div id="bar_chart" className=" mb-auto h-full">
                <div className={`container flex flex-wrap  ${dark ? "text-neutral-200" : "text-neutral-900"} items-center px-6 pt-6 pb-4 mb-2`}>
                    {loaded?
                    data.length !== 0 ? <h1 className={`absolute font-bold text-2xl`}>{title}</h1> : <h1 className={`absolute font-bold text-2xl`}>No Data Found</h1>:
                    <LoadingDots/>}
                </div>
                <div className=" w-full h-full pb-8"><DonutChart data={data} maxValue={maxValue} /></div>
            </div>
        </Card>
    )
}

export default DonutChartBacker;