import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function SearchBar({submit, previewText}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;

    function search(event){
      submit(event.target.value);
    }


    return(        
    <form className="w-full h-min p-4 " onSubmit={(e)=>{e.preventDefault();}}>
    <label
      htmlFor="search"
      className={`mb-2 text-sm font-medium ${dark ? "text-white" : "text-neutral-900" }  sr-only `}>
      Search
    </label>
    <div className="relative">
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <svg
          aria-hidden="true"
          className={`w-4 h-4 ${dark?"text-neutral-300":"text-neutral-200"}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
      </div>
      <input
        type="search"
        id="filter"
        name="filter"
        className={` w-full  h-min text-sm focus:outline rounded-full border-2 ${dark? "bg-neutral-800 text-neutral-400 border-neutral-300" : "bg-neutral-50 text-neutral-700 border-neutral-200"} focus:outline-accent `}
        onChange={search}
        placeholder={previewText}
      />
    </div>
  </form>)
}

export default SearchBar;