import CardFit from "../components/UI/CardFit";
import { redirect, useLocation } from "react-router-dom";
import RoundedButton from "../components/UI/RoundedButton";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import TwoFactor from "../components/TwoFactor";
import { useDispatch } from "react-redux";
import { currentUserActions } from "../store/currentUser-slice";

function TwoFactorPage({ toggle }) {
  const { dark } = useContext(ThemeContext);
  const dispatch = useDispatch();

  function clearTemp(){
    dispatch(currentUserActions.clearTempLogin());
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      <CardFit>
        <TwoFactor toggle={toggle} clear={clearTemp}/>
      </CardFit>
      <div className="absolute bottom-0 left-0 m-10">
        <RoundedButton text={dark ? "Light Mode" : "Dark Mode"} onClick={toggle} />
      </div>
    </div>
  );
}

export default TwoFactorPage;

export async function T({ request }) {
  const data = await request.formData();
  const authData = {
    "email": data.get("email"),
    "password": data.get("password"),
    "twoFactorCode": data.get("code")
  };
  
  console.log(authData);

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(authData),
  };

  const res = await fetch("https://prospecting-api.azurewebsites.net/Authentication/LoginWIth2fa", requestOptions);

  const resData = await res.json();

  localStorage.setItem("token", resData.token);
  localStorage.setItem("expiration", resData.expiration);

  return redirect("/");
}