import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function DropDownItem({name, id, onClick, value, k}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [hover,SetHover] = useState(false);

    function handleClick(){
        onClick({name,value});
    }

    function enter(){
        SetHover(true)
    }
    
    function leave(){
        SetHover(false)
    }

    return(
        <li id={id} className=" hover:cursor-pointer" >
        <button className={`w-full h-full rounded-full p`} onClick={handleClick}>
        {hover && <div className={`absolute left-0 w-1 h-9 bg-accent`}/>}
        <div onMouseEnter={enter} onMouseLeave={leave} className={`flex items-center p-2 hover:text-white rounded ${dark ? "hover:bg-neutral-800 " : "hover:bg-neutral-100"}`} >
        <p className={`w-full mr-2 text-sm font-medium text-justify  ${dark ? "text-neutral-300" : "text-neutral-900"} rounded`}>{name}</p>
        </div>
        </button>
    </li>
    )
}

export default DropDownItem;