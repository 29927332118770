import { useState } from 'react';
import SidebarElement from './SidebarElement';

function Sidebar({ listElements, onClick, filter, closeBar = null }) {
    const [active, setActive] = useState()

    function changeActive(id) {
        setActive(id)
        if(closeBar!==null){
        closeBar();
        }
    }

    const objs = listElements.map((element) => (
        <SidebarElement
            key={element.id}
            element={element}
            onChange={changeActive}
            active={active}
            filter={filter}
        />
    ));

    return <div className="w-full py-4 overflow-y-auto  left-0" onClick={onClick}>
        <ul className="space-y-2">{
            objs
        }
        </ul>
    </div>
}

export default Sidebar;