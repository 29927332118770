import { vehicleActions } from "./vehicle-slice";

//"https://prospecting-api.azurewebsites.net/Prospects/GetMany"

export function GetVehicleData(customer) {
  return async (dispatch) => {
    const fetchVehicleMap = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        [ids]
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetVehicle",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }
    const fetchVehicle = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        [ids]
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/GetMany",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }

    const fetchMot = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/GetMot?id=" + ids,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      if (response.status === 204) {
        return {};
      }

      const data = await response.json();

      return data;
    }

    const fetchDvla = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        [ids]
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/GetDvlas",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }
    const fetchPurchases = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        [ids]
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/GetPurchases",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }
    const fetchServices = async (ids) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        [ids]
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/GetServices",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }

    const map = await fetchVehicleMap(customer);
    const m = map;
    console.log(m);
    const vehicle = await fetchVehicle(map[0].vehicleId);
    const mot = await fetchMot(map[0].vehicleId);
    const dvla = await fetchDvla(vehicle[0].registration.replace(/ /g, ''));
    const purchases = await fetchPurchases(map[0].vehicleId);
    const services = await fetchServices(map[0].vehicleId);
    dispatch(vehicleActions.replaceVehicle({ items: vehicle || [], mots: mot || [], maps: map || [], dvla: dvla || [], purchases: purchases || [], services: services || [] }))
  }
};

export function CreateVehicle(data) {
  return async (dispatch) => {
    const postVehicle = async (d) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        d
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/CreateVehicle",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not create vehicle.');
      }

      const data = await response.json();

      return data;
    }
    const postVehicleMap = async (d) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        d
      );

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/CreateVehicleMap",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not create vehicle.');
      }

      const data = await response.json();

      return data;
    }

    await postVehicleMap(data.vehicleMap);
    await postVehicle(data.vehicle);
    dispatch(vehicleActions.addVehicle({ vehicleMap: data.vehicleMap, vehicle: data.vehicle }))

  }
};

export function UpdateVehicle(data) {
  return async (dispatch) => {
    const postVehicle = async (d) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(
        d
      );

      var requestOptions = {
        method: 'PUT',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Vehicle/UpdateVehicle",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not create vehicle.');
      }

      const data = await response.json();

      return data;
    }

    await postVehicle(data.vehicle);
    dispatch(vehicleActions.editVehicle({ vehicle: data.vehicle }))

  }
};