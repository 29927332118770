import { useState, useContext } from "react";
import Header2 from "../UI/Header2";
import TextInput from "../UI/TextInput";
import MediumDropdown from "../UI/MediumDropdown";
import { useDispatch } from "react-redux";
import { prospectingActions } from "../../store/prospecting-slice";
import { ThemeContext } from "../../context/ThemeContext";

function EditTableItem({ modalToggle, sites, c }) {
    const dispatch = useDispatch()
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const [customer, setCustomer] = useState(c)

    function editCustomer(e) {
        e.preventDefault();
        dispatch(prospectingActions.editProspecting(customer))
        modalToggle();
    }

    function onChangeFirst(e) {
        setCustomer(prevState => ({
            ...prevState,
            firstName: e.target.value
        }))
    }
    function onChangeLast(e) {
        setCustomer(prevState => ({
            ...prevState,
            lastName: e.target.value
        }))
    }
    function onChangeTitle(e) {
        setCustomer(prevState => ({
            ...prevState,
            title: e.target.value
        }))
    }
    function onChangeSite(e) {
        setCustomer(prevState => ({
            ...prevState,
            site: e.target.value
        }))
    }
    return (
        <form>
            <div class="p-6 space-y-6">

                <TextInput name={"First Name"} value={customer.firstName} onChange={onChangeFirst} />
                <TextInput name={"Last Name"} value={customer.lastName} onChange={onChangeLast} />
                <TextInput name={"Title"} value={customer.title} onChange={onChangeTitle} />
                <div className="inline-flex justify-start w-full space-x-2 items-center">
                    <Header2>Site:</Header2>
                    <MediumDropdown onChange={onChangeSite} options={sites} />
                </div>

            </div>
            <div className={`flex items-center p-6 space-x-2 border-t rounded-b ${dark ? "border-neutral-900" : "border-neutral-200"}`}>
                <button onClick={editCustomer} data-modal-hide="defaultModal" type="submit" className={`text-white hover:text-neutral-300 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-accent focus:ouline-accent`}>Submit</button>
                <button onClick={modalToggle} type="button" className={` focus:ring-4 focus:outline-none rounded-lg border border-neutral-200 text-sm font-medium px-5 py-2.5 hover:text-neutral-900 focus:z-10 ${dark ? "bg-neutral-700 text-neutral-300 border-neutral-500 hover:text-white hover:bg-neutral-600 focus:ring-neutral-600" : "text-neutral-500 bg-white hover:bg-neutral-100 border-neutral-200 focus:accent-outline"}`}>Cancel</button>
            </div>
        </form>
    )
}

export default EditTableItem;