import { Fragment } from "react";
import SolidButton from "../UI/SolidButton";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { customerActions } from "../../store/customer-slice";

function NextCustomer({ OpenEndModal, customerId, outbound }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tableData = useSelector((state) => state.prospecting.filtered);
    const customerIds = tableData.map(t => t.customer);
    const nextCustomer = customerIds.indexOf(customerId);
    const changeCustomer = (item) => new Promise((resolve, reject) => {
        dispatch(customerActions.getFilteredCustomers(item));
        resolve();
    })

    function next() {
        changeCustomer(customerIds[nextCustomer + 1]).then(() => {
            navigate("/prospecting/outbound/" + customerIds[nextCustomer + 1])
            OpenEndModal();
        });
    }
    function back() {
        navigate("/prospecting")
    }

    return (
        <Fragment>
            <div className="flex justify-center space-x-2 py-2">
                <SolidButton text={"Back To Prospects"} onClick={back} />
                {outbound && <SolidButton text={"Next Customer"} onClick={next} />}
            </div>
        </Fragment>)
}

export default NextCustomer;