import { useRouteError } from "react-router-dom";
import CardFit from "../components/UI/CardFit";
import Header from "../components/UI/Header";
import Header2 from "../components/UI/Header2";

function ErrorPage() {
    const error = useRouteError();

    let title = "An Error Ocurred";
    let message = "";

    if (error.status === 500) {
        message = error.data.message;
    }

    if (error.status === 404) {
        title = "404 Not Found";
        message = "Could not find page";
    }

    return (
        <div className="fixed my-auto w-screen min-h-screen items-center">
            <CardFit>
                <Header>{title}</Header>
                <Header2>{message}</Header2>
            </CardFit>
        </div>
    )
}

export default ErrorPage;