import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TextInput({name, id, type = "text", onChange, value, disabled = false}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return (
        <div>
        <input disabled={disabled} onChange={onChange} type={type} id={id} name={name} value={value} className={`${dark ? "bg-neutral-800 border-neutral-600  text-white" : "bg-white border-neutral-300  text-neutral-900" } border  text-sm rounded-lg focus:outline-none focus:outline-accent block w-full p-2.5`} placeholder={name} required/>
    </div>
    )
}

export default TextInput;