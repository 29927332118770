import { createSlice } from "@reduxjs/toolkit";

const initialNotificationsState = {
    items: [],
    changed: false
}

const notificationSlice = createSlice({
    name: 'notifications',
    initialState: initialNotificationsState,
    reducers: {
        replaceNotification(state, action) {
            state.items = action.payload.items;
        },
        addToNotification(state, action) {
            state.items.push({id:state.items.count,body:action.payload});
        },
        removeFromNotification(state, action) {
            const id = action.payload;
            const item = state.items.find(item => item.id === id);
            state.items = state.items.filter(item => item.id !== id);
            item.read = true;
            state.items.push(item);
        },
        removeAllNotification(state) {
            const i = state.items
            i.forEach(element => {
                element.read = true;
            });
            state.items = i;
        }
    }
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice;