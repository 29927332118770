import { useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";

function UserAvatar() {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  return (
    <Link to="/edit-profile">
      <div className="relative w-min">
        <svg width="183" height="183" viewBox="0 0 183 183" fill="none" xmlns="http://www.w3.org/2000/svg" className={` w-10 h-10 rounded-full bg-origin-border border-2 border-transparent ${dark ? "hover:border-white" : "hover:border-neutral-800"} fill-accent`}>
          <circle cx="91.5" cy="91.5" r="91" className=" fill-accent stroke-accent" />
          <path className="fill-secondary" d="m 92 90 c 13.8875 0 25.1429 -11.1914 25.1429 -25 c 0 -13.8086 -11.2554 -25 -25.1429 -25 c -13.8875 0 -25.1429 11.1914 -25.1429 25 c 0 13.8086 11.2554 25 25.1429 25 z m -8.9768 9.375 c -19.3482 0 -35.0232 15.5859 -35.0232 34.8242 c 0 3.2031 2.6125 5.8008 5.8339 5.8008 h 76.3322 c 3.2214 0 5.8339 -2.5977 5.8339 -5.8008 c 0 -19.2383 -15.675 -34.8242 -35.0232 -34.8242 h -17.9536 z" />
        </svg>
      </div>
    </Link>
  );
}

export default UserAvatar;
