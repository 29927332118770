import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function CrossButton({onClick}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
    <button
    id="cross_button"
        type="button"
        className={` bg-transparent ${dark ? "hover:bg-neutral-100 hover:text-neutral-300 text-white" : "hover:bg-neutral-200 hover:text-neutral-900 text-neutral-300" }  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center`}
        data-modal-hide="defaultModal"
        onClick={onClick}
      >
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="sr-only">Close</span>
      </button>
      )
}

export default CrossButton;