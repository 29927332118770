import Draggable from "react-draggable";
import ChatHolder from "./ChatHolder";
import CrossButton from "./UI/CrossButton";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

function ChatModal({ toggleModal, open }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const [typingEnabled, setTyping] = useState(false);
  const [messages, setMessage] = useState([])
  const [m, setM] = useState();
  const { width } = useWindowDimensions();

  function toggleTyping(message) {
    setTyping(!typingEnabled);
    setMessage([...messages, message])
  }

  function sendMessage(event) {
    event.preventDefault();
    if (m !== "" && m != null) {
      setMessage([...messages, m])
      setM("")
    }

  }

  function handleChange(event) {
    setM(event.target.value);
  }

  return (
    <Draggable handle=".handle">
      <div
        id="defaultModal"
        className={`fixed bottom-0 z-50  transition-transform ${open ? "-translate-x-0" : "translate-x-96"} overflow-y-auto pointer-events-none animate-slide-right ${width <= 640 ? "w-full" : "w-1/5"}`}
      >
        <div className="relative h-1/2 max-w-sm md:h-auto max-h-1/2">
          <div className={`relative  shadow ${dark ? "bg-neutral-600" : "bg-neutral-100"} pointer-events-auto`}>
            <div className={`handle flex items-center justify-between p-2 border-b-2 ${dark ? "bg-neutral-900 border-neutral-900" : "bg-neutral-100 border-neutral-200"}`}>
              <h3 className={`text-l ${dark ? "text-white" : "text-neutral-300"} font-semibold`}>How can I help you?</h3>
              <CrossButton onClick={toggleModal} />
            </div>
            <ChatHolder onChoose={toggleTyping} chats={messages} />
            <div className="space-x-2">
              <form>
                <label
                  for="text"
                  className="mb-2 text-sm font-medium text-neutral-900 sr-only dark:text-white">
                  Text
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  </div>
                  <input
                    type="text"
                    id="text"
                    disabled={!typingEnabled}
                    className={`block w-full p-4 text-sm  border-none ${dark ? "bg-neutral-900 focus:border-neutral-50 text-white" : "bg-neutral-50 focus:border-neutral-900 text-neutral-900"} focus:outline-accent`}
                    placeholder={typingEnabled ? "Type here..." : "Pick an Option"}
                    required
                    onChange={handleChange}
                    value={m}
                  />
                  <button
                    className={`text-black absolute right-2.5 bottom-2.5 px-4 py-2 `}
                    type="submit"
                    onClick={sendMessage}
                  >
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" className={`stroke-accent`}>
                      <path d="M1.66675 1.33342L6.33341 6.00008L1.66675 10.6667" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default ChatModal;
