import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SmallNavbar from "../components/SmallNavbar";
import Navbar from "../components/Navbar";
import TopNavbar from '../components/TopNavbar';
import Logo from '../images/RMGLogo.png'
import user from "../images/Icons/user-circle.svg"
import ChatModal from '../components/ChatModal';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { GetPriorityTriggered, GetProspectingData } from "../store/prospecting-actions";
import { GetNotificationData } from "../store/notifications-actions";
import { GetHistoryData } from "../store/history-actions";
import { GetCurrentUserData } from "../store/currentUser-actions";
import { GetCampaignData, GetCampaignImages, GetCampaignScripts } from "../store/campaign-actions"
import { GetOrganisationData } from "../store/organisation-actions";
import hexToHSL from "../hooks/hexToHsl";
import { GetCallOutcomes } from "../store/customer-actions";
import { GetReportingCount, GetIndividualCount } from "../store/reporting-actions";
import { GetServices } from "../store/booking-actions";

function RootPage({ toggle }) {
  const site = useSelector((state) => state.currentUser.currentSite);
  const userName = useSelector((state) => state.currentUser.item);
  const customers = useSelector((state) => state.prospecting.items);
  const camps = useSelector((state) => state.campaign.campaigns);
  const time = useSelector((state) => state.time.searchTime);
  const siteCampaignCommTemplates = useSelector((state) => state.campaign.siteComms);
  const location = useLocation();
  const { width } = useWindowDimensions()
  const [auth, setAuth] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(()=>{
    if(localStorage.getItem('token')==="none"){
      navigate("/auth");
    }
  },[])

  //fetching primary data (user, notifications, history)
  useEffect(() => {
    if(localStorage.getItem('token')!==null){
    dispatch(GetCurrentUserData());
    dispatch(GetNotificationData());
  }
  }, [localStorage.getItem('token')])

  //fetching secondary data (prospecting, organisation, campaign)
  useEffect(()=>{
    if (site !== "" && userName !== undefined) {
      dispatch(GetProspectingData(site));
      dispatch(GetOrganisationData(site));
      dispatch(GetCampaignData(site));
      dispatch(GetServices(site));
      //dispatch(GetHistoryData(userName.userName));
    }
  },[site,userName,dispatch])

  //fetching reporting data
  useEffect(()=>{
    if(camps.length!==0 && site!==""){
      camps.forEach(element => {
        dispatch(GetReportingCount({ url: element.url, id: element.id, siteId: site, fromDays: element.fromDays, date: time }))
      });
    }
  },[camps,site])
  
  useEffect(()=>{
    if(siteCampaignCommTemplates.length !==0 && site !== ""){
      siteCampaignCommTemplates.forEach(element => {
    dispatch(GetIndividualCount({siteCommId:element.id,date:time}))
    });
  }
  },[ siteCampaignCommTemplates, time])

  //fetching script
  useEffect(()=>{
    if(camps.length!==0 && site!==""){
        const ids = camps.map(c => c.id);
        dispatch(GetCampaignScripts(ids));
        dispatch(GetCampaignImages(ids));
    }
  },[camps,site])

  //fetching call outcomes
  useEffect(() => {
    if (customers.length !== 0) {
      const ids = customers.map(c => c.customerId)
      dispatch(GetCallOutcomes(ids));
      dispatch(GetPriorityTriggered(ids));
    }
  }, [customers])

  //change page layout if on login page
  useEffect(() => {
    if (location.pathname === '/auth' || location.pathname === '/tfa' || location.pathname === '/reset-password' || location.pathname === '/booking-gateway') {
      setAuth(true)
      setModalOpen(false)
      setSidebarOpen(false)
    } else {
      setAuth(false)
    }
  }, [location.pathname])

  //set accent and secondary colours
  const accentColour = useSelector((state) => state.organisation.colour);
  const secondaryColour = useSelector((state) => state.organisation.secondaryColour);

  const acc = hexToHSL(accentColour);
  const sec = hexToHSL(secondaryColour);

  var bodyStyles = document.body.style;
  bodyStyles.setProperty('--accent-color-h', acc.h);
  bodyStyles.setProperty('--accent-color-s', acc.s + '%');
  bodyStyles.setProperty('--accent-color-l', acc.l + '%');

  bodyStyles.setProperty('--secondaryaccent-color-h', sec.h);
  bodyStyles.setProperty('--secondaryaccent-color-s', sec.s + '%');
  bodyStyles.setProperty('--secondaryaccent-color-l', sec.l + '%');

  // toggle modal and sidebar
  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function toggleSidebar() {
    setSidebarOpen(!sidebarOpen);
  }

  //set logo
  const logoData = useSelector((state) => state.organisation.logo);
  var logo = "";
  if (logoData === "") {
    logo = Logo;
  } else {
    logo = `data:image/jpeg;base64,${logoData}`;
  }

  return (
    <Fragment>
      {!auth && <div>
        {modalOpen && <ChatModal toggleModal={toggleModal} open={modalOpen} />}
        {width <= 640 && sidebarOpen && <SmallNavbar sidebarOpen={sidebarOpen} logo={logo} onClick={toggle} toggleChat={toggleModal} toggleSidebar={toggleSidebar} />}
        {width > 640 && <Navbar sidebarOpen={sidebarOpen} logo={logo} onClick={toggle} toggleChat={toggleModal} toggleSidebar={toggleSidebar} />}
        <TopNavbar userName={userName.userName} sidebarOpen={sidebarOpen} icon={user} toggleSidebar={toggleSidebar} />
      </div>}
      <div className={`h-full fixed float-bottom w-full overflow-scroll  ${sidebarOpen ? (width > 640 && "transition-transform  scale-x-95 origin-right pl-12") : "transition-transform origin-right"}`}>
        <Outlet />
      </div>
    </Fragment>
  );
}

export default RootPage;
