import { createSlice } from "@reduxjs/toolkit";

const initialOrganisationState = {
    logo: "",
    colour: "#3498DB",
    secondaryColour: "#FFFFFF",
    sites: [],
    changed: false
}

const organisationSlice = createSlice({
    name: 'organisation',
    initialState: initialOrganisationState,
    reducers: {
        replaceOrganisation(state, action) {
            state.logo = action.payload.logo;
            //state.colour = localStorage.getItem("accent") !== null ? localStorage.getItem("accent"): action.payload.colour;
            state.sites = action.payload.sites;
        },
        changeColour(state, action) {
            state.colour = action.payload;
            localStorage.setItem("accent", action.payload);
        },
        addToOrganisation(state, action) {
            const newItem = action.payload;
            state.changed = true;
            state.items.push({ id: newItem.id, ucrn: newItem.ucrn, title: newItem.title, firstName: newItem.firstName, lastName: newItem.lastName, company: newItem.company, status: newItem.status, hot: newItem.hot });
        },
        removeFromOrganisation(state, action) {
            const id = action.payload;
            state.changed = true;
            state.items = state.items.filter(item => item.id !== id);
        }
    }
});

export const organisationActions = organisationSlice.actions;
export default organisationSlice;