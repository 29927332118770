import RingChartBacker from "./RingChartBacker";
import BarChartBacker from "./BarChartBacker";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import DonutChartBacker from "./DonutChartBacker";

function FourCharts({bar1, bar2, ring1, ring2}){
  const {width} = useWindowDimensions();

    return (<div className={`grid ${width<=800?"grid-cols-1 ":"grid-cols-2 h-[75vh] "} auto-rows-fr relative my-6 mx-auto max-w-7xl gap-4 mt-auto`}>
    <div  className=" row-span-2">
    <BarChartBacker loaded={bar1.loaded} title={bar1.title} xAxis={bar1.xAxis} yAxis={bar1.yAxis} data={bar1.data} barColour={bar1.barColour} timeFrame={bar1.timeFrame} callsMade={bar1.callsMade} callsToMake={bar1.callsToMake}/>
    </div>
    <DonutChartBacker title={ring1.title} maxValue={ring1.maxValue} loaded={ring1.loaded} timeFrame={ring1.timeFrame} data={ring1.data}/>
    <RingChartBacker title={ring2.title} maxValue={ring2.maxValue} loaded={ring2.loaded} timeFrame={ring2.timeFrame} data={ring2.data}/>
  </div>)
}

export default FourCharts;