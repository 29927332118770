import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TextInputLabeled({name, type = "text", onChange, value, id, readonly = false, required = true}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return (
        <div>
            <p  className={` pb-1 text-sm ${dark ? "text-neutral-300" : "text-neutral-300"}`}>{name}</p>
        <input readOnly={readonly} onChange={onChange} type={type} id={id} defaultValue={value} className={`${dark ? "bg-neutral-800  border-neutral-800 text-white" : "bg-neutral-100 border-neutral-100  text-neutral-900" } px-6 border text-sm rounded-lg focus:outline-none focus:outline-accent block w-full`}  required={required}/>
    </div>
    )
}

export default TextInputLabeled;