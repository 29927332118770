import { createSlice, createSelector } from "@reduxjs/toolkit";

export const selectContactsDomain = (state) => state.get('prospecting');

const getKeyword = (state) => state.get('prospecting').get('searchKey')

export const filterSelect = createSelector(
    [selectContactsDomain, getKeyword],
    (contactDomain, keyword) => contactDomain.get('prospecting').filter(i => i.customer === keyword)
)


const initialProspectingState = {
    items: [],
    filtered: [],
    changed: false,
    pageLength: 10,
    page: 1,
    totalPages: 1,
    totalPagesFiltered: 1,
    amount: 0,
    searchKey: "",
    appliedFilters: "",
    currentCount: 10,
    maxPoints: 0,
    multipleSortKeys: [],
    priorityTriggered: [],
    prospects: []
}

const prospectingSlice = createSlice({
    name: 'prospecting',
    initialState: initialProspectingState,
    reducers: {
        replaceProspecting(state, action) {
            const i = action.payload.items;
            state.items = sortDesc(i, "points");
            state.filtered = action.payload.items.slice(0, state.pageLength);
            state.amount = action.payload.items.length;
            state.totalPages = Math.ceil(state.amount / state.pageLength);
            const nums = i.map(item => item.points);
            state.maxPoints = Math.max(...nums);
        },
        replacePriorityTriggered(state, action) {
            state.priorityTriggered = action.payload.items;
        },
        addToCurrentProspects(state, action) {
            const countIds = state.prospects.map(a => a.id);
            const newCount = action.payload.items;
                if(!countIds.includes(newCount.id)){
                    state.prospects.push(newCount);
                }
                else 
                {
                    state.prospects = state.prospects.filter(c => c.id !== newCount.id);
                    state.prospects.push(newCount);
                }
        },
        getFilteredProspecting(state, action) {
            state.searchKey = action.payload;
            if (action.payload === "") {
                state.filtered = state.items;
            } else {
                state.filtered = state.items.filter(item => item.firstName.toLowerCase().includes(state.searchKey.toLowerCase()) || item.lastName.toLowerCase().includes(state.searchKey.toLowerCase()) || item.ucrn.toLowerCase().includes(state.searchKey.toLowerCase()));
            }
        },
        changePageLength(state, action) {
            state.pageLength = action.payload;
            state.currentCount = action.payload;
            state.totalPages = Math.ceil(state.amount / state.pageLength);
            state.filtered = state.items.slice(0, action.payload);
        },
        changePage(state, action) {
            let addPages = action.payload;

            let perPage = parseInt(state.pageLength);

            let nextProducts;
            if (addPages === 1) {
                let upperCount = parseInt(state.currentCount) + perPage;
                let lowerCount = state.currentCount;

                if (parseInt(lowerCount) < state.amount) {
                    state.currentCount = parseInt(state.currentCount) + parseInt(state.pageLength);
                    nextProducts = state.items.slice(lowerCount, upperCount);
                    state.page = state.page + 1;
                }
                else {
                    return;
                }
            }

            if (addPages === -1) {
                let upperCount = state.currentCount;
                let lowerCount = parseInt(state.currentCount) - perPage;

                if ((parseInt(lowerCount)) > 0) {
                    state.currentCount = lowerCount;
                    nextProducts = state.items.slice(lowerCount - perPage, upperCount - perPage);
                    state.page = state.page - 1;
                }
                else {
                    return;
                }
            }
            state.currentPage += addPages;

            state.filtered = nextProducts;
        },
        sortProspecting(state, action) {
            let sortedArr = action.payload.direction !== "none" && action.payload.direction === "asc" ?
                sortAsc(state.items, action.payload.id) :
                sortDesc(state.items, action.payload.id);

            state.page = 1;
            state.currentCount = state.pageLength;
            state.items = sortedArr;
            state.filtered = sortedArr.slice(0, state.pageLength);
        },
        sortMultipleProspecting(state, action) {
            let sortKeys = state.multipleSortKeys;
            if (action.payload.add === true) {
                sortKeys.push(action.payload.id);
            }
            else {
                sortKeys = sortKeys.filter(k => k !== action.payload.id);
            }

            const sortArr = state.items

            if (sortKeys.length !== 0) {
                sortArr.sort(sortBy(...sortKeys))
            }

            state.page = 1;
            state.currentCount = state.pageLength;
            state.multipleSortKeys = sortKeys
            state.items = sortArr;
            state.filtered = sortArr.slice(0, state.pageLength);
        },
        addToProspecting(state, action) {
            const newItem = action.payload[0];
            newItem.createdAt = newItem.createdAt.toString();
            state.changed = true;
            state.items.push(newItem);
        },

        editProspecting(state, action) {
            const newItem = action.payload;
            const exsistingItem = state.items.find(item => item.id === newItem.id);
            if (exsistingItem && newItem !== exsistingItem) {
                state.items = state.items.filter(item => item.id !== exsistingItem.id);
                state.items.push({ id: newItem.id, ucrn: newItem.ucrn, title: newItem.title, firstName: newItem.firstName, lastName: newItem.lastName, company: newItem.company, status: newItem.status, hot: newItem.hot });
            }
        },
        removeFromProspecting(state, action) {
            const id = action.payload;
            state.changed = true;
            state.items = state.items.filter(item => item.id !== id);
        }
    }
});

function sortAsc(arr, field) {
    return arr.sort(function (a, b) {
        if (a[field] > b[field]) {
            return 1;
        }
        if (b[field] > a[field]) {
            return -1;
        }
        return 0;
    })
}

function sortDesc(arr, field) {
    return arr.sort(function (a, b) {
        if (a[field] > b[field]) {
            return -1;
        }
        if (b[field] > a[field]) {
            return 1;
        }
        return 0;
    })
}

const
    sortBy = (...keys) => (a, b) => {
        var r = 0;
        keys.some(k => r = a[k] < b[k] || -(a[k] > b[k]));
        return r;
    };

export const prospectingActions = prospectingSlice.actions;
export default prospectingSlice;