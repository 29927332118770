import TextStatistic from "./TextStatistic";
import { Fragment, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import useWindowDimensions from "../hooks/useWindowDimensions";

function DashboardStats({ stats, loaded }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();
    const statList = stats.map((s, index) => index !== stats.length - 1 ? <Fragment key={index}><div  className={`${width <= 640 ? "" : "border-r"} w-full ${dark ? " border-neutral-300" : "border-neutral-300"}`}><TextStatistic loaded={loaded} key={s.name} statName={s.name} statNumber={s.number} time={s.time} /></div></Fragment> : <div key={index} className="w-full"><TextStatistic loaded={loaded} key={s.name} statName={s.name} statNumber={s.number} time={s.time} /></div>)


    return (
        <div className={`mb-6 ${dark ? "bg-neutral-900" : "bg-white"} ${width <= 640 ? "grid grid-cols-2" : "flex justify-between items-center mx-auto sm:space-x-6 max-w-7xl "} overflow-x-auto rounded-b-lg rounded-tr-lg shadow-md h-min  py-4 `}>
            {statList}
        </div>
    )
}

export default DashboardStats;