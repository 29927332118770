import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function ProgressBar({colour, percentage}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    return(
        <div className={`w-full  rounded-full h-2 ${dark ? "bg-neutral-500" : "bg-neutral-200"}`}>
            <div className={`${colour} h-2 rounded-full`} style={{ width: `${percentage}%`}}></div>
        </div>
    )
}

export default ProgressBar;