import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function DateSelect({toggle, calendar, calendarFilter}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    function setDate(e){
        calendarFilter(e.target.value,false);
    }

    return(<div className={`flex justify-between items-center w-full border gap-2 rounded-lg ${dark?"bg-neutral-600":"bg-white"}  border-neutral-300 mb-4 pr-2`}>
        <input onChange={setDate} type={calendar?"text":"date"} disabled={calendar}  className={`w-full bg-transparent border-transparent ${dark?"text-white":"text-neutral-900"} rounded-lg focus-visible:border-transparent focus:border-transparent`}/>
        <button onClick={toggle}>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={`${dark ? "stroke-white": "stroke-black"}`} d="M7 6.125V2.625M14 6.125V2.625M6.125 9.625H14.875M4.375 18.375H16.625C17.0891 18.375 17.5342 18.1906 17.8624 17.8624C18.1906 17.5342 18.375 17.0891 18.375 16.625V6.125C18.375 5.66087 18.1906 5.21575 17.8624 4.88756C17.5342 4.55937 17.0891 4.375 16.625 4.375H4.375C3.91087 4.375 3.46575 4.55937 3.13756 4.88756C2.80937 5.21575 2.625 5.66087 2.625 6.125V16.625C2.625 17.0891 2.80937 17.5342 3.13756 17.8624C3.46575 18.1906 3.91087 18.375 4.375 18.375Z"  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </button>
    </div>)
}

export default DateSelect;