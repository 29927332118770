import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function RoundedButton({text, onClick, white = false}) {
  const theme = useContext(ThemeContext);
  const {dark} = theme;

  return (
    <button
      id={text}
      type="button"
      className={`${dark ? white?"text-white border-white hover:text-white": "text-neutral-300 border-neutral-300 hover:text-secondary" : "text-neutral-300 hover:text-neutral-900"}  border ${dark ? "border-neutral-300" : "border-neutral-300"} hover:bg-accent hover:border-transparent focus:ring-4 focus:outline-none focus:ring-neutral-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-2 mb-2 dark:border-neutral-600 dark:text-neutral-400 dark:hover:text-white dark:hover:bg-neutral-600 dark:focus:ring-neutral-800`}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export default RoundedButton;
