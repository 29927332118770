import { createSlice } from "@reduxjs/toolkit";

const initialCurrentUserState = {
    item: {},
    details: {},
    changed: false,
    site: [],
    currentSite: "",
    tempEmail: "",
    tempPass: ""
}

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: initialCurrentUserState,
    reducers: {
        replaceCurrentUser(state, action) {
            state.item = action.payload.item;
            state.currentSite = action.payload.currentSite;
            state.site = action.payload.site;
            state.details = action.payload.details;
        },
        changeCredentials(state, action) {
            state.item.phoneNumber = action.payload.item.mobileNumber;
            state.item.email = action.payload.item.email;
            state.item.twoFactorEnabled = action.payload.item.enable2fa;
        },
        changeDetails(state, action) {
            state.details = action.payload.item;
        },
        changeCurrentSite(state, action) {
            const newItem = action.payload
            state.currentSite = newItem;
        },
        changeDetails(state, action) {
            const newItem = action.payload;
            state.item = newItem;
        },
        createTempLogin(state, action) {
            const newEmail = action.payload.email;
            state.tempEmail = newEmail;
            const newPass = action.payload.password;
            state.tempPass = newPass;
        },
        clearTempLogin(state) {
            state.tempEmail = "";
            state.tempPass = "";
        },
        default(state) { return state }
    }
});

export const currentUserActions = currentUserSlice.actions;
export default currentUserSlice;