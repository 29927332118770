import { bookingActions } from "./booking-slice";

export function GetServices(site) {
    return async (dispatch) => {
        const getServiceMaps = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify([
                site
            ]);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisation/GetServiceMaps",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch logo.');
            }

            if (response.status === 204) {
                return "";
            }

            const data = await response.json();

            return data;
        }

        const getServices = async (ids) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(ids);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisation/GetServices",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch logo.');
            }

            if (response.status === 204) {
                return "";
            }

            const data = await response.json();

            return data;
        }

        const maps = await getServiceMaps();
        const serviceIds = maps.map(m => m.serviceId);
        const services = await getServices(serviceIds);
        
        console.log(services);

        dispatch(bookingActions.setServices(services))

    }
};

export function GetTimeSlots(date) {
    return async (dispatch) => {
      const fetchTimes = async () => {
  
        var headers = {
          "Content-Type": "application/json",
          "Authorization": "bearer " + localStorage.getItem("token")
        }
        var raw = JSON.stringify(date);
  
        var requestOptions = {
          method: 'POST',
          headers: headers,
          redirect: 'follow',
          body: raw
        };
  
        const response = await fetch(process.env.REACT_APP_API_URL+"/Appointments/GetAvailability",
          requestOptions
        );
  
        if (!response.ok) {
          throw new Error('Could not get time slots.');
        }
        const data = await response.json();
  
        return data;
      };
  
      console.log(date)
      const slots = await fetchTimes();
  
      
      dispatch(bookingActions.setTimeSlots(slots))
    }
  };

  export function CreateAppointment(val) {
    return async (dispatch) => {
      const create = async (v) => {
  
        var headers = {
          "Content-Type": "application/json",
          "Authorization": "bearer " + localStorage.getItem("token")
        }
        var raw = JSON.stringify(v);
  
        var requestOptions = {
          method: 'POST',
          headers: headers,
          redirect: 'follow',
          body: raw
        };
  
        const response = await fetch(process.env.REACT_APP_API_URL+"/Appointments/Create",
          requestOptions
        );
  
        if (!response.ok) {
          throw new Error('Could not post appointment.');
        }
        const data = await response.json();
  
        return data;
      };

      await create(val);
    }
  };
  
  export function GetAppointment(val) {
    return async (dispatch) => {
      const get = async (v) => {
  
        var headers = {
          "Content-Type": "application/json",
          "Authorization": "bearer " + localStorage.getItem("token")
        }
  
        var requestOptions = {
          method: 'GET',
          headers: headers,
          redirect: 'follow',
        };
  
        const response = await fetch(process.env.REACT_APP_API_URL+"/Appointments/Get?id="+v,
          requestOptions
        );
  
        if (!response.ok) {
          return null;
        }
  
        if(response.status === 204){
          return null;
        }
  
        const data = await response.json();
  
        return data;
      };
  
      const appointment = await get(val);
      dispatch(bookingActions.findAppointment({app:appointment||null}))
      return appointment;
    }
  };
  
  export function DeleteAppointment(val) {
    return async (dispatch) => {
      const deleteAppointment = async (v) => {
  
        var headers = {
          "Content-Type": "application/json",
          "Authorization": "bearer " + localStorage.getItem("token")
        }
  
        var raw = JSON.stringify(v);
  
        var requestOptions = {
          method: 'POST',
          headers: headers,
          redirect: 'follow',
          body: raw
        };
  
        const response = await fetch(process.env.REACT_APP_API_URL+"/Appointments/Delete",
          requestOptions
        );
  
        if (!response.ok) {
          throw new Error('Could not delete appointment.');
        }
        const data = await response.json();
  
        return data;
      };
  
      await deleteAppointment(val);
    }
  };