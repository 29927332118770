import { useState } from "react";
import CallTable from "../components/Tables/CallTable";
import TransitionWrapper from "../components/TransitionWrapper";
import ProspModal from "../components/Tables/ProspModal";
import { createPortal } from "react-dom";

const DUMMY_SITES = [{ id: "s1", name: "Site1" }, { id: "s2", name: "Site2" }]

function ProspectingPage({ show }) {
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("")
  const [customer, setCustomer] = useState({})

  function modalToggle(e, customer) {
    setModal(!modal);
    setType(e);
    setCustomer(customer)
  }

  return (
    <TransitionWrapper show={show}>
      {modal && createPortal(<ProspModal modalToggle={modalToggle} sites={DUMMY_SITES} type={type} customer={customer} />, document.getElementById('modal'))}
      <CallTable modalToggle={modalToggle} modal={modal} />
    </TransitionWrapper>
  );
}

export default ProspectingPage;
