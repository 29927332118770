import CrossButton from "../UI/CrossButton";
import { Fragment, useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import RecordCall from "./RecordCall";
import NextCustomer from "./NextCustomer";

function CallCompleteModal({OpenEndModal, outcomes, customerId, outbound, user}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;    
    const [initial, setInitial] = useState(true);

    function changePage(){
        setInitial(false);
    }

    return(
        <Fragment>
        <div className="fixed justify-center z-30 w-screen h-screen opacity-50  bg-black blur-lg" ></div>
        <div className="absolute flex w-full h-full justify-center items-center z-40" >
        <div className={`fixed mx-auto p-8 z-50 ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg w-1/4 mb-10 justify-center items-center border border-accent shadow-md `}>
            <div className="flex justify-end">
            <CrossButton onClick={OpenEndModal}/>
            </div>
            {initial?
                <RecordCall outcomes={outcomes} customerId={customerId} outbound={outbound} user={user} changePage={changePage}/>:
                <NextCustomer OpenEndModal={OpenEndModal} customerId={customerId} outbound={outbound}/>
            }
        </div>
        </div>
        </Fragment>)
}

export default CallCompleteModal;