import { useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function CampClicks({ opens, clicks, recipients, width }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const [hover, setHover] = useState(false);
    const [o, setOpens] = useState(Math.round((opens / recipients) * 100));
    const [c, setClicks] = useState(Math.round((clicks / recipients) * 100));
    const [clicked, setClicked] = useState(false);

    function hoverOff() {
        if (!clicked) {
            var openPercent = Math.round((opens / recipients) * 100);
            setOpens(openPercent);
            var clickPercent = Math.round((clicks / recipients) * 100);
            setClicks(clickPercent);
            setHover(false);
        }
    }
    function hoverOn() {
        if (!clicked) {
            setHover(true);
            setClicks(clicks);
            setOpens(opens);
        }
    }
    function click() {
        if (hover) {
            setClicks(clicks);
            setOpens(opens);
            setHover(false)
        } else {
            var openPercent = Math.round((opens / recipients) * 100);
            setOpens(openPercent);
            var clickPercent = Math.round((clicks / recipients) * 100);
            setClicks(clickPercent);
            setHover(true)
        }
        setClicked(!clicked);
    }

    return (
        <div className={`w-full grid grid-cols-2 self-center h-min ${width <= 640 ? "" : "w-1/6"}`} onMouseEnter={hoverOn} onMouseLeave={hoverOff} onClick={click}>
            <div className={`border-r  ${dark ? "border-neutral-300" : "border-neutral-300"}`}>
                <h2 className={`text-md text-center cursor-default font-bold ${dark ? "text-white" : "text-black"}`}>{hover ? o : o + (!clicked && "%")}</h2>
                <p className={`text-center text-xs cursor-default ${dark ? "text-neutral-300" : "text-neutral-300"}`}>Opens</p>
            </div>
            <div>
                <h2 className={`text-md text-center cursor-default font-bold ${dark ? "text-white" : "text-black"}`}>{hover ? c : c + (!clicked && "%")}</h2>
                <p className={`text-center text-xs cursor-default ${dark ? "text-neutral-300" : "text-neutral-300"}`}>Clicks</p>
            </div>
        </div>
    )
}

export default CampClicks;