import { useContext } from "react";
import { Link } from 'react-router-dom';
import { ThemeContext } from "../context/ThemeContext";

function SidebarElement({ element: { id, name, image, link }, onChange, active, filter }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;

    function changeSelected() {
        onChange(id)
        if (filter != null) {
            filter(id);
        }
    }

    return (
        <li>
            <Link to={link}>
                <div key={id} onClick={changeSelected} className={`${active === id ? (dark ? "bg-neutral-800" : "bg-neutral-100") : ""} w-full flex items-center justify-center p-2 text-base ${dark ? "hover:bg-neutral-600" : "hover:bg-neutral-200"}  font-normal `}>
                    {active === id && <div className={`absolute left-0 w-1 h-10 bg-accent`} />}
                    <div className="pl-3">
                        <svg width="20" height="20" viewBox="0 0 20 20" className={` fill-accent`}>
                            <path fillRule="evenodd" clipRule="evenodd" d={image} />
                        </svg>
                    </div>
                    <span className={`flex-1 ml-6 whitespace-nowrap  font-bold text-left ${dark ? "text-white" : "text-neutral-900"}`}>{name}</span>
                </div>
            </Link>
        </li>
    )
}

export default SidebarElement;