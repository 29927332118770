function LoadingDots(){
    return(
    <div className="flex gap-x-2">
    <span className='sr-only'>Loading...</span>
     <div className='h-2 w-2 bg-neutral-300 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
   <div className='h-2 w-2 bg-neutral-300 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
   <div className='h-2 w-2 bg-neutral-300 rounded-full animate-bounce'></div>
</div>)
}

export default LoadingDots;