import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext"
import Header from "./UI/Header";
import NotificationItem from "./NotificationItem";
import { useDispatch } from "react-redux";
import { historyActions } from "../store/history-slice";
import FilterButton from "./UI/FilterButton";
import NotifFilter from "./NotifFilter";

function NotifHistory() {
    const { dark } = useContext(ThemeContext);
    const hist = useSelector((state) => state.notification.items);
    const [deleted, setDeleted] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [history] = useState(hist!==undefined?hist:[])
    const dispatch = useDispatch()

    const items = history.map(item => item.read && <NotificationItem id={item.id} body={item.body} time={item.time} flagged={item.flagged} show={deleted} deleted={item.deleted} flagControl={flag} deleteControl={deleteNotif} />)

    items.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

    function toggleDeleted() {
        setDeleted(!deleted);
    }

    function openFilter() {
        setFilterOpen(!filterOpen);
    }

    function flag(id) {
        const itemPrev = history.find(item => item.id === id);
        const item = JSON.parse(JSON.stringify(itemPrev))
        item.flagged = !item.flagged;
        dispatch(historyActions.updateHistory(item))
    }
    function deleteNotif(id) {
        const item = history.find(item => item.id === id);
        item.deleted = !item.deleted;
        dispatch(historyActions.updateHistory(item))
    }

    return (
        <div className={`relative mt-20 mx-auto py-4 max-w-7xl overflow-x-auto shadow-md sm:rounded-lg ${dark ? "bg-neutral-900" : "bg-white"}`}>
            {filterOpen && <NotifFilter open={openFilter} />}
            <div className={`flex items-center justify-start border-b-2 pb-4 ${dark ? "border-neutral-700" : "border-neutral-200"}  flex pl-6 min-h-fit`}>
                <svg className="w-6 h-6 pt-1" xmlns="http://www.w3.org/2000/svg" fill="current"><path fill="#F12B2C" d="M7.49926 0C6.90663 0 6.42785 0.474805 6.42785 1.0625V1.7C3.98368 2.19141 2.14219 4.33633 2.14219 6.90625V7.53047C2.14219 9.09102 1.56295 10.5984 0.518326 11.7672L0.270561 12.0428C-0.0106854 12.3549 -0.0776488 12.8031 0.0931079 13.185C0.263865 13.5668 0.648904 13.8125 1.07077 13.8125H13.9277C14.3496 13.8125 14.7313 13.5668 14.9054 13.185C15.0795 12.8031 15.0092 12.3549 14.728 12.0428L14.4802 11.7672C13.4356 10.5984 12.8563 9.09434 12.8563 7.53047V6.90625C12.8563 4.33633 11.0148 2.19141 8.57068 1.7V1.0625C8.57068 0.474805 8.09189 0 7.49926 0ZM9.01598 16.3791C9.41776 15.9807 9.64209 15.4395 9.64209 14.875H7.49926H5.35643C5.35643 15.4395 5.58076 15.9807 5.98254 16.3791C6.38432 16.7775 6.93007 17 7.49926 17C8.06845 17 8.6142 16.7775 9.01598 16.3791Z" /></svg>
                <div className={`pl-2 `}>
                    <Header acc={false}>{!deleted ? "Notifications" : "Notifications will be permanently deleted at midnight"}</Header>
                </div>
                <div className="absolute right-0">
                    <FilterButton openFilter={openFilter} />
                </div>
            </div>
            <ul >
                {items}
            </ul>
            <button className="pt-4 flex pl-4" onClick={toggleDeleted}>
                <svg className="pt-1" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={`${dark ? "fill-white" : "fill-black"}`} fillRule="evenodd" clipRule="evenodd" d="M10.8001 2.2998C10.5773 2.29992 10.3589 2.35947 10.1695 2.47179C9.97999 2.58411 9.82689 2.74476 9.7273 2.93575L8.8585 4.5998H4.8001C4.48184 4.5998 4.17661 4.72097 3.95157 4.93663C3.72653 5.1523 3.6001 5.44481 3.6001 5.7498C3.6001 6.0548 3.72653 6.34731 3.95157 6.56298C4.17661 6.77864 4.48184 6.8998 4.8001 6.8998V18.3998C4.8001 19.0098 5.05295 19.5948 5.50304 20.0261C5.95313 20.4575 6.56358 20.6998 7.2001 20.6998H16.8001C17.4366 20.6998 18.0471 20.4575 18.4972 20.0261C18.9472 19.5948 19.2001 19.0098 19.2001 18.3998V6.8998C19.5184 6.8998 19.8236 6.77864 20.0486 6.56298C20.2737 6.34731 20.4001 6.0548 20.4001 5.7498C20.4001 5.44481 20.2737 5.1523 20.0486 4.93663C19.8236 4.72097 19.5184 4.5998 19.2001 4.5998H15.1417L14.2729 2.93575C14.1733 2.74476 14.0202 2.58411 13.8307 2.47179C13.6413 2.35947 13.4229 2.29992 13.2001 2.2998H10.8001ZM8.4001 9.1998C8.4001 8.89481 8.52653 8.6023 8.75157 8.38663C8.97661 8.17097 9.28184 8.0498 9.6001 8.0498C9.91836 8.0498 10.2236 8.17097 10.4486 8.38663C10.6737 8.6023 10.8001 8.89481 10.8001 9.1998V16.0998C10.8001 16.4048 10.6737 16.6973 10.4486 16.913C10.2236 17.1286 9.91836 17.2498 9.6001 17.2498C9.28184 17.2498 8.97661 17.1286 8.75157 16.913C8.52653 16.6973 8.4001 16.4048 8.4001 16.0998V9.1998ZM14.4001 8.0498C14.0818 8.0498 13.7766 8.17097 13.5516 8.38663C13.3265 8.6023 13.2001 8.89481 13.2001 9.1998V16.0998C13.2001 16.4048 13.3265 16.6973 13.5516 16.913C13.7766 17.1286 14.0818 17.2498 14.4001 17.2498C14.7184 17.2498 15.0236 17.1286 15.2486 16.913C15.4737 16.6973 15.6001 16.4048 15.6001 16.0998V9.1998C15.6001 8.89481 15.4737 8.6023 15.2486 8.38663C15.0236 8.17097 14.7184 8.0498 14.4001 8.0498Z" />
                </svg>
                <div className="pl-4">
                    <h2 className={` font-semibold text-md ${dark ? "text-neutral-300" : "text-neutral-300"}`}>{deleted ? "View history" : "View deleted items"}</h2>
                </div>
            </button>
        </div>
    )
}

export default NotifHistory;