import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function Header3({children}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return <h2 className={` font-bold text-lg ${dark ? "text-neutral-300" : "text-neutral-900"}`}>{children}</h2>
}

export default Header3;