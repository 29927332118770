import { useState, useEffect, Fragment } from "react";

function TransitionWrapper({ children, show }) {
    const [num, setNum] = useState(0)

    useEffect(() => {
        let start = 0;
        const end = 10;
        if (start === end) {
            return;
        }
        let totalMilSecDur = parseInt(10);
        let incrementTime = (totalMilSecDur / end) * 100;

        let timer = setInterval(() => {
            start += 1;
            setNum(start);
            if (start === end) clearInterval(timer)
        }, incrementTime);
    }, [])

    if (num !== 10) {
        document.body.style.overflow = "hidden"
    }
    else {
        document.body.style.overflow = "auto"
    }

    return (
        <Fragment>
            {show && num !== 10 && <div id="defaultModal" className="bottom-0 fixed flex-none scale-y-110 min-w-screen h-screen grid grid-cols-8 inset-0 z-10 ">
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 1 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 2 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 3 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 4 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 5 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 6 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 7 && 'scale-y-0 rounded-t-full'}`} />
                <span className={`w-full h-full border-4 transition-transform origin-bottom bg-accent border-accent ${num >= 8 && 'scale-y-0 rounded-t-full'}`} />
            </div>}
            {children}
        </Fragment>
    )
}

export default TransitionWrapper;