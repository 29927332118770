import React from 'react';

const compare =(a, b) => {
    return b > a ? 1 : -1;
}

const Sort= ({children, by})=> {
if (!by) {
return children
}
else
{
return React.Children.toArray(children).sort(compare)
}
}

export default Sort;