import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function RingKey({ colour, name, amount }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  return (
    <div className="flex shrink justify-start items-center space-x-2 left-0 h-full pb-2 pl-6">
      <div className={`w-3 h-3 rounded-full aspect-square ${"bg-[" + colour + "]"}`}></div>
      <div>
        <p className={`${dark ? "text-neutral-200" : "text-neutral-900"} text-sm pl-2`}>{name}</p>
        <p className={`${dark ? "text-white" : "text-neutral-900"} font-bold pl-2`}>{amount}</p>
      </div>
    </div>
  );
}

export default RingKey;
