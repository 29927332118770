function SkeletonBarChart(){
    return(
        <div className=" w-full h-full px-6 pb-8 gap-x-4 opacity-50">
            <div className="flex">
            <div className="h-4 w-10 rounded-full bg-neutral-300 mb-4 ml-6 animate-pulse"></div>
            <div className="h-4 w-10 rounded-full bg-neutral-300 mb-4 ml-6 animate-pulse"></div>
            </div>
            <div className="flex w-full h-full gap-x-2">
                <div className="w-1/4 h-full space-y-4 flex flex-col items-end">
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse "></div>
                    <div className="bg-neutral-300 w-3/4 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-4/5 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-1/2 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-3/4 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-4/5 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-1/2 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                </div>
                <div className="w-3/4 h-full space-y-4">
                    <div className="bg-neutral-300 w-4/5 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-2/5 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-1/2 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-2/3 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-4/5 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-2/6 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-1/6 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-2/3 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-1/2 rounded-lg min-h-[30px] animate-pulse"></div>
                    <div className="bg-neutral-300 w-full rounded-lg min-h-[30px] animate-pulse"></div>
                </div>
            </div>
        </div>
    )
}

export default SkeletonBarChart;