import { Fragment, useContext } from "react";
import Header from "../UI/Header";
import { ThemeContext } from "../../context/ThemeContext"
import CrossButton from "../UI/CrossButton";

function CommentModal({ content, close }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    return (
        <Fragment>
            <div className={`absolute right-0 justify-center w-fit h-min max-w-2xl m-auto md:h-auto `}>
                <div className={`relative rounded-lg shadow ${dark ? "bg-neutral-800" : "bg-white"} border-2 border-accent`}>
                    <div className={`flex items-start justify-between px-4 pt-4 rounded-t pl-6`}>
                        <Header>Comment</Header>
                        <CrossButton onClick={close} />
                    </div>
                    <p className={`${dark ? "text-white" : "text-neutral-900"} p-6`}>{content}</p>
                </div>
            </div>
        </Fragment>
    )
}

export default CommentModal;