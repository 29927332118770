import { createSlice } from "@reduxjs/toolkit";

const initialCampaignState = {
    items: [],
    siteCampaigns: [],
    campaigns: [],
    filtered: [],
    type: [],
    comms: [],
    siteComms: [],
    sendGrid: [],
    scripts: [],
    images: [],
    searchKey: "",
    filter: "",
    typeFilter: [],
    loaded: false
}

const campaignSlice = createSlice({
    name: 'campaign',
    initialState: initialCampaignState,
    reducers: {
        replaceCampaign(state, action) {
            state.items = action.payload.items;
            state.filtered = action.payload.items;
            state.siteCampaigns = action.payload.siteCampaigns;
            state.campaigns = action.payload.campaigns;
            state.type = action.payload.type;
            state.siteComms = action.payload.siteComms;
            state.comms = action.payload.comms;
            state.loaded = true
        },
        replaceSendGrid(state, action) {
            const eventIds = state.sendGrid.map(a => a.id);
            const newEvent = action.payload;
            newEvent.forEach(element => {
                if(!eventIds.includes(element.id)){
                    state.sendGrid.push(element);
                }
            });
                
        },
        replaceScripts(state, action) {
            state.scripts = action.payload;
        },
        replaceImages(state, action) {
            state.images = action.payload;
        },
        getFilteredCampaign(state, action) {
            state.searchKey = action.payload.search;
            state.filter = action.payload.filter;
            state.typeFilter = action.payload.typeFilter;
            if (action.payload.search === "" && action.payload.filter === "" && action.payload.typeFilter.length === 0) {
                state.filtered = state.items;
            } else {
                if (action.payload.filter !== "") {
                    if (action.payload.filter === "ongoing") {
                        state.filtered = state.items.filter(item => item.progress < 100);
                    } else if (action.payload.filter === "complete") {
                        state.filtered = state.items.filter(item => item.progress === 100);
                    }
                    else {
                        state.filtered = state.items;
                    }
                    if (action.payload.typeFilter.length !== 0) {
                        if (action.payload.typeFilter.includes("email")) {
                            if (action.payload.typeFilter.includes("sms")) {
                                if (action.payload.typeFilter.includes("post")) {
                                    state.filtered = state.filtered.filter(item => item.sendType === "email" || item.sendType === "sms" || item.sendType === "post");
                                } else {
                                    state.filtered = state.filtered.filter(item => item.sendType === "email" || item.sendType === "sms");
                                }
                            }
                            else if (action.payload.typeFilter.includes("post")) {
                                state.filtered = state.filtered.filter(item => item.sendType === "email" || item.sendType === "post");
                            } else {
                                state.filtered = state.filtered.filter(item => item.sendType === "email");
                            }
                        } else if (action.payload.typeFilter.includes("sms")) {
                            if (action.payload.typeFilter.includes("post")) {
                                state.filtered = state.filtered.filter(item => item.sendType === "sms" || item.sendType === "post");
                            } else {
                                state.filtered = state.filtered.filter(item => item.sendType === "sms");
                            }
                        } else if (action.payload.typeFilter.includes("post")) {
                            state.filtered = state.filtered.filter(item => item.sendType === "post");
                        }
                    } if (action.payload.search !== "") {
                        state.filtered = state.filtered.filter(item => item.name.toLowerCase().includes(state.searchKey) || item.sendType.toLowerCase().includes(state.searchKey) || item.time.toLowerCase().includes(state.searchKey));
                    }
                }
                else if (action.payload.typeFilter.length !== 0) {
                    if (action.payload.typeFilter.includes("email")) {
                        if (action.payload.typeFilter.includes("sms")) {
                            if (action.payload.typeFilter.includes("post")) {
                                state.filtered = state.items.filter(item => item.sendType === "email" || item.sendType === "sms" || item.sendType === "post");
                            } else {
                                state.filtered = state.items.filter(item => item.sendType === "email" || item.sendType === "sms");
                            }
                        }
                        else if (action.payload.typeFilter.includes("post")) {
                            state.filtered = state.items.filter(item => item.sendType === "email" || item.sendType === "post");
                        } else {
                            state.filtered = state.items.filter(item => item.sendType === "email");
                        }
                    } else if (action.payload.typeFilter.includes("sms")) {
                        if (action.payload.typeFilter.includes("post")) {
                            state.filtered = state.items.filter(item => item.sendType === "sms" || item.sendType === "post");
                        } else {
                            state.filtered = state.items.filter(item => item.sendType === "sms");
                        }
                    } else if (action.payload.typeFilter.includes("post")) {
                        state.filtered = state.items.filter(item => item.sendType === "post");
                    } if (action.payload.search !== "") {
                        state.filtered = state.filtered.filter(item => item.name.toLowerCase().includes(state.searchKey) || item.sendType.toLowerCase().includes(state.searchKey) || item.time.toLowerCase().includes(state.searchKey));
                    }
                }
                else if (action.payload.search !== "") {
                    state.filtered = state.items.filter(item => item.name.toLowerCase().includes(state.searchKey) || item.sendType.toLowerCase().includes(state.searchKey) || item.time.toLowerCase().includes(state.searchKey));

                }
            }
        },
        addToCampaign(state, action) {
            const newItem = action.payload;
            state.changed = true;
            state.items.push({ id: newItem.id, ucrn: newItem.ucrn, title: newItem.title, firstName: newItem.firstName, lastName: newItem.lastName, company: newItem.company, status: newItem.status, hot: newItem.hot });
        },
        removeFromCampaign(state, action) {
            const id = action.payload;
            state.changed = true;
            state.items = state.items.filter(item => item.id !== id);
        }
    }
});

export const campaignActions = campaignSlice.actions;
export default campaignSlice;