import CampWords from "./CampWords";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { GetReportingCount, GetIndividualCount} from "../../store/reporting-actions";
import IndividualCampaignEvent from "./IndividualCampaignEvent";
import CampProgress from "./CampProgress";

function IndividualCampaign({ campaign, siteCampaignCommTemplates }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const dispatch = useDispatch();
    const site = useSelector((state) => state.currentUser.currentSite)
    const { width } = useWindowDimensions();
    const [showEvents, setShowEvents] = useState(false)
    const siteCampaigns = useSelector((state) => state.campaign.siteCampaigns);
    const reporting = useSelector((state) => state.reporting.counts);
    const siteCampaignId = siteCampaigns.find(s => s.siteId === site && s.campaignId === campaign.id);
    const time = useSelector((state) => state.time.searchTime);
    const [report, setReport] = useState({ num: 0 });
    const [sentCamps, setSentCamps] = useState({ num: 0 });
    const [progress, setProgress] = useState((100 * sentCamps.num) / report.num);
    const [scct] = useState(siteCampaignCommTemplates)

    const sc = scct.filter(s => s.siteCampaignsId === siteCampaignId.id);

    const map = sc.map((comms, index) => <IndividualCampaignEvent key={comms.id + index} siteComms={comms} campaignId={campaign.id} width={width} />)

    function toggleEvents() {
        setShowEvents(!showEvents);
    }

    useEffect(() => {
        //dispatch(GetReportingCount({ url: campaign.url, id: campaign.id, siteId: site, fromDays: campaign.fromDays, date: time }))
    }, [time, site, dispatch, campaign])

    useEffect(() => {
        setProgress((100 * sentCamps.num) / report.num);
    }, [sentCamps, report])

    useEffect(() => {
        if (reporting.length !== 0 && reporting[0] !== null && reporting[0] !== undefined) {
            const campaignAmount = reporting.find(c => c.id === campaign.id && c.date === time);
            if (campaignAmount !== undefined) {
                setReport(campaignAmount);
            }
        }

    }, [campaign.id, reporting, time])

    useEffect(() => {
        if (reporting.length !== 0) {
            const list = [];
            scct.forEach(element => {
                const f = reporting.find(b => element.id.includes(b.id) && b.date === time);
                if (f !== undefined) {
                    list.push(f);
                }
            });
            if (list.length !== 0) {
                let n = 0;
                list.forEach(element => {
                    n += element.nums;
                });
                setSentCamps({ nums: n });
            }
        }
    }, [reporting, scct, time])

    return (
        <li className={`flex flex-col h-min w-full  ${dark ? "odd:bg-neutral-600 bg-neutral-900 text-neutral-400" : "odd:bg-neutral-75 bg-white text-neutral-500"}`}>
            <div className={`flex w-full ${width <= 640 ? "flex-col justify-center px-2" : "flex-nowrap mx-auto px-4 justify-between"}`}>
                <div className="w-full">
                    <CampWords id={campaign.id} name={campaign.name} location={campaign.location} time={time} width={width} amount={report.num} />
                    <button className="flex items-center justify-start cursor-pointer w-full py-1" onClick={toggleEvents}>
                        <svg className={`${showEvents ? "" : "rotate-180"} stroke-accent hover:stroke-neutral-900`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.001 6L6.00098 1L1.00098 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <p className={`pl-2 text-xs text-neutral-300`}>Show Events</p>
                    </button>
                </div>
                <CampProgress name={campaign.id} data={{ campaign: campaign, comms: sc, date: time }} width={width} progress={progress} />
            </div>
            {showEvents && <ul className={``}>
                {map}
            </ul>}
        </li>
    )
}

export default IndividualCampaign;