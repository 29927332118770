import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";
import getUniqueId from "../hooks/getUniqueId";

export const selectContactsDomain = (state) => state.get('customers');

const getKeyword = (state) => state.get('customers').get('searchKey')

export const filterSelect = createSelector(
    [selectContactsDomain, getKeyword],
    (contactDomain, keyword) => contactDomain.get('customers').filter(i => i.customer === keyword)
)

const initialCustomerState = {
    address: [],
    callHistory: [],
    contact: [],
    details: [],
    vehicle: [],
    outcomes: [],
    filtered: [],
    searchKey: "",
    loaded: false,
    callHistoryLoaded: false
}

const customerSlice = createSlice({
    name: 'customers',
    initialState: initialCustomerState,
    reducers: {
        replaceCustomers(state, action) {
            state.loaded = false;
            const addIds = state.address.map(a => a.id);
            const newAddress = action.payload.address;
            for (let index = 0; index < newAddress.length; index++) {
                const element = newAddress[index];
                if (!addIds.includes(element.id)) {
                    state.address.push(element);
                }
            }
            const chIds = state.callHistory.map(a => a.id);
            const newCallHistory = action.payload.callHistory;
            if (newCallHistory.length !== 0) {
                for (let index = 0; index < newCallHistory.length; index++) {
                    const element = newCallHistory[index];
                    if (!chIds.includes(element.id)) {
                        state.callHistory.push(element);
                    }
                }
            }
            else {
                state.callHistory = []
            }
            const contIds = state.contact.map(d => d.id)
            const newContact = action.payload.contact;
            for (let index = 0; index < newContact.length; index++) {
                const element = newContact[index];
                if (!contIds.includes(element.id)) {
                    state.contact.push(element);
                }
            }
            const detIds = state.details.map(d => d.id)
            const newDetails = action.payload.details;
            for (let index = 0; index < newDetails.length; index++) {
                const element = newDetails[index];
                if (!detIds.includes(element.id)) {
                    state.details.push(element);
                }
            }
            const vehIds = state.vehicle.map(d => d.id)
            const newVehicle = action.payload.vehicle;
            for (let index = 0; index < newVehicle.length; index++) {
                const element = newVehicle[index];
                if (!vehIds.includes(element.id)) {
                    state.vehicle.push(element);
                }
            }
            state.outcomes = action.payload.outcomes;
            state.loaded = true;
        },
        getFilteredCustomers(state, action) {
            const item = [];
            const a = state.address.find(item => item.customer === action.payload);
            if (a !== undefined) {
                item.push(a);
            } else {
                item.push({ id: getUniqueId(), addressL1: "", addressL2: "", addressL3: "", addressL4: "", addressL5: "", postcode: "", customer: action.payload, createdAt: new Date().toString(), createdBy: "", modifiedAt: "", modifiedBy: "" })
            }
            const ch = state.callHistory.find(item => item.customerId === action.payload);
            if (ch !== undefined) {
                item.push(ch);
            }
            else {
                item.push({ outbound: false, answered: false, comments: "", bookAppointmentClicked: "", customerId: action.payload })
            }
            const c = state.contact.find(item => item.customer === action.payload);
            if (c !== undefined) {
                item.push(c);
            }
            else {
                item.push({ id: getUniqueId(), email: "", altEmail: "", tel: "", altTel: "", customer: action.payload, createdAt: new Date().toString(), createdBy: "", modifiedAt: "", modifiedBy: "" })
            }
            const d = state.details.find(item => item.customer === action.payload);
            if (d !== undefined) {
                item.push(d);
            } else {
                item.push({ id: getUniqueId(), title: "", firstName: "", lastName: "", notes: "", customer: action.payload, createdAt: new Date().toString(), createdBy: "", modifiedAt: "", modifiedBy: "" })
            }
            state.filtered = item;
        },
        createCustomers(state, action) {
            const newDetails = action.payload.details;
            newDetails.createdAt = newDetails.createdAt.toString();
            const newContact = action.payload.contact;
            newContact.createdAt = newContact.createdAt.toString();
            const newAddress = action.payload.address;
            newAddress.createdAt = newAddress.createdAt.toString();
            state.details.push(newDetails);
            state.contact.push(newContact);
            state.address.push(newAddress);
            state.changed = true;
        },
        addCallHistory(state, action) {
            const newHistory = action.payload;
            console.log(newHistory);
            newHistory.createdAt = newHistory.createdAt.toString();
            state.callHistory.push(newHistory);
            state.callHistoryLoaded = true;
        },
        editCustomers(state, action) {
            const newDetails = action.payload.details;
            newDetails.modifiedAt = newDetails.modifiedAt.toString();
            const newContact = action.payload.contact;
            newContact.modifiedAt = newContact.modifiedAt.toString();
            const newAddress = action.payload.address;
            newAddress.modifiedAt = newAddress.modifiedAt.toString();
            const exsistingDetails = state.details.find(item => item.id === newDetails.id);
            const exsistingContact = state.contact.find(item => item.id === newContact.id);
            const exsistingAddress = state.address.find(item => item.id === newAddress.id);

            if (exsistingDetails && newDetails !== exsistingDetails) {
                state.details = state.details.filter(item => item.id !== exsistingDetails.id);
                state.details.push({
                    id: newDetails.id,
                    firstName: newDetails.firstName,
                    lastName: newDetails.lastName,
                    title: newDetails.title,
                    customer: newDetails.customer,
                    createdAt: newDetails.createdAt,
                    createdBy: newDetails.createdBy
                });
            }

            if (exsistingContact && newContact !== exsistingContact) {
                state.contact = state.contact.filter(item => item.id !== exsistingContact.id);
                state.contact.push({
                    id: newContact.id,
                    email: newContact.email,
                    tel: newContact.tel,
                    altEmail: newContact.altEmail,
                    altTel: newContact.altTel,
                    customer: newContact.customer,
                    createdAt: newContact.createdAt,
                    createdBy: newContact.createdBy
                });
            }

            if (exsistingAddress && newAddress !== exsistingAddress) {
                state.address = state.address.filter(item => item.id !== exsistingAddress.id);
                state.address.push({
                    id: newAddress.id,
                    addressL1: newAddress.addressL1,
                    addressL2: newAddress.addressL2,
                    addressL3: newAddress.addressL3,
                    addressL4: newAddress.addressL4,
                    addressL5: newAddress.addressL5,
                    postcode: newAddress.postcode,
                    customer: newAddress.customer,
                    createdAt: newAddress.createdAt,
                    createdBy: newAddress.createdBy
                });
            }
        }

    },
    removeFromCustomers(state, action) {
        const id = action.payload;
        state.changed = true;
        state.items = state.items.filter(item => item.id !== id);
    }
});

export const customerActions = customerSlice.actions;
export default customerSlice;