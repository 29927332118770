import SmallCalendar from "./SmallCalendar";
import { Fragment, useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import SquareRadio from "./SquareRadio";
import DateSelect from "./DateSelect";
import CrossButton from "./CrossButton";
import TickBoxDropdown from "./TickBoxDropdown";
import MediumDropdown from "./MediumDropdown";
import { useSelector } from "react-redux";

function FilterMenu({filterControl, close, hotFilter, filterCampaign, filterSite, filterCallperson, salesPeople, filterDate}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const [useRange, setRange] = useState(false);
    const [cal, setCal] =useState(false);
    const sites = useSelector(state => state.organisation.sites)
    const campaigns = useSelector(state => state.campaign.campaigns)

    function rangeManager(){
        setRange(!useRange);
    }

    function calendarFilter(val, range){
        filterDate(val, range);
    }

    function openCalendar(){
        setCal(!cal);

    }   
    
    const options = campaigns.map(c => {return{name:c.name, id:c.id, value:c.id}})
    const siteOptions = sites.map((s,index) => {return{name:s.name, id:index, value:s.id}})

    const salesOptions = salesPeople.map((s,index) => {return{name:s, id:index, value:s};});
    salesOptions.push({name:"none",id:"none",value:null})

    return(
        <div className={`fixed z-50 min-w-fit ${dark ? "bg-neutral-800 " : "bg-neutral-75 " } p-4 rounded-lg shadow mt-2 border-2 border-accent`}>
            <div className="flex w-full justify-end pb-2">
            <CrossButton onClick={close}/>
            </div>
            <DateSelect toggle={openCalendar} calendar={cal} calendarFilter={calendarFilter}/>
            {cal && <Fragment><SmallCalendar useRange={useRange} onChange={calendarFilter}/>
            <div className="py-2"><SquareRadio name={"Show date range"} boxCol={"border-neutral-300"} crossCol={"text-neutral-300"} onClick={rangeManager}/></div>
            </Fragment>}
            <p className={`${dark? "text-neutral-300" : "text-neutral-600"} font-bold pb-2`}>View</p>
            <div className={`border rounded-lg p-2 ${dark? "border-neutral-300 bg-neutral-600" : "border-neutral-300 bg-white"}`}>
            <SquareRadio id={"hot"} name={"Hot leads"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl} def={hotFilter.includes("hot")}/>
            <SquareRadio id={"warm"} name={"Warm leads"} boxCol={"border-yellow-300"} crossCol={"text-yellow-300"} onClick={filterControl} def={hotFilter.includes("warm")}/>
            <SquareRadio id={"cold"} name={"Cool leads"} boxCol={"border-blue-500"} crossCol={"text-blue-500"} onClick={filterControl} def={hotFilter.includes("cold")}/>
            </div>
            <div className="pt-4">
            <TickBoxDropdown options={options} onChange={filterCampaign} title={"Filter Campaigns"}/>
            </div>
            <div className="pt-4">
            <TickBoxDropdown options={siteOptions} onChange={filterSite} title={"Filter Sites"}/>
            </div>
            <div className="pt-4">
            <MediumDropdown options={salesOptions} onChange={filterCallperson} title={"Filter Sales Person"}/>
            </div>
        </div>
    )
}

export default FilterMenu;