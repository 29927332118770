import DropDownItem from "./DropDownItem";
import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { Link } from "react-router-dom";
import CrossButton from "./CrossButton";

function DropDownMenu({items, toggle, onClick, closeable = true}) {
  const theme = useContext(ThemeContext);
  const {dark} = theme;

  function change(item){
    toggle();
    onClick(item);
  }

    const dropDownList = items.map((item) => ( item.link != null ? <Link key={item.id} to={item.link} state={item.parent}><DropDownItem name={item.name} id={item.id} value={item.value} onClick={item.onClick} parent={item.parent}/></Link> : <DropDownItem key={item.id} value={item.value} name={item.name} id={item.id} onClick={change}/>))
  return (
    <div
      className={`fixed z-50 ${dark ? "bg-neutral-900 divide-neutral-800" : "bg-white divide-neutral-100" } rounded-lg shadow `}
    >
      <div className="flex justify-end">
      {closeable&&<CrossButton onClick={toggle}/>}
      </div>
      <ul
        className={`pb-3 space-y-1 text-sm  ${dark ? "text-neutral-300" : "text-neutral-700"} `}
        >
            {dropDownList}
      </ul>
    </div>
  );
}

export default DropDownMenu;
