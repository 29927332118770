import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { GetSendGridCampaignData } from "../../store/campaign-actions";
import { useDispatch, useSelector } from "react-redux";

function IndPriorityCallRow({ data, openModal, type, campaigns }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const sendGridEvents = useSelector((state) => state.campaign.sendGrid);
    const [campaign, setCampaign] = useState();
    const [events, setEvents] = useState(sendGridEvents);

    useEffect(()=>{
        dispatch(GetSendGridCampaignData(data.relatedId));
    },[data])
    
    useEffect(()=>{
        setEvents(sendGridEvents);
      },[sendGridEvents])

    useEffect(()=>{
        if(events !== undefined && campaigns !== undefined){
            if(events.length!==0 && campaigns.length !==0){
            const event = events.find(c => c.id === data.relatedId);
            if(event!== undefined){
                const camp = campaigns.find(c => c.id === event.campaignId);
                setCampaign(camp);
                console.log(camp)
            }
            }
        }
    },[campaigns, events])

    function openModalButton() {
        openModal("The campaign that sent this email was: "+campaign.name)
    }

    const date = new Date(data.createdAt);

    return(
        <tr className={`w-min py-2 ${dark?"odd:bg-neutral-600 bg-neutral-900":"odd:bg-neutral-75 bg-white"} text-sm ${width<=640?"flex flex-col border-neutral-300 border":"text-center"} ${dark? "text-white" : "text-neutral-600"}`}>
    <td className={`${width>640?"pl-2 text-left py-2 ":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Date: </span>}{date.toLocaleDateString()}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Time: </span>}{date.getHours() + ":" + date.getMinutes()}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Duration: </span>}N/A</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Type: </span>}{type}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>In/Outbound: </span>}{data.reason}</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Answered: </span>}N/A</td>
    <td className={`${width>640?"text-left":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Outcome: </span>}N/A</td>
    <td className={`${width>640?"text-left ":""}`}>{width<=640&&<span className={`text-accent font-semibold`}>Notes: </span>}{<button value={campaign!== undefined?campaign.name:""} onClick={openModalButton} disabled={campaign === undefined} className="">{campaign !== undefined?"View":"..."}</button>}</td>
    </tr>
    )
}

export default IndPriorityCallRow;