import MediumDropdown from "../UI/MediumDropdown";
import { Fragment, useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LargeTextBox from "../UI/LargeTextBox";
import getUniqueId from "../../hooks/getUniqueId";
import SolidButton from "../UI/SolidButton";
import { useDispatch } from "react-redux";
import { CreateNewCallHistory } from "../../store/customer-actions";

function RecordCall({ outcomes, customerId, outbound, user, changePage }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const dispatch = useDispatch()
    const [answer, setAnswer] = useState(true)
    const [completed, setCompleted] = useState({ id: getUniqueId(), customerId: customerId, outbound: outbound, answered: true, comments: null, bookAppointmentClicked: null, createdAt: new Date(), modifiedAt: null, createdBy: user, modifiedBy: null, callOutcomeId: outcomes[0].id });

    const outcomeOptions = outcomes.map((o) => { return { name: o.name, id: o.id, value: o.id } })

    function setOutcome(value) {
        setCompleted(prevState => ({
            ...prevState,
            callOutcomeId: value
        }))
    }
    function setAnswered(e) {
        if (e.target.value === "true") {
            setAnswer(true);
            setCompleted(prevState => ({
                ...prevState,
                answered: true
            }))
        } else {
            setAnswer(false);
            setCompleted(prevState => ({
                ...prevState,
                answered: false
            }))
        }
    }
    function setComments(value) {
        setCompleted(prevState => ({
            ...prevState,
            comments: value
        }))
    }

    function createHistory() {
        dispatch(CreateNewCallHistory(completed))
        changePage();
    }

    return (
        <Fragment>
            <p className={` ${dark ? "text-white" : "text-neutral-900"}`}>Was the call connected?</p>
            <div className="flex space-x-2 py-2">
                <button
                    type="button"
                    className={` ${answer === true ? "bg-green-1000" : "bg-neutral-300"}  text-white hover:-translate-y-1 focus:ring-4 focus:outline-none w-full font-medium rounded-lg text-sm px-5 py-1.5 text-center mb-2`}
                    onClick={setAnswered}
                    value={"true"}>
                    Yes
                </button>
                <button
                    type="button"
                    className={` ${answer === false ? "bg-red-1000" : "bg-neutral-300"}  text-white hover:-translate-y-1 focus:ring-4 focus:outline-none w-full font-medium rounded-lg text-sm px-5 py-1.5 text-center mb-2`}
                    onClick={setAnswered}
                    value={"false"}>
                    No
                </button>
            </div>
            <div className="pb-4">
                <p className={` ${dark ? "text-white" : "text-neutral-900"} pb-2`}>Select call outcome:</p>
                <MediumDropdown options={outcomeOptions} onChange={setOutcome} />
            </div>
            <p className={` pb-2 ${dark ? "text-white" : "text-neutral-900"}`}>Add Comments:</p>
            <LargeTextBox onChange={setComments} />
            <div className="pt-4 flex justify-center">
                <SolidButton text={"Next"} onClick={createHistory} />
            </div>
        </Fragment>)
}

export default RecordCall;