import { currentUserActions } from "./currentUser-slice";

export function GetCurrentUserData() {
  return async (dispatch) => {
    const fetchData = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/GetDetails",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }
      const data = await response.json();

      return data;
    }
    const fetchSite = async (user) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };
      const response = await fetch(process.env.REACT_APP_API_URL+"/UserSites/GetSiteByUser?id=" + user,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch user site.');
      }

      const data = await response.json();

      let ids = [];
      data.forEach(element => {
        ids.push(element.siteId);
      });
      return ids;
    }
    const fetchDetails = async (user) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };
      const response = await fetch(process.env.REACT_APP_API_URL+"/PersonInformation/GetUserDetails?id=" + user,
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch user site.');
      }

      const data = await response.json();

      return data;
    }

    const details = await fetchData();
    const site = await fetchSite(details.id)
    const data = await fetchSite(details.id)

    console.log(site);
    dispatch(currentUserActions.replaceCurrentUser({ item: details || {}, site: site || [], currentSite: site[0] || {}, details: data || {} }))


  }
};

export function ChangeUserDetails(value) {
  return async (dispatch) => {
    const fetchData = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      
      var raw = JSON.stringify(value);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body:raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/PersonInformation/UpdateDetails",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not change details.');
      }

      const data = await response.json();

      return data;
    }

    await fetchData();
    dispatch(currentUserActions.changeCDetails({ item: value || {} }))

  }
};

export function ChangeCurrentUserData(value) {
  return async (dispatch) => {
    const fetchData = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      
      var raw = JSON.stringify(value);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body:raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/ChangeCredentials",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not change credentials');
      }

      const data = await response.json();

      return data;
    }

    await fetchData();
    dispatch(currentUserActions.changeCredentials({ item: value || {} }))

  }
};

export function changePassword(value) {
  return async () => {
    const postPassword = async (i) => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      
      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/ChangePassword",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }
    }

     await postPassword(value);
  }
};

export function forgottenPassword(value) {
  return async () => {
    const fetchPasswordReset = async (i) => {

      var headers = {
        "Content-Type": "application/json",
      }
      
      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/ForgottenPassword",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }
    }
      console.log(value);
      await fetchPasswordReset(value);
  }
};

export function ResetPassword(value) {
  return async () => {
    const resetPassword = async (i) => {

      var headers = {
        "Content-Type": "application/json",
      }
      
      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
        body: raw
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Authentication/ResetPassword",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }

      alert("Your password has been changed, please close this page.");
    }
      await resetPassword(value);
  }
};