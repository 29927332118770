function SolidButton({text, onClick, type, disabled}) {

    return (
      <button
        type={type !== null? type :"button"}
        className={`  `}
        onClick={onClick}
        disabled={disabled}
      >
      <div className={`${!disabled && 'hover:-translate-y-1'} bg-accent w-full h-full disabled:bg-neutral-500 text-secondary focus:ring-4 focus:outline-none focus:ring-neutral-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center`}>
        {text}</div>
      </button>
    );
  }
  
  export default SolidButton;
  