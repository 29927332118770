import Draggable from "react-draggable";
import CrossButton from "./UI/CrossButton";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import ActivityElement from "./ActivityElement";
import { useSelector, useDispatch } from "react-redux";
import { notificationActions } from "../store/notifications-slice";
import useWindowDimensions from "../hooks/useWindowDimensions";

function ActivityList({ toggleModal }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const notifs = useSelector((state) => state.notification.items);
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [elements] = useState(notifs!==undefined?notifs:[]);

  function clearSingle(item) {
    setTimeout(() => {
      dispatch(notificationActions.removeFromNotification(item.id))
    }, 700)
  }

  function clearAll() {
    for (let index = 0; index < elements.length; index++) {
    }
    dispatch(notificationActions.removeAllNotification())
  }

  const activity = elements.map(element => element.read === false && <ActivityElement key={element.id} element={element} onClick={clearSingle} />)

  return (
    <Draggable
      handle=".handle">
      <div
        id="defaultModal"
        className={`fixed right-16 z-50 overflow-y-auto pointer-events-none ${width <= 640 ? "w-full" : "w-1/6"} border-2 border-accent`}
      >
        <div className="relative h-1/2 max-w-sm md:h-auto max-h-1/2">
          <div className={`relative gap-y-2 shadow ${dark ? "bg-neutral-800" : "bg-white"} pointer-events-auto`}>
            <div className={`handle flex items-center justify-between p-2  ${dark ? "bg-neutral-900" : "bg-neutral-100 "}`}>
              <h2 className={`text-l pl-2 font-bold ${dark ? "text-white" : "text-neutral-300"}`}>Activity</h2>
              <CrossButton onClick={toggleModal} />
            </div>
            {elements.length > 0 && <p className="flex justify-end text-sm pt-1 text-sky-500 pr-2 cursor-pointer hover:underline" onClick={clearAll}>Mark all as read</p>}
            <ul className="pt-2">
              {activity}
            </ul>
            {elements.length === 0 && <p className={`font-semibold text-center py-4 ${dark ? "text-neutral-500" : "text-neutral-400"}`}>No Activity</p>}
          </div>
        </div>
      </div>
    </Draggable>
  );
}

export default ActivityList;