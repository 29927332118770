import React from "react";
import ReactEcharts from "echarts-for-react";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";

function BarChart({ xAxis, yAxis, data, barColour, callsMade, callsToMake }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const { width } = useWindowDimensions();

  const option = {
    responsive: false,
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    legend: {
      padding: [2, 10],
      align: 'left',
      left: 'left',
      bottom: '95%',
      data: [
        { name: 'Calls made', icon: 'circle' },
        { name: 'Calls to make', icon: 'circle' },
      ],
      textStyle: {
        color: dark ? "#FFFFFF" : "#000000",
      }
    },
    yAxis: {
      ...yAxis,
      type: 'category',
      axisTick: {
        show: false
      }, axisLabel: {
        color: dark ? "#FFFFFF" : "#000000",
        width: width >= 400?200:100,
        overflow: 'break'
      }
    },
    xAxis: {
      ...xAxis,
      type: 'value',
      axisLabel: {
        color: dark ? "#FFFFFF" : "#000000"
      },
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: "#E9ECEF"
        }
      }
    },
    tooltip: {

    },
    series: [
      callsMade,
      callsToMake
    ]
  };
  return (<ReactEcharts option={option} opts={{ renderer: 'svg' }}
    style={{
      height: '100%',
      width: '100%',
    }}
    className=" min-h-[300px]" />);
}

export default BarChart;