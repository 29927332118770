import TextInput from "./UI/TextInput";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import LargeCircleImage from "./UI/LargeCircleImage";
import { useSelector, useDispatch } from "react-redux";
import AccentFullButton from "./UI/AccentFullButton";
import { Link } from "react-router-dom";
import { organisationActions } from "../store/organisation-slice";
import RoundedButton from "./UI/RoundedButton";
import { ChangeCurrentUserData, ChangeUserDetails, changePassword } from "../store/currentUser-actions";

function EditProfile({ logout }) {
  const userData = useSelector((state) => state.currentUser.item);
  const userDetails = useSelector((state) => state.currentUser.details);
  const dispatch = useDispatch()
  const [details, setDetails] = useState(userDetails);
  const [user, setUser] = useState({email:userData.email,mobileNumber:userData.phoneNumber,enable2fa:userData.twoFactorEnabled})
  const [pass, setPass] = useState({password:"",newPassword:"",confirmedPassword:""})
  const [col, setCol] = useState();
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  console.log(user);

  useEffect(() => {
    setUser(userData);
  }, [userData])

  function changeCredentials(e) {
    e.preventDefault();
    dispatch(ChangeCurrentUserData(user));
  }
  
  function changeDetails(e) {
    e.preventDefault();
    dispatch(ChangeUserDetails(details));
  }
  
  function changePass(e) {
    e.preventDefault();
    dispatch(changePassword(pass));
  }
  
  function onChangeDetails(e) {
    setDetails(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  function onChange(e) {
    setUser(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }
  
  function onChangePass(e) {
    setPass(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }))
  }

  function typeCol(e) {
    setCol(e.target.value);
  }

  function changeColour(e) {
    e.preventDefault();
    dispatch(organisationActions.changeColour(col));
  }

  return (
    <div className=" h-screen w-screen mt-20">
      <div className="relative w-full z-10">
        <LargeCircleImage image={"m 92 90 c 13.8875 0 25.1429 -11.1914 25.1429 -25 c 0 -13.8086 -11.2554 -25 -25.1429 -25 c -13.8875 0 -25.1429 11.1914 -25.1429 25 c 0 13.8086 11.2554 25 25.1429 25 z m -8.9768 9.375 c -19.3482 0 -35.0232 15.5859 -35.0232 34.8242 c 0 3.2031 2.6125 5.8008 5.8339 5.8008 h 76.3322 c 3.2214 0 5.8339 -2.5977 5.8339 -5.8008 c 0 -19.2383 -15.675 -34.8242 -35.0232 -34.8242 h -17.9536 z"} />
      </div>
      <div
        className={`static my-6 mx-auto max-w-7xl pt-24 mt-32  ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg`}
      >
        <div className="p-6 w-full">
          <h2 className={`text-accent font-bold pb-4 text-lg`}>Personal Information</h2>
          <form className={`border-b pb-4 w-full border-neutral-300`}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <TextInput key="First Name" name="First Name" id="firstName" onChange={onChangeDetails}/>
              <TextInput key="Last Name" name="Last Name" id="lastName" onChange={onChangeDetails}/>
            </div>
            <div className="w-[12%]">
              <AccentFullButton text="Update" onClick={changeDetails} type="submit" />
            </div>
          </form>
          <h2 className={`text-neutral-300 font-bold py-4`}>Change Login Details</h2>
          <form className={`border-b pb-4 w-full border-neutral-300`}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <TextInput key="Email" id="email" name="Email"  onChange={onChange} />
              <TextInput key="Phone Number" id="phoneNumber" name="Phone Number" onChange={onChange} />
            </div>
            <div className="w-[12%]">
              <AccentFullButton text="Update" onClick={changeCredentials} type="submit" />
            </div>
          </form>
          <div className="py-4">
          <h2 className={`text-neutral-300 font-bold `}>Change Password</h2>
          <p className={` ${dark?"text-neutral-500":"text-neutral-400"}`}>Password should be at least 6 characters and include at least: one capital letter, one lowercase letter, one special character and one number</p>
          </div>
          <form className={` pb-4 `}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <TextInput key="Current Password" name="Current Password" id="password" type="password" onChange={onChangePass}/>
              <TextInput key="New Password" name="New Password" id="newPassword" type="password" onChange={onChangePass}/>
              <TextInput key="Confirmed Password" name="Confirm Password" id="confirmedPassword" type="password" onChange={onChangePass}/>
            </div>
            <div className="w-[12%]">
              <AccentFullButton text="Change Password" onClick={changePass} type="button" />
            </div>
          </form>
          <div className="flex justify-center w-full pt-4">
            <Link to="/auth"><RoundedButton text="Logout" onClick={logout} white={true} /></Link>
          </div>
        </div>
      </div>
    </div>

  );
}

export default EditProfile;
