import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import EditProfilePage from "./pages/EditProfilePage";
import FAQPage from "./pages/FAQPage";
import ProspectingPage from "./pages/ProspectingPage";
import RootPage from "./pages/RootPage";
import { ThemeContext } from './context/ThemeContext';
import useDarkMode from "./hooks/useDarkMode";
import AuthPage, { A as authAction } from "./pages/AuthPage";
import TwoFactorPage, { T as twoFactor } from "./pages/TwoFactorPage";
import { tokenLoader, checkToken } from "./utils/auth";
import { useEffect, useState } from "react";
import ErrorPage from "./pages/ErrorPage";
import HistoryPage from "./pages/HistoryPage";
import BookingPage from "./pages/BookingPage";
import CampaignsPage from "./pages/CampaignsPage";
import CampaignDetailsPage from "./pages/CampaignDetailsPage";
import Outbound from "./components/Tables/Outbound";
import EditAuthPage from "./pages/EditAuthPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import BookingGatewayPage from "./pages/BookingGatewayPage";
import BookingAlreadyPage from "./pages/BookingAlreadyPage";
// import { useDispatch } from 'react-redux';
// import { prospectingActions } from "./store/prospecting-slice";


function App() {
  const { darkMode, toggle } = useDarkMode()
  const [showTransition, setShowTransition] = useState(true);

  useEffect(()=>{
    localStorage.setItem('token',"none");
  },[])

  const stopShow = () => {
    setShowTransition(false);
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage toggle={toggle} />,
      id: "root",
      errorElement: <ErrorPage />,
      loader: tokenLoader,
      children: [
        { index: true, element: <Dashboard show={showTransition} stop={stopShow} />, loader: checkToken },
        { path: "prospecting", element: <ProspectingPage show={showTransition} />, loader: checkToken },
        { path: "edit-profile", element: <EditProfilePage show={showTransition} />, loader: checkToken },
        { path: "history", element: <HistoryPage show={showTransition} />, loader: checkToken },
        { path: "faq", element: <FAQPage show={showTransition} />, loader: checkToken },
        { path: "auth", element: <AuthPage toggle={toggle} />, action: authAction },
        { path: "tfa", element: <TwoFactorPage toggle={toggle} />, action: twoFactor },
        { path: "reset-password/*", element: <ResetPasswordPage toggle={toggle} />,  },
        { index: true, element: <Dashboard show={showTransition} />, loader: checkToken },
        { path: "prospecting", element: <ProspectingPage show={showTransition} />, loader: checkToken },
        { path: "prospecting/outbound/:ucrn", element: <Outbound t={"out"} save={false} />, loader: checkToken },
        { path: "prospecting/inbound/:ucrn", element: <Outbound t={"in"} save={false} />, loader: checkToken },
        { path: "prospecting/outbound/:ucrn/save", element: <Outbound t={"out"} save={true} />, loader: checkToken },
        { path: "book/*", element: <BookingPage show={showTransition} />, loader: checkToken },
        { path: "booking-gateway", element: <BookingGatewayPage/>, loader: checkToken },
        { path: "booking-confirm", element: <BookingAlreadyPage/>, loader: checkToken },
        { path: "edit-profile", element: <EditProfilePage show={showTransition} />, loader: checkToken },
        { path: "edit-profile", element: <EditProfilePage show={showTransition} />, loader: checkToken },
        { path: "edit-twofactor", element: <EditAuthPage show={showTransition} />, loader: checkToken },
        { path: "campaigns", element: <CampaignsPage show={showTransition} />, loader: checkToken },
        { path: "/campaigns/:name", element: <CampaignDetailsPage />, loader: checkToken },
        { path: "history", element: <HistoryPage show={showTransition} />, loader: checkToken },
        { path: "faq", element: <FAQPage show={showTransition} />, loader: checkToken },
        { path: "auth", element: <AuthPage toggle={toggle} />, action: authAction },
      ]
    }
  ]);

  // const dispatch = useDispatch();

  let dark = darkMode;
  localStorage.setItem('theme', dark)



  return (
    <ThemeContext.Provider value={{ dark }}>
      <div className={`w-full h-screen  -z-40`}>
        <RouterProvider router={router} />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;