import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function MediumDropdownItem({id, name, value, onClick, last}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [hover, setHover] = useState(false);

    function changeController(){
      onClick({name, value})
    }

    function hoverOn(){
      setHover(true)
    }
    function hoverOff(){
      setHover(false)
    }

    return (
    <li key={id} onMouseEnter={hoverOn} onMouseLeave={hoverOff} className={` h-full w-full flex justify-center text-sm ${last&&" rounded-b-lg "} hover:text-accent ${dark ? " text-neutral-100  hover:bg-neutral-600" : " text-neutral-300 hover:bg-neutral-75"}`}>
      <div className="w-full h-full flex justify-between">
      {hover ? <div className={`h-max bg-accent ${last&&"rounded-bl-lg"} w-1  text-transparent`}>a
        </div>:<div className="h-full w-1">
        </div>}
        <button className="text-center w-full" onClick={changeController} value={value}>
        {name}
      </button>
      <div></div>
      </div>
    </li>
    )
}

export default MediumDropdownItem;