import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";

function NotificationItem({ id, time, body, flagged, show, deleted }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const [hover, setHover] = useState(false);
    const [del, setDeleted] = useState(deleted);
    const [flag, setFlagged] = useState(flagged);

    const date = new Date(time.year, time.month, time.day, time.hour, time.min);

    function hoverOn() {
        setHover(true);
    }
    function hoverOff() {
        setHover(false);
    }

    if (show === del) {
        return (
            <li key={id} id={id} onMouseEnter={hoverOn} onMouseLeave={hoverOff} className={`w-full h-full ${dark ? "text-white  odd:bg-neutral-600 border-neutral-700" : "text-neutral-900 odd:bg-neutral-75 border-neutral-200 "} border-b-2 items-center`}>
                <div className="flex w-full relative h-full">
                    {(flag) && <div className={`absolute left-0 w-1 h-full bg-orange-400`} />}
                    <div className={`flex flex-col items-center py-2 pl-6`}>
                        <p className="text-red-1000 text-md font-bold">{date.getHours() + ":" + date.getMinutes()}</p>
                        <p className={` ${dark ? "text-neutral-300" : "text-neutral-300"} text-sm`}>{date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear()}</p>
                    </div>
                    <div className="flex flex-col justify-center p-2 w-10/12">
                        <p className={`${flag ? "font-bold" : " font-normal"} ${dark ? "text-white" : "text-black"} pl-4`}>{body}</p>
                    </div>
                    <div className="flex right-0 flex-row h-full w-2/12"></div>
                    {hover && <div className="flex right-0 flex-row absolute h-full w-2/12"  >
                        {!del && <button id={id} onClick={() => { setFlagged(!flag) }} className={`h-full w-1/2 flex flex-col justify-center items-center border-x-2 ${dark ? "border-neutral-700" : "border-neutral-200"}`}>
                            <div>
                                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_868_5636)">
                                        <path d="M2.14286 1.125C2.14286 0.502734 1.66406 0 1.07143 0C0.478795 0 0 0.502734 0 1.125V2.25V12.9375V16.875C0 17.4973 0.478795 18 1.07143 18C1.66406 18 2.14286 17.4973 2.14286 16.875V12.375L4.29576 11.809C5.67188 11.4469 7.12835 11.6156 8.39732 12.2801C9.87723 13.057 11.5949 13.152 13.1417 12.5402L14.3036 12.0832C14.7221 11.918 15 11.4996 15 11.0285V2.32383C15 1.51523 14.1897 0.987891 13.5 1.35L13.1786 1.51875C11.6283 2.33438 9.80357 2.33438 8.25335 1.51875C7.07812 0.9 5.72879 0.745313 4.45312 1.0793L2.14286 1.6875V1.125Z" fill="#FF7A00" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_868_5636">
                                            <rect width="15" height="18" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <p className="pt-1 text-sm">{flag ? "Unflag" : "Flag"}</p>
                        </button>}
                        <button id={id} onClick={() => { setDeleted(!del); if (del) { setFlagged(false) }; }} className={`h-full ${del ? "border-x-2 w-full" : " w-1/2 border-r-2 "} flex flex-col justify-center items-center ${dark ? "border-neutral-700" : "border-neutral-200"}`}>
                            <div>
                                <svg className="pt-1" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`${dark ? "fill-white" : "fill-black"}`} fillRule="evenodd" clipRule="evenodd" d="M10.8001 2.2998C10.5773 2.29992 10.3589 2.35947 10.1695 2.47179C9.97999 2.58411 9.82689 2.74476 9.7273 2.93575L8.8585 4.5998H4.8001C4.48184 4.5998 4.17661 4.72097 3.95157 4.93663C3.72653 5.1523 3.6001 5.44481 3.6001 5.7498C3.6001 6.0548 3.72653 6.34731 3.95157 6.56298C4.17661 6.77864 4.48184 6.8998 4.8001 6.8998V18.3998C4.8001 19.0098 5.05295 19.5948 5.50304 20.0261C5.95313 20.4575 6.56358 20.6998 7.2001 20.6998H16.8001C17.4366 20.6998 18.0471 20.4575 18.4972 20.0261C18.9472 19.5948 19.2001 19.0098 19.2001 18.3998V6.8998C19.5184 6.8998 19.8236 6.77864 20.0486 6.56298C20.2737 6.34731 20.4001 6.0548 20.4001 5.7498C20.4001 5.44481 20.2737 5.1523 20.0486 4.93663C19.8236 4.72097 19.5184 4.5998 19.2001 4.5998H15.1417L14.2729 2.93575C14.1733 2.74476 14.0202 2.58411 13.8307 2.47179C13.6413 2.35947 13.4229 2.29992 13.2001 2.2998H10.8001ZM8.4001 9.1998C8.4001 8.89481 8.52653 8.6023 8.75157 8.38663C8.97661 8.17097 9.28184 8.0498 9.6001 8.0498C9.91836 8.0498 10.2236 8.17097 10.4486 8.38663C10.6737 8.6023 10.8001 8.89481 10.8001 9.1998V16.0998C10.8001 16.4048 10.6737 16.6973 10.4486 16.913C10.2236 17.1286 9.91836 17.2498 9.6001 17.2498C9.28184 17.2498 8.97661 17.1286 8.75157 16.913C8.52653 16.6973 8.4001 16.4048 8.4001 16.0998V9.1998ZM14.4001 8.0498C14.0818 8.0498 13.7766 8.17097 13.5516 8.38663C13.3265 8.6023 13.2001 8.89481 13.2001 9.1998V16.0998C13.2001 16.4048 13.3265 16.6973 13.5516 16.913C13.7766 17.1286 14.0818 17.2498 14.4001 17.2498C14.7184 17.2498 15.0236 17.1286 15.2486 16.913C15.4737 16.6973 15.6001 16.4048 15.6001 16.0998V9.1998C15.6001 8.89481 15.4737 8.6023 15.2486 8.38663C15.0236 8.17097 14.7184 8.0498 14.4001 8.0498Z" />
                                </svg>
                            </div>
                            {!del && <p className="pt-1 text-sm">Delete</p>}
                            {del && <p className="pt-1 text-sm">Revert</p>}
                        </button>
                    </div>}
                </div>
            </li>
        )
    } else {
        return <div></div>
    }
}

export default NotificationItem;