import { useState } from "react";
import IndividualCampaign from "./IndividualCampaign";
import SolidButton from "../UI/SolidButton";
import { useDispatch } from "react-redux";
import { timeActions } from "../../store/time-slice";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function GroupedCampaigns({ campaigns, toggleAction, act, siteCampaignCommTemplates }) {
    const m = new Date().getMonth();
    const y = new Date().getFullYear();
    const [month, setMonth] = useState(m);
    const [year, setYear] = useState(y);
    const dispatch = useDispatch();

    function changeMonthForward() {
        if (month === 11) {
            setMonth(0);
            setYear(year + 1);
            setSearchTime(1, year + 1);
        }
        else {
            const mo = month + 1;
            setMonth(mo);
            setSearchTime(mo, year);
        }
    }

    function changeMonthBackward() {
        if (month === 0) {
            setMonth(11);
            setYear(year - 1);
            setSearchTime(12, year - 1);
        }
        else {
            const mo = month - 1;
            setMonth(mo);
            setSearchTime(mo, year);
        }
    }

    function setSearchTime(month, year) {
        const date = new Date(year, month, 3).toISOString();
        dispatch(timeActions.changeSearchTime(date));
    }

    const campaign = Object.values(campaigns);

    const t = monthNames[month === 12 ? 0 : month];

    const map = (campaign.map((i, index) => (<IndividualCampaign act={act} key={i.id + index} campaign={i} siteCampaignCommTemplates={siteCampaignCommTemplates} />)))

    map.sort((a, b) => b - a);

    return (
        <div>
            <div className="flex justify-between">
                <div className="flex justify-between pl-6 w-1/5">
                    <button className="-rotate-90 cursor-pointer mr-2" value={"backwards"} onClick={changeMonthBackward}>
                        <svg className={`stroke-accent hover:stroke-neutral-900`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.001 6L6.00098 1L1.00098 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <h2 className="text-neutral-300 font-bold py-2 text-md ">{t + ", " + year}</h2>
                    <button className="rotate-90 cursor-pointer ml-2" value={"forward"} onClick={changeMonthForward}>
                        <svg className={`stroke-accent hover:stroke-neutral-900`} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.001 6L6.00098 1L1.00098 6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className="mr-2 mb-2">
                    <SolidButton text={"Swap Campaign Type"} onClick={toggleAction} />
                </div>
            </div>
            <ul>
                {map}
            </ul>
        </div>
    )
}

export default GroupedCampaigns;