import TickButton from "./UI/TickButton";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";

function ActivityElement({ element, onClick, on }) {
    const [clicked, setClicked] = useState(false)
    const theme = useContext(ThemeContext);
    const { dark } = theme;

    function clickHandler() {
        onClick(element);
        setClicked(true);
    }

    if (on) {
        setClicked(true)
    }

    return (
        <li className={`inline-flex justify-start items-center mx-auto p-2 py-4 px-4 cursor-pointer border-t duration-700 ${dark ? "border-neutral-300" : "border-neutral-300"} ${clicked ? "opacity-0" : "opacity-100"}`} onClick={clickHandler}>
            <p className={`select-none w-11/12  ${dark ? "text-white" : "text-neutral-900"} text-[13px]`}>{element.body}</p>
            <div className="absolute right-1 w-1/12">
                <TickButton on={clicked} />
            </div>
        </li>
    )
}

export default ActivityElement;