function ColouredSolidButton({text, onClick, type, disabled, colour}) {

    return (
      <button
        type={type !== null? type :"button"}
        onClick={onClick}
        disabled={disabled}
      >
        <div className={`${!disabled && 'hover:-translate-y-1'} ${colour} w-full h-full disabled:bg-neutral-500 text-white  focus:ring-4 focus:outline-none focus:ring-neutral-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mb-2`}>
        {text}</div>
      </button>
    );
  }
  
  export default ColouredSolidButton;
  