import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function Header({children, acc = true}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return <h2 className={`font-bold  text-lg  ${acc?"text-accent":dark?"text-neutral-300":"text-neutral-300"}`}>{children}</h2>
}

export default Header;