import { configureStore } from "@reduxjs/toolkit";
import prospectingSlice from "./prospecting-slice";
import notificationSlice from "./notifications-slice";
import historySlice from "./history-slice";
import currentUserSlice from "./currentUser-slice";
import campaignSlice from "./campaign-slice";
import organisationSlice from "./organisation-slice";
import customerSlice from "./customer-slice";
import vehicleSlice from "./vehicle-slice";
import reportingSlice from "./reporting-slice";
import timeSlice from "./time-slice";
import bookingSlice from "./booking-slice";

const store = configureStore({
    reducer: { prospecting: prospectingSlice.reducer, time: timeSlice.reducer, notification: notificationSlice.reducer, hist: historySlice.reducer, currentUser: currentUserSlice.reducer, campaign: campaignSlice.reducer, organisation: organisationSlice.reducer, customers: customerSlice.reducer, vehicles: vehicleSlice.reducer, reporting: reportingSlice.reducer, booking: bookingSlice.reducer }
})

export default store;