import Card from "../UI/Card";
import BarChart from "./BarChart";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Spinner from "../UI/Spinner";
import LoadingDots from "../UI/LoadingDots";
import SkeletonBarChart from "../UI/SkeletonBarChart";

function BarChartBacker({ xAxis, yAxis, data, barColour, title, callsMade, callsToMake, loaded }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    useWindowDimensions();

    return (
        <Card>
            <div id="bar_chart" className=" mb-auto h-full max-h-screen">
                <div className={`container flex flex-wrap  ${dark ? "text-neutral-200" : "text-neutral-900"} items-center px-6 pt-6 pb-4 mb-2`}>
                    {loaded?
                    callsMade !== null ? <h1 className={`absolute font-bold text-2xl`}>{title}</h1> : <h1 className={`absolute font-bold text-2xl`}>Loading...</h1>:
                    <LoadingDots/>
                    }
                </div>
                {yAxis.data.length !== 0 ?
                    <div className=" w-full h-full pl-4 pb-8"><BarChart xAxis={xAxis} yAxis={yAxis} data={data} barColour={barColour} callsMade={callsMade} callsToMake={callsToMake} /></div>
                    :<SkeletonBarChart/>
                }
            </div>
        </Card>
    )
}

export default BarChartBacker;