import ColouredBadge from "../UI/ColouredBadge";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useDispatch, useSelector } from "react-redux";
import { customerActions } from "../../store/customer-slice";
import TableCell from "./TableCell";
import SkeletonInput from "../UI/SkeletonInput";
import { GetVehicleData } from "../../store/vehicle-actions";
import { Link } from "react-router-dom";
import LoadingDots from "../UI/LoadingDots";

function TableRow({ id, custId, hot, data, max }) {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
    const sites = useSelector((state) => state.organisation.sites);
    const customerData = useSelector((state) => state.customers.address);
    const callHistory = useSelector((state) => state.customers.callHistory);
    const campaigns = useSelector((state) => state.campaign.campaigns);
    const [loaded, setLoaded] = useState(false);
  const {dark} = theme;

  const s = sites.filter(i => i.id === data.orgSiteId);
  const c = campaigns.filter(i => i.id === data.campaignId);

  const hist = callHistory.filter(h => h.customerId === data.id);
  const hi = hist.map(i => (i.createdAt));
  const h = hi.length !== 0 ?hi.reduce(function (a, b) { return a > b ? a : b; }):0;
  const timeSinceCall = h !== 0 ?  Math.abs(new Date().getTime() - new Date(h).getTime()) / 1000 / 3600: "Not Called"

  let hotColour = "";
  if (hot >= Math.ceil(max / 4) + Math.ceil(max / 2)) {
    hotColour = "bg-[#F12B2C]"
  } else if (hot <= Math.ceil(max / 4)) {
    hotColour = "bg-[#34AAFF]"
  } else {
    hotColour = "bg-[#FEC400]"
  }

  useEffect(() => {
    let myPromise = new Promise(async function a(resolve, reject) {
      dispatch(GetVehicleData(custId));
      return resolve();
    });
    myPromise.then(async function b() {
      setLoaded(true);
    })
  }, [custId, dispatch])

  const changeCustomer = (item) => new Promise((resolve, reject) => {
    // do anything here
    dispatch(customerActions.getFilteredCustomers(item));
    resolve();
  })

  function inOutClick() {
    changeCustomer(custId)
  }


  return (
    <tr id={id} className={` ${dark ? "odd:bg-neutral-600 bg-neutral-900 text-white" : "odd:bg-neutral-75 bg-white text-neutral-900"}  dark:bg-neutral-800 dark:border-neutral-700`}>
      <td className="px-4 py-6 uppercase">{data.ucrn}</td>
      <TableCell cont={data.title !== null?data.title:null}/>
      <TableCell cont={data.firstName !== null?data.firstName:null}/>
      <TableCell cont={data.lastName !== null?data.lastName:null}/>
      <TableCell cont={s[0] !== undefined?s[0].name:null}/>
      <TableCell cont={c[0] !== undefined?c[0].name:null}/>
      <td className="px-4 py-4"><p>{timeSinceCall!=="Not Called"? Math.trunc(timeSinceCall) +" hours ago":"Not Called"}</p></td>
      <TableCell cont={data.lastSalesExec !== null?data.lastSalesExec:""}/>
      <td className=" px-4 py-4 ">
        <ColouredBadge colour={hotColour} text={hot + "º"} />
      </td>
      <td className="px-4 py-2">
        {loaded && customerData.filter(i => i.customer === custId).length !== 0 ?
          <div className="w-full h-full flex flex-col gap-2 items-center">
            <Link className="w-full" to={`/prospecting/outbound/` + custId} state={custId}><button
              id="out"
              type="button"
              className={`${dark ? "text-neutral-300 border-neutral-300 hover:text-secondary" : "text-neutral-300 hover:text-neutral-900"} w-full border ${dark ? "border-neutral-300" : "border-neutral-300"} hover:bg-accent hover:border-transparent focus:ring-4 focus:outline-none focus:ring-neutral-300 rounded-lg text-sm px-2 text-center mr-2 `}
              onClick={inOutClick}
            >
              Outbound
            </button></Link>
            <Link className="w-full" to={`/prospecting/inbound/` + custId} state={custId}>
              <button
                id="in"
                type="button"
                className={`${dark ? "text-neutral-300 border-neutral-300 hover:text-secondary" : "text-neutral-300 hover:text-neutral-900"} w-full border ${dark ? "border-neutral-300" : "border-neutral-300"} hover:bg-accent hover:border-transparent focus:ring-4 focus:outline-none focus:ring-neutral-300 rounded-lg text-sm px-2 text-center mr-2`}
                onClick={inOutClick}
              >
                Inbound
              </button></Link>
          </div> :
          <LoadingDots/>}
      </td>
    </tr>
  );
}

export default TableRow;
