import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

function ChatBoxSender({ text }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  return (
    <div className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
      <div>
        <div className={`${dark ? "bg-neutral-900" : "bg-neutral-100"} border-accent border-2 text-white px-3 py-1 rounded-l-lg rounded-br-lg`}>
          <p className="text-sm">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default ChatBoxSender;
