import { useEffect, useState } from "react";

function CountUp({maxNumber, duration, time, acc}){
const [num, setNum] = useState(0)

useEffect(() => {
    let start = 0;
    const end = maxNumber;
    if(start === end) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 100;

    let timer = setInterval(() => {
        start += 1;
        setNum(start);
        if(start === end) clearInterval(timer)
    }, incrementTime);
}, [maxNumber, duration])

return <h1 className={` font-extrabold text-2xl text-center ${acc?"text-accent":"text-red-600"}`}>{num +(time !== null && " " + time)}</h1>
}

export default CountUp;