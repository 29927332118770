import { organisationActions } from "./organisation-slice";

export function GetOrganisationData(org) {
    return async (dispatch) => {
        const fetchSites = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisation/GetSites",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch organisation data.');
            };

            const data = await response.json();

            return data;
        }

        const fetchLogo = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var requestOptions = {
                method: 'POST',
                headers: headers,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Organisation/GetLogo?id=" + org,
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch logo.');
            }

            if (response.status === 204) {
                return "";
            }

            const data = await response.json();

            return data;
        }


        const sites = await fetchSites();
        const orgData = await fetchLogo();
        dispatch(organisationActions.replaceOrganisation({ logo: orgData.key || "", colour: "#A01D22", sites: sites }))

    }
};

//export function SendCartData(cartData){
//    return async(dispatch) => {
//        dispatch(uiActions.showNotification({status: 'pending', title: 'Sending...', message: 'Sending cart data.'}));
//
//        async function sendRequest(){
//
//        const response = await fetch(
//            "https://react-test-a4209-default-rtdb.europe-west1.firebasedatabase.app/cart.json",
//            {
//              method: "PUT",
//              body: JSON.stringify({items: cartData.items, totalQuantity: cartData.totalQuantity}),
//            }
//          );
//          if (!response.ok) {
//            throw new Error("Sending Data Failed");
//          }
//        };
//
//        try{
//            await sendRequest();
//            dispatch(uiActions.showNotification({status: 'success', title: 'Success!', message: 'Sent cart data.'}));
//       }catch(error){
//            dispatch(uiActions.showNotification({status: 'error', title: 'Error', message: 'Sending cart data failed.'}));
//        }
//
//    };
//}