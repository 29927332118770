import LoginForm from "../components/LoginForm";
import CardFit from "../components/UI/CardFit";
import { redirect } from "react-router-dom";
import RoundedButton from "../components/UI/RoundedButton";
import { useContext, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { useDispatch } from "react-redux";
import { currentUserActions } from "../store/currentUser-slice";
import { Form } from "react-router-dom";
import SolidButton from "../components/UI/SolidButton";
import TextInput from "../components/UI/TextInput";
import Header from "../components/UI/Header";
import { forgottenPassword } from "../store/currentUser-actions";

function AuthPage({ toggle }) {
  const { dark } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [passModal, setPassModal] = useState(false);

  function saveDetails(email,password,event){
    if(!passModal){
    dispatch(currentUserActions.createTempLogin({email:email,password:password}));
    }
  }

  function SendResetEmail(event){
    if(passModal){
      event.preventDefault();
    dispatch(forgottenPassword({email:event.target.email.value,uri:process.env.REACT_APP_RESET_URL}));
    }
  }
  
  function forgotPassword(){
    setPassModal(!passModal);
  }

  return (
    <div className="h-full w-full z-10 my-auto">
      <div className="w-full h-full  flex justify-center items-center">
        <div className="absolute w-80 mb-96">
          <div className={`bg-accent flex justify-center items-center m-auto h-auto aspect-square w-1/3 rounded-full`}>
            <svg viewBox="0 0 183 183" className={` fill-secondary`}>
              <path d="m 92 90 c 13.8875 0 25.1429 -11.1914 25.1429 -25 c 0 -13.8086 -11.2554 -25 -25.1429 -25 c -13.8875 0 -25.1429 11.1914 -25.1429 25 c 0 13.8086 11.2554 25 25.1429 25 z m -8.9768 9.375 c -19.3482 0 -35.0232 15.5859 -35.0232 34.8242 c 0 3.2031 2.6125 5.8008 5.8339 5.8008 h 76.3322 c 3.2214 0 5.8339 -2.5977 5.8339 -5.8008 c 0 -19.2383 -15.675 -34.8242 -35.0232 -34.8242 h -17.9536 z" />
            </svg>
          </div>
        </div>
        <CardFit>
          {!passModal?
          <LoginForm title={"Log in"} save={saveDetails} forgot={forgotPassword}/>
          :
          <div className="w-full mt-10">
          <Form method="post" onSubmit={SendResetEmail}>
              <Header>Enter Your Email Address</Header>
              <div className="mt-2 flex w-full space-y-2">
                  <div className="flex flex-col w-full gap-2">
                      <TextInput type="email" name="email" />
                  </div>
              </div>
              <div className="pt-6 flex justify-between">
                  <SolidButton type="submit" text={'Send'} />
                  <SolidButton type="button" text={'Back'} onClick={forgotPassword}/>
              </div>
          </Form>
          </div>
          }
        </CardFit>
        <div className="absolute bottom-0 left-0 m-10">
          <RoundedButton text={dark ? "Light Mode" : "Dark Mode"} onClick={toggle} />
        </div>
      </div>
    </div>
  );
}

export default AuthPage;

//setting auth token on login
export async function A({ request }) {
  const data = await request.formData();
  const authData = {
    "email": data.get("email"),
    "password": data.get("password"),
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(authData),
  };
  const res = await fetch(process.env.REACT_APP_API_URL+"/Authentication/Login", requestOptions);

  if (!res.ok) {
    throw new Error('Login Failed');
  }
  
  const resData = await res.json();

  if(resData.token === undefined){
    console.log("tfa")
    return redirect("/tfa");
  }else{
    localStorage.setItem("token", resData.token);
    localStorage.setItem("expiration", resData.expiration);
  
    return redirect("/")
  }

}