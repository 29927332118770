import EditProfile from "../components/EditProfile";
import TransitionWrapper from "../components/TransitionWrapper";

function EditProfilePage({show}){
    return (
        <TransitionWrapper show={show}>
            <EditProfile logout={logOut}/>
        </TransitionWrapper>
    )
}

export default EditProfilePage;

function logOut(){
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
}