import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function Header2({children}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return <h2 className={` font-semibold text-md ${dark ? "text-neutral-300" : "text-neutral-300"}`}>{children}</h2>
}

export default Header2;