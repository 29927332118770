function LargeCircleImage({image}){
    
    return (   
        <div  className={`flex justify-center m-auto h-auto `}>
            <div className="absolute h-full w-80 bottom-0">
        <div  className={`bg-accent flex justify-center m-auto h-auto aspect-square w-3/4 rounded-full`}>
    <svg  viewBox="0 0 183 183" className={` fill-secondary`}>
    <path  d={image}/>
    </svg>
    </div>
    </div>
    </div>
)
}

export default LargeCircleImage;