import { customerActions } from "./customer-slice";

//"https://prospecting-api.azurewebsites.net/Prospects/GetMany"

export function GetCustomerData(customers) {
  return async (dispatch) => {
    const fetchAddress = async (ids) => {

      var values = Array.isArray(ids)? ids:[ids];
      var raw = JSON.stringify(values);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetAddress",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch address.');
      }

      const data = await response.json();

      return data;
    }
    const fetchCallHistory = async (ids) => {

      var values = Array.isArray(ids)? ids:[ids];
      var raw = JSON.stringify(values);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetCallHistory",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch call history.');
      }

      const data = await response.json();

      return data;
    }
    const fetchContacts = async (ids) => {

      var values = Array.isArray(ids)? ids:[ids];
      var raw = JSON.stringify(values);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetContacts",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch contacts.');
      }

      const data = await response.json();

      return data;
    }
    const fetchDetails = async (ids) => {

      var values = Array.isArray(ids)? ids:[ids];
      var raw = JSON.stringify(values);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetDetails",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }

      const data = await response.json();

      return data;
    }
    const fetchVehicles = async (ids) => {

      var values = Array.isArray(ids)? ids:[ids];
      var raw = JSON.stringify(values);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetVehicle",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch vehicles.');
      }

      const data = await response.json();

      return data;
    }
    const fetchOutcomes = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetAllOutcomes",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch vehicles.');
      }

      const data = await response.json();

      return data;
    }


    const addresses = await fetchAddress(customers);
    const callHistory = await fetchCallHistory(customers);
    const contacts = await fetchContacts(customers);
    const details = await fetchDetails(customers);
    const vehicles = await fetchVehicles(customers);
    const outcomes = await fetchOutcomes();
    dispatch(customerActions.replaceCustomers({ address: addresses || [], callHistory: callHistory || [], contact: contacts || [], details: details || [], vehicle: vehicles || [], outcomes: outcomes || [], loaded: true }))

  }
};

export function GetCallOutcomes(customers) {
  return async (dispatch) => {
    const fetchOutcomes = async () => {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetAllOutcomes",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch vehicles.');
      }

      const data = await response.json();

      return data;
    }
    const fetchCallHistory = async (ids) => {

      var raw = JSON.stringify(ids);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetCallHistory",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch call history.');
      }

      const data = await response.json();

      return data;
    }

    const outcomes = await fetchOutcomes();
    const callHistory = await fetchCallHistory(customers);
    dispatch(customerActions.replaceCustomers({ callHistory: callHistory || [], outcomes: outcomes || [], address: [], details: [], contact: [], vehicle: [] }))

  }
}

export function CreateNewCustomer(data) {
  return async (dispatch) => {
    async function createDetails(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateDetails",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };
    async function createAddress(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateAddress",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };
    async function createContact(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateContacts",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };


    data.details.customer = data.customer;
    data.address.customer = data.customer;
    data.contact.customer = data.customer;
    await createDetails(data.details);
    await createAddress(data.address);
    await createContact(data.contact);
    dispatch(customerActions.createCustomers({ details: data.details, contact: data.contact, address: data.address }))


  };
}

export function CreateNewCallHistory(data) {
  return async (dispatch) => {
    async function createCallHistory() {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(data);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateCallHistory",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };


    await createCallHistory();

    dispatch(customerActions.addCallHistory(data));


  };
}

export function UpdateCustomerData(data) {
  return async (dispatch) => {
    async function sendDetails(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      i.modifiedAt = new Date();

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'PUT',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/UpdateDetails",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };
    async function sendAddress(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      i.modifiedAt = new Date();

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'PUT',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/UpdateAddress",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };
    async function sendContact(i) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      i.modifiedAt = new Date();

      var raw = JSON.stringify(i);

      var requestOptions = {
        method: 'PUT',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/UpdateContacts",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };


    await sendDetails(data.details);
    await sendAddress(data.address);
    await sendContact(data.contact);
    dispatch(customerActions.editCustomers({ details: data.details, contact: data.contact, address: data.address }))


  };
}