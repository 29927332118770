import TransitionWrapper from "../components/TransitionWrapper";
import MediumDropdown from "../components/UI/MediumDropdown";
import LargeTextBox from "../components/UI/LargeTextBox";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/UI/Header";
import ColouredSolidButton from "../components/UI/ColouredSolidButton";
import SmallAppointmentCalendar from "../components/UI/SmallAppointmentCalendar";
import CapsuleButton from "../components/UI/CapsuleButton";
import { GetTimeSlots } from "../store/booking-actions";
import { bookingActions } from "../store/booking-slice";
import getUniqueId from "../hooks/getUniqueId";

function BookingPage({ show }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.currentUser.item);
    const services = useSelector((state) => state.booking.services);
    const slots = useSelector((state) => state.booking.timeSlots);
    const taken = useSelector((state) => state.booking.removedTimes);
    const site = useSelector((state) => state.currentUser.currentSite);
    const custDetails = useSelector((state) => state.customers.details);
    const [date, setDate] = useState(new Date());
    const [timeSelect, setTimeSelect] = useState(false);
    const [time, setTime] = useState();
    const [timeOptions, setTimes] = useState([])
    const [options, setOptions] = useState([{name:"choose service",id:0,value:{description:"",name:""}}])
    const [service, setService] = useState(options[0].value);    
    const [notes, setNotes] = useState(null)
    const [customer,setCustomer] = useState();
    const [details, setDetails] = useState({title:"",firstName:"",lastName:""});

    useEffect(()=>{
        dispatch(GetTimeSlots({siteId:site,date:new Date()}));
        const strings = location.pathname.split("/");
        const l = strings.length-1;
        const id = strings[l];
        const d = custDetails.find(c => c.customer === id);
        setDetails(d);
        setCustomer(id)
      },[])
      
    useEffect(()=>{
        if(services !== undefined && services.length !== 0){
          const m = services.map(s => {return{name:s.name,id:s.id,value:{id:s.id,name:s.name,description:s.description}}});
          setOptions(m);
          setService(m[0].value)
        }
      },[services])

    useEffect(()=>{
        if(date !== undefined && date !== null){
          const list = removeOverlappingTimes(taken,slots);
          const reduced = list.filter(s => new Date(s.slotStart).getDate() === date.getDate()&&s.serviceId === service.id);
          const m = reduced.map(s => {return{name:(new Date(s.slotStart).getHours() <10 ?"0"+new Date(s.slotStart).getHours():new Date(s.slotStart).getHours())+":"+(new Date(s.slotStart).getMinutes() <10 ?"0"+new Date(s.slotStart).getMinutes():new Date(s.slotStart).getMinutes()),id:new Date(s.slotStart),value:s.slotStart,disabled:false}});
          setTimes(m);
        }
      },[date, taken])

      function removeOverlappingTimes(removals, slots){
        const newSlots = slots;
        if(removals !== undefined && removals.length !== 0){
        removals.forEach(element => {
          const start = new Date(element.start);
          const diff = new Date(element.end) - start;
          var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
  
          for (let index = 0; index < diffMins; index++) {
            const comp = new Date(start.getTime() + index*60000);
            
            newSlots.filter(s => new Date(s.slotStart) !== comp || new Date(s.slotEnd) !== comp);
          }
        });
      }
        return newSlots;
      }
      
      function changeSlots(date){
        dispatch(GetTimeSlots({siteId:site,date:date}));
      }

    function setServiceInApp(value) {
        setService(value)
    }
    
    function setDay(val){
        if(val!== "none"){
          setTimeSelect(true);
          setDate(new Date(val));
          console.log(val)
        }
      }
      
    function chooseTime(e){
        setTime(e);
      }

      function backCalendar(){
        setTimeSelect(false);
    }

    function setComments(e) {
        setNotes(e.target.value);
    }

    function enter(){
      if(service !== "" && date !== undefined && time !== undefined){
        const at = time.toString();
        console.log({withUser:"none",service:service.id,at:at,customer:customer, createdBy:user.email, createdAt:new Date().toISOString(), notes:notes})
        dispatch(bookingActions.setCurrentAppointment({id:getUniqueId(),withUser:"none",service:service.id,at:at,customer:customer, createdBy:user.email, createdAt:new Date().toISOString(), notes:notes}));
        navigate("/booking-gateway");
      }
    }

    const times = timeOptions.map(t => <CapsuleButton key={t.id} name={t.name} onClick={chooseTime} value={t.value} active={time} disabled={t.disabled}/>)

    return (
        <TransitionWrapper show={show}>
            <div className="flex justify-center mt-20">
                <div className={`relative mx-auto p-8 ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg w-2/3 mb-10 shadow-md`}>
                    <div className="pb-4">
                    <Header>Create appointment:</Header>
                    </div>
                    <div className="flex justify-between">
                      <div>
                    <div className=" mb-2">
                        <div className="pb-4">
                            <p className={` ${dark ? "text-white" : "text-neutral-900"} pb-2`}>Select Service:</p>
                            <MediumDropdown options={options} onChange={setServiceInApp} />
                        </div>
                    </div>
                    <div className="w-fit mb-2">
                        <p className={` ${dark ? "text-white" : "text-neutral-900"} pb-2`}>Select Time Slot:</p>
                {timeSelect === false ?<SmallAppointmentCalendar onChange={setDay} times={slots} day={"none"} changeSlots={changeSlots}/>:
                <div className="relative h-full">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 border pt-6 px-6 grid-rows-5 rounded-lg border-neutral-300">
                  {times}
                </div>
                <button onClick={backCalendar} className={`absolute bottom-0 text-sm p-2 py-1 mx-2 mb-2 border hover:border-accent rounded-lg ${dark?"text-white border-neutral-300":"text-neutral-500 border-neutral-300"}`}>Change date</button>
                </div>}
                </div>
                </div>
                <div className="border border-neutral-300 rounded-lg w-fit p-2 ">
                  <p className={` pb-2 ${dark ? "text-white" : "text-neutral-900"}`}>Appointment details</p>
                    <p className={` pb-2 text-accent font-semibold`}>Customer:</p>
                  <p className={` pb-2 ${dark ? "text-neutral-200" : "text-neutral-800"}`}>{details.title+" "+details.firstName+" "+details.lastName}</p>
                    <p className={` pb-2 text-accent font-semibold`}>Service:</p>
                  <p className={` pb-2 ${dark ? "text-neutral-200" : "text-neutral-800"}`}>{service.name}</p>
                  <p className={` pb-2 ${dark ? "text-neutral-200" : "text-neutral-800"}`}>{service.description}</p>
                    <p className={` pb-2 text-accent font-semibold`}>Time slot:</p>
                  <p className={` pb-2 ${dark ? "text-neutral-200" : "text-neutral-800"}`}>{time!== undefined?new Date(time).toLocaleString():""}</p>
                    <p className={` pb-2 text-accent font-semibold`}>Notes:</p>
                  <p className={` pb-2 ${dark ? "text-neutral-200" : "text-neutral-800"}`}>{notes}</p>
                </div>
                </div>
                    <p className={` pb-2 ${dark ? "text-white" : "text-neutral-900"}`}>Add Comments:</p>
                    <LargeTextBox onChange={setComments} />
                    <div className="w-fit pt-4">
                        <ColouredSolidButton onClick={enter} text="Create" colour={"bg-green-1000"}/>
                    </div>
                    </div>
                </div>
        </TransitionWrapper>
    );
}

export default BookingPage;