import { useContext, useState} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import TickBoxDropdownItem from "./TickBoxDropdownItem";

function TickBoxDropdown({options, onChange, title, data = null, acc=false}){
  const theme = useContext(ThemeContext);
  const {dark} = theme;
  const [open, setOpen] = useState(false);
  const [ticked, setTicked] = useState([]);

    const menuItems = options.map((option, index) => <TickBoxDropdownItem key={option.name} ticked={ticked} name={option.name} id={option.id} value={option.value} acc={acc} onClick={changeController} last={index===options.length-1?true:false}/>)

    function changeController(e){
      onChange(e.value)
      setTicked(e.name)
    }

    function toggleOptions(){
      setOpen(!open);
    }

    return (
      <div className={`relative w-full h-full`}>
      <div className=" grid grid-cols-1 w-full h-full z-20 ">
    <button
    className={`  ${
      dark ? "text-neutral-100 bg-neutral-600 " : "text-neutral-300 "
    }  w-full h-full text-medium space-x-2 items-center ${open? "rounded-t-lg border-t border-x":"rounded-lg border"}  ${acc? "border-accent" :dark? "border-neutral-300":"border-neutral-600"}  hover:text-accent `}
  id="select"
  onClick={toggleOptions}>
    <div  className="flex justify-between items-center">
    <p className=" pl-2 w-4/5 py-1">{title}</p>
    <div className="w-1/5 flex justify-center items-center">
    <svg data-accordion-icon className={`w-6 h-6 ${open ? "rotate-180" : ""} shrink-0 fill-accent`} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
    </div>
    </div>
  </button>
  <div>
    {open && <div key={title} className={`absolute w-full max-h-32 lg:max-h-96 space-y-2 border-b object-contain overflow-y-auto overflow-x-hidden overscroll-contain z-50 border-x rounded-b-lg ${acc?"border-accent":dark?"border-neutral-300 bg-neutral-900":"bg-white border-neutral-600"} `}><ul >{menuItems}</ul></div>}
    </div>
    </div>
  </div>)
}

export default TickBoxDropdown;