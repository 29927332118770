import * as sig from "@microsoft/signalr";
import { notificationActions } from "./store/notifications-slice";

function Conn (dispatch){
    if (localStorage.getItem("token") != null) {

        const hubConnection = new sig.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL.concat("/signal"), { accessTokenFactory: () => localStorage.getItem("token") })
            .withAutomaticReconnect()
            .configureLogging(sig.LogLevel.Information)
            .build();

        hubConnection.start();

        hubConnection.on('CallStart', message => {
            dispatch(notificationActions.addToNotification(message));
        });

        hubConnection.on('CallEnd', message => {
            dispatch(notificationActions.addToNotification(message));
        });

        hubConnection.on('CreateProspect', message => {
            dispatch(notificationActions.addToNotification(message));
        });
    
        hubConnection.on('Updaterospect', message => {
            dispatch(notificationActions.addToNotification(message));
        });

        hubConnection.on('RemoveProspect', message => {
            dispatch(notificationActions.addToNotification(message));
        });
    }
    
}

export default Conn;