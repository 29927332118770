function AccentFullButton({ text, onClick, type, disabled }) {

  return (
    <button
      type={type !== null ? type : "button"}
      className={`bg-accent disabled:bg-neutral-500 text-secondary ${!disabled && 'hover:-translate-y-1'} focus:ring-4 w-full focus:outline-none focus:ring-neutral-300 font-medium rounded-lg text-sm px-2 py-1.5 text-center `}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

export default AccentFullButton;
