import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GetAppointment, CreateAppointment } from "../store/booking-actions";
import { useEffect } from "react";
import Spinner from "../components/UI/Spinner";

function BookingGatewayPage(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const curApp = useSelector(state => state.booking.currentAppointment);
    
    let myPromise = new Promise(function(myResolve, myReject) {
      const checkApp = {at:curApp.at,customer:curApp.customer,id:"tempId",service:curApp.service,createdAt:new Date(),createdBy:"tempUser",withUser:"none"};
      console.log(checkApp);
       var prev = dispatch(GetAppointment(curApp.customer));

      myResolve(prev); // when successful
      myReject();  // when error
    });

    useEffect(()=>{
      myPromise.then(
        function(prev){
          if(prev !== null){
            navigate("/booking-confirm")
          }else{
            dispatch(CreateAppointment(curApp));
            navigate("/prospecting")
          }
        },
        function(){
          navigate("/conf")
        }
      )
    },[])

    return(
      <div className="flex w-full h-full justify-center items-center">
        <Spinner/>
      </div>
    )
}

export default BookingGatewayPage;