import { createSlice } from "@reduxjs/toolkit";

const initialReportingState = {
    counts: [],
    opensClicks: [],
    unsubscribeSpamBounce: [],
}

const reportingSlice = createSlice({
    name: 'reporting',
    initialState: initialReportingState,
    reducers: {
        addToReporting(state, action) {
            const countIds = state.counts.map(a => a.id);
            const newCount = action.payload.counts;
                if(!countIds.includes(newCount.id)){
                    state.counts.push(newCount);
                }
                else if(state.counts.find(n => n.id=== newCount.id).date !== newCount.date)
                {
                    state.counts = state.counts.filter(c => c.id !== newCount.id);
                    state.counts.push(newCount);
                }
        },
        addClicksAndOpens(state, action) {
            const countIds = state.opensClicks.map(a => a.id);
            const newCount = action.payload.counts;
                if(!countIds.includes(newCount.id)){
                    state.opensClicks.push(newCount)
                }
                else if(state.opensClicks.find(n => n.id=== newCount.id).date !== newCount.date)
                {
                    state.opensClicks = state.opensClicks.filter(c => c.id !== newCount.id);
                    state.opensClicks.push(newCount);
                }
        },
        addUnsubscribeSpamBounce(state, action) {
            const countIds = state.unsubscribeSpamBounce.map(a => a.id);
            // const countDates = state.unsubscribeSpamBounce.map(a => a.date);
            const newCount = action.payload.counts;
                if(!countIds.includes(newCount.id)){
                    state.unsubscribeSpamBounce.push(newCount);
                }
                else if(state.unsubscribeSpamBounce.find(n => n.id=== newCount.id).date !== newCount.date)
                {
                    state.unsubscribeSpamBounce = state.unsubscribeSpamBounce.filter(c => c.id !== newCount.id);
                    state.unsubscribeSpamBounce.push(newCount);
                }
        },
    }
});

export const reportingActions = reportingSlice.actions;
export default reportingSlice;