import GroupedCampaigns from "./GroupedCampaigns";
import { groupBy } from "../../hooks/groupBy";
import Sort from "../UI/Sort";

function CampaignHolder({ campaigns, years, toggleAction, act, siteCampaignCommTemplates }) {

    const groupCamps = groupBy(campaigns, "month");

    const campaign = Object.values(groupCamps);
    const keys = Object.keys(groupCamps);

    campaign.sort((a, b) => (a - b));
    const map = campaign.map((i, index) => <GroupedCampaigns key={index} campaigns={i} title={keys[index]} toggleAction={toggleAction} act={act} siteCampaignCommTemplates={siteCampaignCommTemplates} />)

    return (
        <ul>
            <Sort by={keys}>
                {map}
            </Sort>
        </ul>
    )
}

export default CampaignHolder;