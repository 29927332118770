import { Fragment, useContext } from "react";
import Header from "../UI/Header";
import {ThemeContext} from "../../context/ThemeContext"
import AddToTable from "./AddToTable";
import EditTableItem from "./EditTableItem";
import CrossButton from "../UI/CrossButton";

function ProspModal({modalToggle, sites, type, customer}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return (
        <Fragment>
            <div className="fixed justify-center z-50 w-screen h-screen opacity-50  bg-black blur-lg"></div>
        <div className="fixed flex z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-full justify-center items-center">
            <div className={`relative justify-center w-fit h-min max-w-2xl m-auto md:h-auto `}>
            <div className={`relative rounded-lg shadow ${dark? "bg-neutral-900":"bg-white"} border-2 border-accent`}>
            <div className={`flex items-start justify-between px-4 pt-4 rounded-t pl-6`}>
            {type === "create" && <Header>New customer details</Header>}
            {type === "edit" && <Header>Edit Customer</Header>}
                <CrossButton onClick={modalToggle}/>
            </div>
            {type === "create" && <AddToTable modalToggle={modalToggle} sites={sites}/>}
            {type === "edit" && <EditTableItem modalToggle={modalToggle} sites={sites} c={customer}/>}
            </div>
            </div>
        </div>
        </Fragment>
    )
}

export default ProspModal;