import SquareRadio from "../UI/SquareRadio";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import CrossButton from "../UI/CrossButton";

function CampaignFilter({ open, filter }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;

    function changeFilter(id) {
        filter(id);
    }

    return (
        <div className={`z-10 absolute right-0 border p-2 rounded-md ${dark ? "bg-neutral-700" : "bg-[#F7F8F9]"} border-accent`}>
            <div className=" pb-2 flex justify-between">
                <p className={`pt-2 text-neutral-300 font-semibold`}>View</p>
                <CrossButton onClick={open} />
            </div>
            <div className={`p-1 border rounded-md ${dark ? "border-neutral-300 bg-neutral-600" : "border-neutral-300 bg-white"}`}>
                <SquareRadio id="email" name="Emails" boxCol={"border-neutral-300"} crossCol={"text-neutral-300"} onClick={changeFilter} />
                <SquareRadio id="sms" name="SMS" boxCol={"border-neutral-300"} crossCol={"text-neutral-300"} onClick={changeFilter} />
                <SquareRadio id="post" name="Post" boxCol={"border-neutral-300"} crossCol={"text-neutral-300"} onClick={changeFilter} />
            </div>
        </div>
    )
}

export default CampaignFilter;