import { Fragment, useContext, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import IndCallRow from "./IndCallRow";
import CommentModal from "./CommentModal";
import IndPriorityCallRow from "./IndPriorityCallRow";

function IndCallTable({ campaigns, tableData, outcomes }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const { width } = useWindowDimensions();
    const [open, setOpen] = useState();
    const [content, setContent] = useState();

    function compare( a, b ) {
        if ( a.createdAt> b.createdAt){
          return -1;
        }
        if ( a.createdAt < b.createdAt ){
          return 1;
        }
        return 0;
      }

    const sortedTable = tableData.sort(compare)

    let mappedTable = "";
    if(tableData != null){
        mappedTable = sortedTable.map((data,index) => data.reason === undefined ?<IndCallRow type="Call" data={data} key={index} openModal={openModal} outcomes={outcomes}/>:<IndPriorityCallRow campaigns={campaigns} type="Trigger" data={data} key={index} openModal={openModal}/>)
    }

    function openModal(i) {
        setOpen(true);
        setContent(i);
    }

    function closeModal() {
        setOpen(false);
    }

    return (
        <Fragment>
            {open && <CommentModal content={content} close={closeModal} />}
            <div className={`rounded-lg border ${dark ? "bg-neutral-900 border-neutral-300" : "bg-white border-neutral-200"} pb-2 `}>
                <table className={`w-full rounded border-neutral-300 ${width > 640 && dark ? " border-neutral-300" : "border-neutral-600"} `}>
                    {width > 640 && <Fragment>
                        <thead >
                            <tr key={"header"} className={`border-b text-sm ${dark ? "border-neutral-300" : "border-neutral-200"} `}>
                                <th scope="col" className={`pl-2 py-2 font-bold text-neutral-300 text-left `}>Date</th>
                                <th className={`font-bold text-neutral-300 text-left `}>Time</th>
                                <th className={`font-bold text-neutral-300 text-left `}>Duration</th>
                                <th className={`font-bold text-neutral-300 text-left`}>Type</th>
                                <th className={`font-bold text-neutral-300 text-left`}>Details</th>
                                <th className={`font-bold text-neutral-300 text-left `}>Answered</th>
                                <th className={`font-bold text-neutral-300 text-left `}>Outcome</th>
                                <th className={`font-bold text-neutral-300 text-left `}>Notes</th>
                            </tr>
                        </thead>
                    </Fragment>}
                    <tbody>
                        {mappedTable}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default IndCallTable;