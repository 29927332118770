import { useContext} from "react";
import { ThemeContext } from "../../context/ThemeContext";

function LargeTextBox({onChange, name, value}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return(
        <textarea rows="3" onChange={onChange} type="text" id={name} name={name} value={value} className={`${dark ? "bg-neutral-800 border-neutral-600  text-white" : "bg-neutral-50 border-neutral-300  text-neutral-900" } border  text-sm rounded-lg focus:outline-none focus:outline-accent block w-full p-2.5`}/>
    )
}

export default LargeTextBox;