import { prospectingActions } from "./prospecting-slice";

//"https://prospecting-api.azurewebsites.net/Prospects/GetMany"

export function GetProspectingData(org) {
  return async (dispatch) => {
    const fetchCustomer = async (o) => {

        var raw = JSON.stringify(o);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetMany",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch customers.');
      }

      const data = await response.json();

      return data;
    }
    const fetchProsp = async (o) => {

      var raw = JSON.stringify([o]);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL + "/Prospects/GetMany",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }
    const fetchPriority = async (o) => {

      var raw = JSON.stringify(o);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL + "/Campaigns/GetPriorityTriggered",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects.');
      }

      const data = await response.json();

      return data;
    }

    let customer = [];
    let prosp = [];
    let priorityTriggered = [];

    async function setId(i) {
      const ids = [];
      for (let index = 0; index < i.length; index++) {
        const element = i[index]
        ids.push(element.customerId);
      }
      return ids;
    }
      prosp = (await fetchProsp(org));
      customer = (await fetchCustomer(await setId(prosp)));
    
    const items = prosp.map((x) => {
      const y = customer.find(item => x.customerId === item.id);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(customer.filter(item => prosp.every(x => x.customerId !== item.id)));

    console.log("paart done prosp")
    const fetchDetails = async (ids) => {

      var raw = JSON.stringify(ids);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetDetails",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch details.');
      }

      const data = await response.json();

      return data;
    }

    const fetchOrgMap = async (ids) => {

      var raw = JSON.stringify(ids);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Customers/GetOrgMap",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch org map.');
      }

      const data = await response.json();

      return data;
    }
    const cust = items.map(a => a.customerId);
    const details = await fetchDetails(cust);
    const orgMap = await fetchOrgMap(cust);

    const code = details.map((x) => {
      const y = items.find(item => x.customer === item.customerId);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(items.filter(item => details.every(x => x.customer !== item.customerId)));

    const fin = code.map((x) => {
      const y = orgMap.find(item => x.customer === item.customerId);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(orgMap.filter(item => code.every(x => x.customer !== item.customerId)));

    const v = customer.map(p => p.id);
    priorityTriggered = (await fetchPriority(v));
    const pt = priorityTriggered.map(p => p.customerId);
    const finalList = fin.filter(f => pt.includes(f.customerId));
    finalList.forEach(element => {
      const p = priorityTriggered.filter(f => f.customerId === element.customerId);
      const points = p.map(n => n.points);
      points.forEach(e => {
        element.points += e;
      });
      const reason = p.map(n => n.reason);
      const list = [];
      reason.forEach(e =>{
        list.push(e);
      });
      element.reason = list;
    });

    console.log("try to get prosp")
    dispatch(prospectingActions.replaceProspecting({ items: finalList }))
  }
}

export function GetPriorityTriggered(custId){
  return async (dispatch) => {
    const fetchPriority = async (ids) => {

      var raw = JSON.stringify(ids);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetPriorityTriggered",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch priority triggered');
      }

      const data = await response.json();

      return data;
    }

    const pt = await fetchPriority(custId);
    
    dispatch(prospectingActions.replacePriorityTriggered({ items: pt }))
  }
}

export function GetCurrentProspects(custId){
  return async (dispatch) => {
    const fetchPriority = async (ids) => {

      var raw = JSON.stringify([ids]);

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(process.env.REACT_APP_API_URL+"/Prospects/GetMany",
        requestOptions
      );

      if (!response.ok) {
        throw new Error('Could not fetch prospects');
      }

      const data = await response.json();

      return data;
    }

    const pt = await fetchPriority(custId);
    
    dispatch(prospectingActions.addToCurrentProspects({ items: pt }))
  }
}

export function CreateProspectingData(data) {
  return async (dispatch) => {
    async function generateUCRN(site) {

      var headers = {
        "Content-Type": "text/plain; charset=utf-8",
        "Authorization": "bearer " + localStorage.getItem("token")
      }
      var requestOptions = {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Organisation/GenerateUCRN?id=" + site,
        requestOptions
      );

      const data = response.text();
      return data;
    }

    async function createProsp(prospData) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(prospData);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Prospects/Create",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };

    async function createCustomer(custData) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(custData);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/Create",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };

    async function createOrgMap(custData) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(custData);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateOrgMap",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };

    async function createPriority(custData) {

      var headers = {
        "Content-Type": "application/json",
        "Authorization": "bearer " + localStorage.getItem("token")
      }

      var raw = JSON.stringify(custData);

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow'
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL+"/Customers/CreateOrgMap",
        requestOptions
      );
      if (!response.ok) {
        throw new Error("Sending Data Failed");
      }
    };

    const ucrn = await generateUCRN(data.site)
    data.customer.ucrn = ucrn;
    data.prospect.orgSiteId = data.site;
    data.orgMap.siteId = data.site;
    data.priority.relatedId = data.site;
    let myPromise = new Promise(async function a(resolve, reject) {
      await createCustomer(data.customer);
      return resolve();
    });
    myPromise.then(async function b() {
      await createProsp(data.prospect);
      await createOrgMap(data.orgMap);
      await createPriority(data.priority);
    }
    )
    const prosp = [data.prospect];
    const cus = [data.customer];
    const det = [data.details];
    const org = [data.orgMap];
    const priority = [data.priority];

    const items = prosp.map((x) => {
      const y = cus.find(item => x.customerId === item.id);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(cus.filter(item => prosp.every(x => x.customerId !== item.id)));
    const details = items.map((x) => {
      const y = det.find(item => x.customerId === item.customer);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(det.filter(item => items.every(x => x.customerId !== item.customer)));
    const prty = details.map((x) => {
      const y = priority.find(item => x.customerId === item.customer);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(priority.filter(item => items.every(x => x.customerId !== item.customer)));
    const final = prty.map((x) => {
      const y = org.find(item => x.customerId === item.customerId);
      if (y) {
        return Object.assign({}, x, y);
      } else
        return x
    }).concat(org.filter(item => details.every(x => x.customerId !== item.customerId)));

    dispatch(prospectingActions.addToProspecting(final))


  };
}