import { Fragment } from "react";
import SmallDropdown from "../UI/SmallDropdown";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

const numbers = [
  { id: 1, name: 10, value: 10 },
  { id: 2, name: 20, value: 20 },
  { id: 3, name: 30, value: 30 },
  { id: 4, name: 40, value: 40 },
  { id: 5, name: 50, value: 50 },
  { id: 6, name: 60, value: 60 },
  { id: 7, name: 70, value: 70 },
  { id: 8, name: 80, value: 80 },
];

function TablePages({ changePages, movePage, firstPage, lastPage, dataLength }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;

  function changeRows(i) {
    changePages(i.value);
  }

  function forwardPage() {
    movePage(true)
  }

  function backwardPage() {
    movePage(false);
  }

  return (
    <Fragment>
      <nav
        className={`flex items-center h-min justify-end pt-2 mb-2 right-0 ${dark ? "bg-neutral-900" : "bg-white"
          } space-x-4`}
        aria-label="Table navigation"
      >
        <p
          className={`text-sm ${dark ? "text-neutral-500" : "text-neutral-400"
            } pb-0.5`}
        >
          Rows per page:
        </p>
        <div
          className={`font-semibold  ${dark ? "text-neutral-500" : "text-neutral-400"
            } `}
        ><div className="">
            <SmallDropdown options={numbers} onChange={changeRows} bigText={false} />
          </div>
        </div>
        <span className="text-sm font-normal text-neutral-500 dark:text-neutral-400">
          {" "}
          <span
            className={`font-semibold ${dark ? "text-neutral-300" : "text-neutral-900"}`}
          >
            {firstPage + "-" + (lastPage > dataLength ? dataLength : lastPage)}
          </span>{" "}
          of{" "}
          <span
            className={`font-semibold ${dark ? "text-neutral-300" : "text-neutral-900"}`}
          >
            {dataLength}
          </span>
        </span>
        <ul className="inline-flex items-center -space-x-px pr-2">
          <li>
            <button className="block px-3 py-2 ml-0 leading-tight text-accent hover:text-neutral-700" onClick={backwardPage}>
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
          <li>
            <button className="block px-3 py-2 leading-tight text-accent hover:text-neutral-700" onClick={forwardPage}>
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
}

export default TablePages;
