import { campaignActions } from "./campaign-slice";

export function GetCampaignData(site) {
    return async (dispatch) => {
        const fetchSiteCampaigns = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify([
                site
            ]);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/SiteCampaigns/GetMany",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch site Campaigns data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchTypes = async (ids) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(ids);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetTypes",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch site Campaigns data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchSiteComms = async (ids) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(ids);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetSiteCampaignComms",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch site Comms data.');
            };

            const data = await response.json();
            return data;
        }
        const fetchComms = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetComms",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch comms data.');
            };

            const data = await response.json();
            return data;
        }

        const campData = await fetchSiteCampaigns();

        const siteIds = campData.map(d => d.id);

        const siteComms = await fetchSiteComms(siteIds);

        const comms = await fetchComms();

        const campaigns = campData.map(d => d.campaigns);

        const camapignIds = campaigns.map(d => d.id);

        const type = await fetchTypes(camapignIds);

        dispatch(campaignActions.replaceCampaign({ siteCampaigns: campData || [], campaigns: campaigns || [], items: campaigns || [], type: type || [], siteComms: siteComms || [], comms: comms || [] }))
        
    }
};

export function GetSendGridCampaignData(ids) {
    return async (dispatch) => {
        const fetchSendGrid = async () => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify([ids]);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetSendGridEvents",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch send grid data.');
            };

            const data = await response.json();
            return data;
        }

        const sendGrid = await fetchSendGrid();

        dispatch(campaignActions.replaceSendGrid(sendGrid))

    }
}

export function GetCampaignScripts(val) {
    return async (dispatch) => {
        const fetchScripts = async (ids) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(ids);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetScripts",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch scripts');
            };

            const data = await response.json();
            return data;
        }
        const scripts = await fetchScripts(val);

        dispatch(campaignActions.replaceScripts(scripts))

    }
}

export function GetCampaignImages(val) {
    return async (dispatch) => {
        const fetchImages = async (ids) => {
            var headers = {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.getItem("token")
            }

            var raw = JSON.stringify(ids);

            var requestOptions = {
                method: 'POST',
                headers: headers,
                body: raw,
                redirect: 'follow'
            };

            const response = await fetch(process.env.REACT_APP_API_URL+"/Campaigns/GetImages",
                requestOptions
            );

            if (!response.ok) {
                throw new Error('Could not fetch images');
            };

            const data = await response.json();
            return data;
        }
        const scripts = await fetchImages(val);

        dispatch(campaignActions.replaceImages(scripts))

    }
}