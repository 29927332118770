import SetTwoFactor from "../components/SetTwoFactor";
import TransitionWrapper from "../components/TransitionWrapper";

function EditAuthPage({ show }) {
    return (
        <TransitionWrapper show={show}>
            <SetTwoFactor />
        </TransitionWrapper>
    )
}

export default EditAuthPage;