import useWindowDimensions from "../hooks/useWindowDimensions";
import SmallDropdown from "./UI/SmallDropdown";

function DashboardFilter({ timeFrame }) {
    useWindowDimensions();
    const fal = false;

    return (
        <div className={`mt-20 bg-accent sm:space-x-6 w-min overflow-x-auto rounded-t-lg shadow-md  `}>
            {fal && <SmallDropdown options={timeFrame} bigText={false} accent={true} />}
        </div>
    )
}

export default DashboardFilter;