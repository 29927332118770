import { Form, useNavigation } from "react-router-dom";
import SolidButton from "./UI/SolidButton";
import TextInput from "./UI/TextInput";
import Header from "./UI/Header";
import { useSelector } from "react-redux";

function TwoFactor({clear}) { 
  const email = useSelector((state) => state.currentUser.tempEmail);
  const password = useSelector((state) => state.currentUser.tempPass);
  const navigation = useNavigation();
  const isSubmitting = navigation.state === 'submitting';

    return (
        <Form method="post" onSubmit={clear}>
            <input hidden name={"email"} value={email} readOnly/>
            <input hidden name={"password"} value={password} readOnly/>
            <Header>Input Authenticator Code:</Header>
            <div className="mt-2 space-y-2">
                <TextInput name="code" />
            </div>
            <div className="pt-2 flex justify-center">
                <SolidButton type="submit" text={isSubmitting ? 'Submitting...' : 'Authenticate'} />
            </div>
        </Form>
    )
}

export default TwoFactor;