import { useState } from "react";
import Accordion from "../components/UI/Accordion";
import Header from "../components/UI/Header";
import Header2 from "../components/UI/Header2";
import LargeCircleImage from "../components/UI/LargeCircleImage";
import SearchBar from "../components/UI/SearchBar";
import TransitionWrapper from "../components/TransitionWrapper";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const items = [
    {
        title: "Question 1",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },
    {
        title: "Question 2",
        content: "Answer 2"
    },
    {
        title: "Question 3",
        content: "Answer 3"
    },
    {
        title: "Question 4",
        content: "Answer 4"
    },
    {
        title: "Question 5",
        content: "Answer 5"
    },
]

function FAQPage({ show }) {
    const theme = useContext(ThemeContext);
    const { dark } = theme;
    const [searchInput, setSearchInput] = useState("");

    //data filter
    const filteredData = searchInput !== "" ? items.filter(o =>
        Object.keys(o).some(k =>
            o[k].toString().toLowerCase().indexOf(searchInput) !== -1)) : items

    //search bar logic
    const handleSearch = (input) => {
        setSearchInput(input)
    };

    const empty = filteredData.length;

    return (
        <TransitionWrapper show={show}>
            <div className="fixed h-screen w-screen mt-20">
                <div className="relative w-full z-10">
                    <LargeCircleImage image={"M65.375 52.125C74.6667 46.9167 84.5208 44.3125 94.9375 44.3125C103.521 44.3125 110.333 46.375 115.375 50.5C120.458 54.625 123 60.125 123 67C123 71.5833 121.958 75.5417 119.875 78.875C117.792 82.2083 113.833 85.9583 108 90.125C104 93.0833 101.458 95.3333 100.375 96.875C99.3333 98.4167 98.8125 100.438 98.8125 102.938V106.688H82.25V102.062C82.25 98.0625 83.1042 94.5833 84.8125 91.625C86.5208 88.6667 89.6458 85.5208 94.1875 82.1875C98.5625 79.0625 101.438 76.5208 102.812 74.5625C104.229 72.6042 104.938 70.4167 104.938 68C104.938 65.2917 103.938 63.2292 101.938 61.8125C99.9375 60.3958 97.1458 59.6875 93.5625 59.6875C87.3125 59.6875 80.1875 61.7292 72.1875 65.8125L65.375 52.125ZM80.25 128.062C80.25 124.562 81.1875 121.917 83.0625 120.125C84.9375 118.333 87.6667 117.438 91.25 117.438C94.7083 117.438 97.375 118.354 99.25 120.188C101.167 122.021 102.125 124.646 102.125 128.062C102.125 131.354 101.167 133.958 99.25 135.875C97.3333 137.75 94.6667 138.688 91.25 138.688C87.75 138.688 85.0417 137.771 83.125 135.938C81.2083 134.062 80.25 131.438 80.25 128.062Z"} />
                </div>
                <div
                    className={`static my-6 mx-auto max-w-7xl pt-24 mt-32 pb-2 ${dark ? "bg-neutral-900" : "bg-white"} rounded-lg`}
                >
                    <div className=" mx-auto pt-6 mt-6">
                        <div className="pl-8">
                            <Header>How can we help you?</Header>
                        </div>
                        <div className="px-4">
                            <SearchBar submit={handleSearch} previewText="Describe your question" />
                        </div>
                        <div className="pl-8 pt-2">
                            <Header2>Browse frequently asked questions</Header2>
                        </div>
                        <div className=" px-8">
                            {empty > 0 && <Accordion items={filteredData} />}
                            {empty === 0 && <p className={` text-center font-semibold ${dark ? "text-white" : "text-neutral-900"}`}>{"Cannot find anything matching your search, try chatting with us instead."}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </TransitionWrapper>
    )
}

export default FAQPage;