import Card from "../UI/Card";
import RingChart from "./RingChart";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LoadingDots from "../UI/LoadingDots";

function RingChartBacker({ maxValue, data, title, loaded }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  return (
    <Card >
      <div id="ring_chart" className="h-full justify-center flex-grow mb-auto">
        <div className={`container flex flex-wrap  ${dark ? "text-neutral-200" : "text-neutral-900"} items-center py-6 px-6 mb-2`}>
          {loaded?
          data[0].value !== 0 || data[1].value !== 0 || data[2].value !== 0 ? <h1 className={`absolute font-bold text-2xl`}>{title}</h1> : <h1 className={`absolute font-bold text-2xl`}>No Data Found</h1>:
          <LoadingDots/>
          }
        </div>
        <div className=" w-full h-3/4 flex justify-between"><RingChart maxValue={maxValue} data={data} loaded={loaded}/></div>
      </div>
    </Card>
  );
}

export default RingChartBacker;
