import TableBody from "./TableBody";
import TablePages from "./TablePages";
import TableHeader from "./TableHeader";
import { useEffect, useState, useContext } from "react";
import {ThemeContext} from "../../context/ThemeContext"
import SearchBar from "../UI/SearchBar";
import { useSelector, useDispatch } from "react-redux";
import FilterMenu from "../UI/FilterMenu";
import SkeletonTable from "../UI/SkeletonTable";
import { GetCustomerData } from "../../store/customer-actions";
import { prospectingActions } from "../../store/prospecting-slice";
import FilterButton from "../UI/FilterButton";
import SquareRadio from "../UI/SquareRadio";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const tableColumns = [
    {
        name: "UCRN",
        id:"customerId"
    },
    {
        name: "Title",
        id:"title"
    },
    {
        name: "First name",
        id:"firstName"
    },
    {
        name: "Surname",
        id:"lastName"
    },
    {
        name: "Site",
        id:"site"
    },
    {
        name: "Campaign",
        id:"company"
    },
    {
        name: "Last called",
        id:"lastCalled"
    },
    {
        name: "Last sales person",
        id:"salesPerson"
    },
    {
        name: "How hot?",
        id:"points"
    },
]

function CallTable({modalToggle, modal}) {
  const curPage = useSelector((state) => state.prospecting.page);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  let firstPage = ((curPage - 1) * rowsPerPage);
  let lastPage = (curPage * rowsPerPage);
  const dispatch = useDispatch();

  const tableData = useSelector((state)=> state.prospecting.filtered);
  const amount = useSelector((state) => state.prospecting.amount);
  const stateLoad = useSelector((state) => state.customers.loaded);
  const max = useSelector((state) => state.prospecting.maxPoints);
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const {width} = useWindowDimensions();
    const [filterOpen, SetFilter] = useState(false);
    const [hotFilter, setHotFilter] = useState([]);
    const [campaignFilter, setCampaignFilter] = useState([]);
    const [siteFilter, setSiteFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState("none");
    const [dateRangeFilter, setDateRangeFilter] = useState([]);
    const [salesPersonFilter, setSalesPersonFilter] = useState("");
    const [sortMultiple, setSortMultiple] = useState(false);

     function setId(i){
      const ids = [];
      for (let index = 0; index < i.length; index++) {
        const element = i[index]
        if(!ids.includes(element.lastSalesExec)){
          ids.push(element.lastSalesExec);
        }
      }
      return ids;
      }

      const salesPeople = setId(tableData);

    useEffect(()=>{
        if(tableData.length !==0){
          const ids = tableData.map(c => c.customerId)
          dispatch(GetCustomerData(ids));
        }
    },[tableData, stateLoad, dispatch])

    let amountOfPages = Math.ceil(amount / rowsPerPage);

    function changeRowsPerPage(rows){
      setRowsPerPage(rows);
      dispatch(prospectingActions.changePageLength(rows));
    }

    function changePage(forwards){
      if(forwards)
      {
        dispatch(prospectingActions.changePage(1))
      }else{
        dispatch(prospectingActions.changePage(-1))
      }
    }

    function toggleSortMultiple(e)
    {
      setSortMultiple(!sortMultiple);
    }

    function requestSort(direction, id, add){
      if(sortMultiple)
      {
        dispatch(prospectingActions.sortMultipleProspecting({id:id,add:add}));
      }else{
        dispatch(prospectingActions.sortProspecting({direction,id}));
      }
    }

    function openEditModal(customer){
      modalToggle("edit", customer)
    }

    function openModal(e){
      modalToggle("create");
    }

    function openFilter(){
      SetFilter(!filterOpen);
    }
    
    const handleSearch = (input) => {
      dispatch(prospectingActions.getFilteredProspecting(input));
    };

    function filterControl(filter){
      if (hotFilter.includes(filter))
      {
        setHotFilter((prevState) =>
        prevState.filter((prevItem) => prevItem !== filter));
      }else{
        setHotFilter(oldArray => [...oldArray,filter]);
      }
    }    
    
    function filterCampaign(filter){
      if (campaignFilter.includes(filter))
      {
        setCampaignFilter((prevState) =>
        prevState.filter((prevItem) => prevItem !== filter));
      }else{
        setCampaignFilter(oldArray => [...oldArray,filter]);
      }
    }
    
    function filterSite(filter){
      if (siteFilter.includes(filter))
      {
        setSiteFilter((prevState) =>
        prevState.filter((prevItem) => prevItem !== filter));
      }else{
        setSiteFilter(oldArray => [...oldArray,filter]);
      }
    }

    function filterCallperson(filter){
        setSalesPersonFilter(filter)
    }

    function filterDate(filter, range){
      if(range){
        if(filter.length !== 0){
        const mapped = filter.map(f=> new Date(f).getFullYear() + new Date(f).getDay() + new Date(f).getMonth())
        console.log(mapped)
        setDateRangeFilter(mapped);
        }else{
          setDateRangeFilter(filter);
        }
      }
      else{
        if(filter!=="none"){
        const mapped = new Date(filter).getFullYear() + new Date(filter).getDay() + new Date(filter).getMonth();
        setDateFilter(mapped);
        }else{
          setDateFilter(filter);
        }
      }
    }

  return (
    <div className={` ${modal && "blur-sm"}`}>
    <div id="prospecting_table" className={`relative mb-10 mx-auto max-w-7xl overflow-x-auto shadow-md sm:rounded-lg mt-20 ${dark ? "bg-neutral-900" : "bg-white"}`}>
      <div className={` ${width<=400?"":"flex items-center"} w-full h-min justify-between flex-nowrap  pl-2`}>
        <div className="w-full">
        <SearchBar data={tableData} submit={handleSearch}/>
        </div>
        <button onClick={openModal} id="create" className={`${dark? "text-white":"text-neutral-900"} ${width<=400?"pl-1 w-full":"w-1/2"} h-min flex justify-start`}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="mt-1">
        <path className={`fill-accent`} d="M6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6ZM6 8C7.5913 8 9.11742 8.63214 10.2426 9.75736C11.3679 10.8826 12 12.4087 12 14H0C0 12.4087 0.632141 10.8826 1.75736 9.75736C2.88258 8.63214 4.4087 8 6 8ZM14 4C14 3.73478 13.8946 3.48043 13.7071 3.29289C13.5196 3.10536 13.2652 3 13 3C12.7348 3 12.4804 3.10536 12.2929 3.29289C12.1054 3.48043 12 3.73478 12 4V5H11C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043 10 5.73478 10 6C10 6.26522 10.1054 6.51957 10.2929 6.70711C10.4804 6.89464 10.7348 7 11 7H12V8C12 8.26522 12.1054 8.51957 12.2929 8.70711C12.4804 8.89464 12.7348 9 13 9C13.2652 9 13.5196 8.89464 13.7071 8.70711C13.8946 8.51957 14 8.26522 14 8V7H15C15.2652 7 15.5196 6.89464 15.7071 6.70711C15.8946 6.51957 16 6.26522 16 6C16 5.73478 15.8946 5.48043 15.7071 5.29289C15.5196 5.10536 15.2652 5 15 5H14V4Z" />
        </svg>
        <p className={`${dark? "text-neutral-300":"text-neutral-300"} ${width<=400?"pl-4":"pl-2"} font-semibold`}>Add new customer</p>
        </button>
        <div className="flex h-full w-full justify-end">
      {filterOpen && <FilterMenu filterDate={filterDate} salesPeople={salesPeople} filterControl={filterControl} filterCampaign={filterCampaign} filterSite={filterSite} filterCallperson={filterCallperson} close={openFilter} hotFilter={hotFilter}/>}
      </div>
      <div className={`flex ${width<=400?"w-full":"w-1/3"}  justify-end`}>
      <SquareRadio onClick={toggleSortMultiple} name={"Sort Multiple?"} boxCol={"border-neutral-300"} crossCol={"text-neutral-300"} textCol={"text-neutral-300"}/>
      </div>
        <FilterButton openFilter={openFilter}/>
      </div>
      <table className="w-full text-sm text-left text-neutral-500 dark:text-neutral-400">
        <TableHeader columns={tableColumns} onClick={requestSort} multiple={sortMultiple}/>
        <tbody>
          {tableData.length > 0 ?  
          <TableBody filterDate={dateFilter} filterDateRange={dateRangeFilter} hotFilter={hotFilter} tableData={tableData} filterCampaign={campaignFilter} filterSite={siteFilter} filterCallperson={salesPersonFilter} max={max} openModal={openEditModal}/>: <SkeletonTable/>}
        </tbody>
        <tfoot className={`${dark ? "bg-neutral-900" : "bg-white"}`}></tfoot>
      </table>
      <TablePages changePages={changeRowsPerPage} movePage={changePage} firstPage={firstPage + 1} lastPage={lastPage} amountOfPages={amountOfPages} dataLength={amount}/>
    </div>
    <div id="filterModal" data-modal-placement="top-right" tabIndex="-1" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full">
    </div>
    </div>
  );
}

export default CallTable;
