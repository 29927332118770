import CardCentered from "../UI/CardCentered";
import Header2 from "../UI/Header2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import TextInputLabeled from "../UI/TextInputLabeled";
import SquareRadio from "../UI/SquareRadio";
import LargeTextBox from "../UI/LargeTextBox";
import IndCallTable from "./IndCallTable";
import { useDispatch, useSelector } from "react-redux";
import { GetVehicleData, UpdateVehicle } from "../../store/vehicle-actions";
import { customerActions } from "../../store/customer-slice";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { GetCustomerData, UpdateCustomerData } from "../../store/customer-actions";
import Header from "../UI/Header";
import AccordionHeaderInput from "../UI/AccordionHeaderInput";
import CallCompleteModal from "./CallCompleteModal";
import ColouredSolidButton from "../UI/ColouredSolidButton";
import InformationIcon from "../UI/InformationIcon";
import { GetCurrentProspects } from "../../store/prospecting-actions";
import CrossButton from "../UI/CrossButton";
import TitleColonMessage from "../UI/TitleColonMessage";

function Outbound(props){
    //let { ucrn } = useParams();
    const dispatch = useDispatch();
    const data = useLocation();
    const history = useNavigate();
    const theme = useContext(ThemeContext);
    const {dark} = theme;
    const prosp = useSelector((state) => state.prospecting.items);
    const priority = useSelector((state) => state.prospecting.priorityTriggered);
    const det = useSelector((state) => state.customers.filtered);
    const check = useSelector((state) => state.customers.details);
    const outcomes = useSelector((state) => state.customers.outcomes);
    const prospects = useSelector((state) => state.prospecting.prospects);
    const user = useSelector((state) => state.currentUser.item);
    const v = useSelector((state) => state.vehicles.maps);
    const ch = useSelector((state) => state.customers.callHistory);
    const campaigns = useSelector((state) => state.campaign.campaigns);
    const scripts = useSelector((state) => state.campaign.scripts);
    const images = useSelector((state) => state.campaign.images);
    const veh = useSelector((state)=> state.vehicles.items);
    const services = useSelector((state)=> state.vehicles.services);
    const purchases = useSelector((state)=> state.vehicles.purchases);
    const dvla = useSelector((state)=> state.vehicles.dvla);
    const site = useSelector((state)=> state.currentUser.currentSite);
    const {width} = useWindowDimensions();        
    const [details, setDet] = useState();
    const [active, setActive] = useState()
    const [vehicleMap, setVehicleMap] = useState()
    const [openTable, setopenTable] = useState()
    const [openFinal, setFinalOpen] = useState(false);
    const [openScript, setOpenScript] = useState(false);
    const [openImage, setOpenImage] = useState(false);
    const [dateStrings, setDateStrings] = useState({mot: null, delivery: null, offerCompleted: null, service: null})
    const [reasons, setReasons] = useState(priority);
    const [cust,setCust] = useState({lastSalesExec: null })
    const [pro, setPro] = useState();
    const [recentCampaign, setRecentCampaign] = useState({name:""});
    const [script, setScript] = useState();
    const [image, setImage] = useState();
    const [customerId, setCustomerId] = useState("");
    const [cal] = useState(ch);

    useEffect(()=>{
        const strings = data.pathname.split("/");
        const l = strings.length-1;
        const id = strings[l];
        setCustomerId(id)
        data.state = id;
      },[data])

    function changeActive(id){
        setActive(id)
    }

    function changeTable(id){
        setopenTable(id)
    }

    function OpenEndModal(){
        setFinalOpen(!openFinal)
    }
    
    function OpenScriptModal(){
        setOpenScript(!openScript)
    }
    
    function OpenImageModal(){
        setOpenImage(!openImage)
    }
    
    useEffect(() =>{
        if(site.length!==0){
            dispatch(GetCustomerData(customerId));
            dispatch(GetCurrentProspects(customerId));
            dispatch(GetVehicleData(customerId));
        }
      },[site, customerId])

      useEffect(() =>{
        if(check.length!==0){
            dispatch(customerActions.getFilteredCustomers(customerId));
        }
      },[check, customerId])

      useEffect(()=>{
        if(det!==undefined){
            setDet(det);
        }
      },[det])
      
      useEffect(()=>{
        if(v!==undefined){
            setVehicleMap(v);
        }
      },[v])
      
    useEffect(()=>{
        if(prospects.length !== 0){
            const arr = prospects[0].map(p => p.createdAt);
            const latest = arr.map(p => new Date(p)).sort().slice(-1);
            const t = latest[0]
            const fin = prospects[0].find(f => new Date(f.createdAt).toISOString() === new Date(t).toISOString());
            if(fin!==undefined){
                setPro(fin);
            }
        }
      },[prospects])

      useEffect(()=>{
        if(pro!==undefined && campaigns.length!==0){
            const cam = campaigns.find(c => c.id === pro.campaignId);
            const sc = scripts.find(s => s.campaignId === cam.id);
            const im = images.find(s => s.campaignId === cam.id);
            console.log(images);
            setRecentCampaign(cam);
            setScript(sc);
            setImage(im);
        }
      },[pro,campaigns])

      const [vehicle, setVehicles] = useState([]);
      const [dvlas, setDvlas] = useState();
      const [serv, setServ] = useState();
      const [pur, setPur] = useState();

      useEffect(()=>{
        if(customerId !== null && vehicleMap !== undefined){

        const map = vehicleMap.filter(i => i.customerId === customerId);
    
        if(map.length !== 0){
        const ve = veh.filter(i => i.id === map[0].vehicleId);
        setVehicles(ve);
        const s = services.filter(i => i.vehicleId === map[0].vehicleId)[0];
        setServ(s);
        const p = purchases.filter(i => i.vehicleId === map[0].vehicleId)[0];
        setPur(p);
        }
        if(vehicle.length !== 0){
        const d = dvla.filter(i => i.registrationNumber === vehicle[0].registration.split(' ').join(''))[0];
        setDvlas(d);
        }
        }
      },[customerId, vehicleMap, veh, dvla, services, purchases])

    useEffect(()=>{
        let p = prosp.filter(i =>i.id === customerId)[0]
        if(p !== undefined){
        setCust({lastSalesExec: p.lastSalesExec})
        }
    },[prosp, details])
    
    useEffect(()=>{
        if(priority !== undefined && priority.length !== 0){
            const p = priority.filter(i => i.customerId ===  customerId);
            if(p!== undefined){
                setReasons(p);
            }
        }
    },[priority, details])

    useEffect(()=>{
        if(dvlas !== undefined){
        setDateStrings(prevState => ({
            ...prevState,mot: new Date(dvlas.motExpiryDate).toDateString()})
            )
        }
        if(pur !== undefined){
            if(pur.deliveryDate !== null){
                setDateStrings(prevState => ({
                    ...prevState,delivery: new Date(pur.deliveryDate).toDateString()})
                    )
            }if(pur.offerCompleted !== null){
                setDateStrings(prevState => ({
                    ...prevState,
                offerCompleted: new Date(pur.offerCompleted).toDateString()})
                    )
            }
        }
        if(serv !== undefined){
            setDateStrings(prevState => ({
                ...prevState,service: new Date(serv.lastVisitDate).toDateString()})
                )
        }
    },[dvlas, pur, serv])
    
    const [address,setAddress] = useState({addressL1:"",addressL2:"",addressL3:"",addressL4:"", addressL5:"", postcode: "", modifiedAt:"" ,modifiedBy:"", customer: "", createdBy: "", createdAt: "", id: ""});
    const [contact,setContact] = useState({email:"",altEmail:"",tel:"",altTel:"",modifiedAt:"" ,modifiedBy: "", customer:"", createdBy: "", createdAt:"", id:""});
    const [detail,setDetails] = useState({title:"",firstName:"",lastName:"", notes:"",modifiedAt:"" ,modifiedBy: "", customer: "", createdBy: "", createdAt: "", id:""});
    const [vehiclea,setVehicle] = useState(vehicle[0]!== undefined?{id:vehicle[0].id,registration:vehicle[0].registration, make:vehicle[0].make, model:vehicle[0].model, firstUsedDate: vehicle[0].firstUsedDate, fuelType: vehicle[0].fuelType, primaryColour: vehicle[0].primaryColour, vin: vehicle[0].vin, regDate: vehicle[0].regDate, manufactureDate: vehicle[0].manufactureDate, engineSize: vehicle[0].engineSize, createdAt: vehicle[0].createdAt, createdBy: vehicle[0].createdBy, modifiedAt: new Date(), modifiedBy: user.email}:{id:"",registration:"", make:"", model:"", firstUsedDate: "", fuelType: "", primaryColour: "", vin: "", regDate: "", manufactureDate: "", engineSize: "", createdAt: "", createdBy: "", modifiedAt: new Date(), modifiedBy: user.email});

    useEffect(()=>{
        if(details !== undefined && details.length !== 0){
            setAddress({addressL1:details[0].addressL1,addressL2:details[0].addressL2,addressL3:details[0].addressL3,addressL4:details[0].addressL4, addressL5:details[0].addressL5, postcode: details[0].postcode, modifiedAt:"" ,modifiedBy: user.email, customer: details[0].customer, createdBy: details[0].createdBy, createdAt: details[0].createdAt, id: details[0].id});
            setContact({email:details[2].email,altEmail:details[2].altEmail,tel:details[2].tel,altTel:details[2].altTel,modifiedAt:"" ,modifiedBy: user.email, customer: details[0].customer, createdBy: details[2].createdBy, createdAt: details[2].createdAt, id: details[2].id})
            setDetails({title:details[3].title,firstName:details[3].firstName,lastName:details[3].lastName, notes:details[3].notes,modifiedAt:"" ,modifiedBy: user.email, customer: details[0].customer, createdBy: details[3].createdBy, createdAt: details[3].createdAt, id: details[3].id})
        }
    },[details, customerId, user.email])

    useEffect(()=>{
        if(vehicle!== undefined && vehicle.length !==0){
            setVehicle({id:vehicle[0].id,registration:vehicle[0].registration, make:vehicle[0].make, model:vehicle[0].model, firstUsedDate: vehicle[0].firstUsedDate, fuelType: vehicle[0].fuelType, primaryColour: vehicle[0].primaryColour, vin: vehicle[0].vin, regDate: vehicle[0].regDate, manufactureDate: vehicle[0].manufactureDate, engineSize: vehicle[0].engineSize, createdAt: vehicle[0].createdAt, createdBy: vehicle[0].createdBy, modifiedAt: new Date(), modifiedBy: user.email});
        }
    },[vehicle])

    const callHistory = cal.filter(c => c.customerId === customerId);

    function filterControl(){
    }
    
    useEffect(()=>{
        if(props.save === true)
        {
            updateInfo();
            history("/prospecting")
        }
        function updateInfo(){
            dispatch(UpdateCustomerData({details:detail,address:address,contact:contact}))
            dispatch(UpdateVehicle({vehicle:vehiclea}))
        }
    },[address, contact, detail, history, props.save, vehiclea])

    const today = new Date();
    
    function onChangeAddress(e){
        const addr = e.target.value.split(", ");

        setAddress(prevState => ({
            ...prevState,
            addressL1: addr[0]
        }))
        if(addr[1]!== ""){
        setAddress(prevState => ({
            ...prevState,
            addressL2: addr[1]
        }))
        }
        if(addr[2]!==""){
        setAddress(prevState => ({
            ...prevState,
            addressL3: addr[2]
        }))
        }
        if(addr[3]!==""){
        setAddress(prevState => ({
            ...prevState,
            addressL4: addr[3]
        }))
        }
        if(addr[4]!=="")
        {
        setAddress(prevState => ({
            ...prevState,
            addressL5: addr[4]
        }))
        }
    }
    function onChangePostcode(e){
        setAddress(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangeContact(e){
        setContact(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangeDetail(e){
        setDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }
    function onChangeVehicle(e){
        setVehicle(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }))
    }

    return(
        <div className="w-full h-full mt-20">
            {openFinal &&
            <CallCompleteModal outcomes={outcomes} OpenEndModal={OpenEndModal} customerId={detail.customer} outbound={props.t === "out"?true:false} user={user.email}/>
            }
            <div className={` ${openFinal && "blur-sm"}`}>
        <CardCentered>
            <Header>{props.t === "out"? "Outbound":"Inbound"}</Header>
            <div className="flex py-4 justify-between">
                <TitleColonMessage title={"Full name"} message={detail.title + " " + detail.firstName + " " + detail.lastName} subtle stacked/>
                <TitleColonMessage title={"Contact number"} message={contact.tel} subtle stacked/>
                <TitleColonMessage title={"Mobile number"} message={contact.mobileNumber} subtle stacked/>
                <TitleColonMessage title={"Alternative number"} message={contact.altTel} subtle stacked/>
                <TitleColonMessage title={"Registration"} message={vehiclea.registration} subtle stacked/>
                <TitleColonMessage title={"Vehicle make"} message={vehiclea.make} subtle stacked/>
                <TitleColonMessage title={"Vehicle model"} message={vehiclea.model} subtle stacked/>
            </div>
            <div className="w-full py-4">
                <div className="flex w-full justify-start space-x-2">
                    <div>
                <ColouredSolidButton colour={"bg-green-1000"} text={"Call Complete"} onClick={OpenEndModal}/>
                </div>
                <Link to={data.pathname + "/save"}>
                <ColouredSolidButton colour={"bg-green-1000"} text={"Update Customer Details"}/>
                </Link>
                <Link to={"/book/" + data.state}>
                <ColouredSolidButton colour={"bg-green-1000"} text={"Book Appointment"}/>
                </Link>
                </div>
                </div>
                <div>
            {openScript && script !== undefined && <div className="absolute flex w-full z-40" >
            <div className={`absolute justify-center w-fit h-min max-w-2xl m-auto md:h-auto `}>
                <div className={`relative rounded-lg shadow ${dark ? "bg-neutral-800" : "bg-white"} border-2 border-accent`}>
                    <div className={`flex items-start justify-between px-4 pt-4 rounded-t pl-6`}>
                        <Header>Script</Header>
                        <CrossButton onClick={OpenScriptModal} />
                    </div>
                    <p className={`${dark ? "text-white" : "text-neutral-900"} p-6`}>{script.body}</p>
                </div>
            </div></div>}
            {openImage && image !== undefined && <div className="absolute flex w-full z-40" >
            <div className={`absolute justify-center w-fit h-min max-w-2xl m-auto md:h-auto `}>
                <div className={`relative rounded-lg shadow ${dark ? "bg-neutral-800" : "bg-white"} border-2 border-accent`}>
                    <div className={`flex items-start justify-between px-4 pt-4 rounded-t pl-6`}>
                        <Header>Example Communication</Header>
                        <CrossButton onClick={OpenImageModal} />
                    </div>
                    <div className="flex w-full h-full justify-center items-center">
                    <img src={`data:image/png;base64,${image.image}`}></img>
                    </div>
                </div>
            </div></div>}
            <p className={` text-neutral-300 font-semibold`}>Most recent campaign:</p>
                <div className="flex justify-start space-x-6 py-4">
                    <div>
                    <p className={` text-neutral-300 pb-2`}>Name:</p>
                    <p className={` ${dark? "text-white" : "text-neutral-900"}`}>{recentCampaign.name}</p>
                    </div>
                    <div className="flex flex-col justify-center">
                    <p className={` text-neutral-300 w-full text-left pb-2`}>Script:</p>
                    <button className="w-full flex justify-center" onClick={OpenScriptModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={`w-6 h-6 ${dark?"fill-white":"fill-black"}`}>
                            <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625zM7.5 15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5A.75.75 0 017.5 15zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H8.25z" clipRule="evenodd" />
                            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
                        </svg>
                    </button>
                    </div>
                    <div>
                    <p className={` text-neutral-300 pb-2 `}>Example:</p>
                    <button className={` ${dark? "text-white" : "text-neutral-900"}`} onClick={OpenImageModal}>view</button>
                    </div>
                </div></div>
            <div className="flex pb-2">
            <Header2>History:</Header2>
            <InformationIcon info={"Sample Text"}/>
            </div>
            <AccordionHeaderInput title={"History Table"} onChange={changeTable} index={0} length={1} active={openTable}>
            <IndCallTable campaigns={campaigns} tableData={callHistory.concat(reasons)} outcomes={outcomes}/>
            </AccordionHeaderInput>
            <div className="mt-6 pb-2">
            <Header2>Edit Customer Details:</Header2>
            </div>
            <div className="flex mt-4">
            <ul className="relative mb-6 mx-auto w-full overflow-x-auto">
                <AccordionHeaderInput  title={"Customer Information"} onChange={changeActive} index={0} length={2} active={active}>
                    <div className={`${width<= 640?"flex flex-col":"grid grid-cols-4 gap-4 gap-x-10 w-full"} pb-2`}>
                <TextInputLabeled name={"Title:"} id={"title"} onChange={onChangeDetail} value={detail.title}/>
                <TextInputLabeled name={"First Name:"} id={"firstName"} onChange={onChangeDetail} value={detail.firstName}/>
                <TextInputLabeled name={"Surname:"} id={"lastName"} onChange={onChangeDetail} value={detail.lastName}/>
                <TextInputLabeled name={"Email Address:"} id={"email"} onChange={onChangeContact} value={contact.email}/>
                <TextInputLabeled name={"Contact Number:"} id={"tel"} onChange={onChangeContact} value={contact.tel}/>
                <TextInputLabeled name={"Alt Contact Number:"} id={"altTel"} onChange={onChangeContact} value={contact.altTel}/>
                <TextInputLabeled name={"Mobile Number:"} value={contact.mobileNumber}/>
                <TextInputLabeled name={"UCRN:"} value={""} readonly/>
                <div className=" col-span-2">
                <TextInputLabeled name={"Address:"} id={"Address"} onChange={onChangeAddress} value={address.addressL1 + "," + address.addressL2 + "," + address.addressL3 + "," + address.addressL4 + "," + address.addressL5}/>
                </div>
                <TextInputLabeled name={"Postcode:"} id={"postcode"} value={address.postcode} onChange={onChangePostcode}/>
                <TextInputLabeled name={"Last Sales Exec:"} value={cust.lastSalesExec}/>
                </div>
                </AccordionHeaderInput>
                <AccordionHeaderInput  title={"Customer Vehicle Information"} onChange={changeActive} index={1} length={2} active={active}>
                    <div className={`${width<= 640?"flex flex-col":"grid grid-cols-4 gap-4 gap-x-10 w-full"} pb-2`}>
                <TextInputLabeled name={"Registration:"} id={"registration"} onChange={onChangeVehicle} value={vehiclea.registration}/>
                <TextInputLabeled name={"Make:"} id={"make"} onChange={onChangeVehicle} value={vehiclea.make}/>
                <TextInputLabeled name={"Model:"} id={"model"} onChange={onChangeVehicle} value={vehiclea.model}/>
                <TextInputLabeled name={"Registration Date:"}  onChange={onChangeVehicle} value={new Date(vehiclea.regDate).toDateString()}/>
                <TextInputLabeled readonly name={"Delivery Date:"}  value={dateStrings.delivery}/>
                <TextInputLabeled readonly name={"Invoice Date:"} value={dateStrings.offerCompleted}/>
                <div>
            <p  className={` pb-1 text-sm ${dark ? "text-neutral-300" : "text-neutral-300"}`}>MOT Expiry Date:</p>
        <p className={`${dark ? "bg-neutral-800 text-white" : "bg-neutral-100 text-neutral-900" } ${dateStrings.mot !== null ? dateStrings.mot >= today ? "border-red-1000" : "border-green-1000" : "border-neutral-300"} py-2 px-6 border text-sm rounded-lg focus:outline-none focus:outline-accent block w-full`}  >
        {dateStrings.mot === null ? "Unknown":dateStrings.mot}
        </p>
    </div>
    <div>
            <p  className={` pb-1 text-sm ${dark ? "text-neutral-300" : "text-neutral-300"}`}>Next Service Date:</p>
            <p className={`${dark ? "bg-neutral-800 text-white" : "bg-neutral-100 text-neutral-900" } ${dateStrings.service !== null ? dateStrings.service >= today ? "border-red-1000" : "border-green-1000" : "border-neutral-300"} py-2 px-6 border text-sm rounded-lg focus:outline-none focus:outline-accent block w-full`}  >
        {dateStrings.service === null ? "Unknown":dateStrings.service}
        </p>
            </div>
                </div>
                </AccordionHeaderInput>
            </ul>
            </div>
            <div className=" border-t border-neutral-300 mt-6">
                <div className="mt-6">
            <p className={`mt-2 font-semibold  ${dark?"text-neutral-300":"text-neutral-600"}`}>If the customer wishes to opt-out of future communications, please tick the relevant box.</p>
            </div></div>
            <div className="flex justify-start mt-6 space-x-8">
            <div >
                <p className={`font-semibold w-max ${dark? "text-white":"text-black"}`}>Correspondence options:</p>
                <SquareRadio name={"Email"} boxCol={"border-green-600"} crossCol={"text-green-600"} onClick={filterControl}/>
                <div className="w-min">
                <SquareRadio name={"Sms"} boxCol={"border-green-600"} crossCol={"text-green-600"} onClick={filterControl}/></div>
                <div className="w-min">
                <SquareRadio name={"Phone"} boxCol={"border-green-600"} crossCol={"text-green-600"} onClick={filterControl}/></div>
                <div className="w-min">
                <SquareRadio name={"Post"} boxCol={"border-green-600"} crossCol={"text-green-600"} onClick={filterControl}/></div>
            </div>
            <div >
                <p className={`font-semibold w-max pr-4 ${dark? "text-neutral-100":"text-black"}`}>Additional options:</p>
                <SquareRadio name={"Deceased"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
                <SquareRadio name={"Incorrect information"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
                <SquareRadio name={"Not eligible"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
                <SquareRadio name={"Interest at a later date"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
                <SquareRadio name={"No longer wants marketing"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
                <SquareRadio name={"Sold vehicle"} boxCol={"border-red-600"} crossCol={"text-red-600"} onClick={filterControl}/>
            </div>
            </div>
            <p className={`mt-2 pb-2 font-semibold ${dark?"text-neutral-300":"text-neutral-600"}`}>Add Comments:</p>
            <LargeTextBox/>
    </CardCentered>
    </div>
    </div>
    )
}

export default Outbound;