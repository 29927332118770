import Campaigns from "../components/Campaigns/Campaigns";
import TransitionWrapper from "../components/TransitionWrapper";

function CampaignsPage({ show }) {
    return (
        <TransitionWrapper show={show}>
            <Campaigns />
        </TransitionWrapper>
    );
}

export default CampaignsPage;