import { useState } from "react";

function InformationIcon({info}){
    const [open,setOpen] = useState(false)

    function mouseEnter(){
        setOpen(true);
    }

    function mouseExit(){
        setOpen(false);
    }

    return(
        <div className="relative w-full">
        <div className="absolute bottom-0 pointer-events-none">
        <div className="flex flex-col justify-between pointer-events-none">
        <div className={`pointer-events-none z-50 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm ${!open&&"invisible opacity-0"}`}>
        {info}
        <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseExit} className=" w-full h-full pointer-events-auto">
        <svg fill="none" viewBox="-5 -5 30 30" strokeWidth={1.5} className="w-6 h-6 flex justify-center">
            <path className={`stroke-red-1000 w-6 h-6`}  strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
        </div>
        </div>
        </div>
        </div>
        )
}

export default InformationIcon;