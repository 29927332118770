import DashboardStats from "../components/DashboardStats";
import FourCharts from "../components/Graphs/FourCharts";
import TransitionWrapper from "../components/TransitionWrapper";
import { useSelector,useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashboardFilter from "../components/DashboardFilter";
import { groupBy } from "../hooks/groupBy";

function Dashboard({ show, stop }) {
  const tableData = useSelector((state) => state.prospecting.items);
  const camps = useSelector((state) => state.campaign.campaigns);
  const callHistory = useSelector((state) => state.customers.callHistory);
  const outcomes = useSelector((state) => state.customers.outcomes);
  const reports = useSelector((state) => state.reporting.counts);
  const chLoaded = useSelector((state) => state.customers.loaded);
  const campLoaded = useSelector((state) => state.campaign.loaded);
  const location = useLocation();
  const [history, setHistory] = useState([{name:"",num:0,custCount:0}]);
  const [callOutcomes, setCallOutcomes] = useState([])

  //call outcome colours
  const colours = ["#FF983B", "#AA3A39", "#FE4E4E", "#EE5397", "#634490", "#3466A5", "#00C4D4", "#AFC1CC"]

  //call outcome chart logic

  useEffect(()=>{
    if(outcomes.length!==0){
      const group = groupBy(callHistory, "callOutcomeId");
      setCallOutcomes(outcomes.map((g, index) => { return { name: g.name, id: g.id, value: (group[g.id] !== undefined ? group[g.id].length : 0), itemStyle: { color: colours[index] } } }))
    }
  },[outcomes])

  //campaign chart logic
  useEffect(() => {
    if(reports.length !== 0 ){
        const list = [];
        camps.forEach(element => {
          const p = tableData.filter(t => t.campaignId === element.id);
          p.forEach(e => {
            const c = callOutcomes.filter(f => f.customerId === e.customerId);
            list.push({id:e.campaignId,callCount:c.length});
          });
        });
    
        setHistory(list);
  }
}, [camps, tableData, reports])

//stop showing page transition
  useEffect(() => {
    if (location.pathname !== "/auth") {
      const interval = setInterval(() => {
        stop();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [location, stop])

  //prospects chart logic
  const nums = tableData.map(item => item.points);

  const max = Math.max(...nums);

  const cold = tableData.filter(item => item.points <= Math.ceil(max / 4)).length;
  const warm = tableData.filter(item => item.points > Math.ceil(max / 4) && item.points < Math.ceil(max / 4) + Math.ceil(max / 2)).length;
  const hot = tableData.filter(item => item.points >= Math.ceil(max / 4) + Math.ceil(max / 2)).length;

  //stats
  const [currentCallHistory, setCurrentCallHistory] = useState([]);

  useEffect(()=>{
    if(callHistory.length > 0){
      const ch = callHistory.filter(c => new Date(c.createdAt).getMonth() === new Date().getMonth() && c.outbound === true);
      setCurrentCallHistory(ch);
    }
  },[callHistory])
  
  const [inboundCalls, setInboundCalls] = useState([]);

  useEffect(()=>{
    if(callHistory.length > 0){
      const ch = callHistory.filter(c => new Date(c.createdAt).getMonth() === new Date().getMonth() && c.outbound === false);
      setInboundCalls(ch);
    }
  },[callHistory])

  const [successfulOutbound, setsuccessfulOutbound] = useState(0)
  const [appointmentBooked, setappointmentBooked] = useState(0)

  useEffect(()=>{
    if(callOutcomes.length!== 0 && callHistory.length !== 0){
      const ch = callHistory.filter(c => new Date(c.createdAt).getMonth() === new Date().getMonth());
      const success = callOutcomes.find(c => c.name === "Success");
      const outbound = callHistory.filter(c => new Date(c.createdAt).getMonth() === new Date().getMonth() && c.outbound === true);
      const sch = outbound.filter(s => s.callOutcomeId === success.id);
      const app = callOutcomes.find(c => c.name === "Appointment Booked");
      const ach = ch.filter(s => s.callOutcomeId === app.id);
      const oach = outbound.filter(s => s.callOutcomeId === app.id);
      if(oach.length !== 0 && sch.length!==0 && success!==undefined){
        setsuccessfulOutbound(sch.length + oach.length);
      }
      if(ach.length !== 0 && app !== undefined){
        setappointmentBooked(ach.length);
      }
    }
  },[callOutcomes, callHistory])

  const stats = [{ name: "Outbound call attempts", number: currentCallHistory.length, time: null }, { name: "Outbound calls completed", number: successfulOutbound, time: null }, { name: "Appointments made", number: appointmentBooked, time: null },{ name: "Inbound calls", number: inboundCalls.length, time: null }]

  //call outcome props
  
  const [callOutcomesGraph, setCallOutcomesGraph] = useState([])

  useEffect(()=>{
    if(callHistory.length > 0 && callOutcomes.length > 0){
      const ch = callHistory.filter(c => new Date(c.createdAt).getMonth() === new Date().getMonth());
      const out = ch.map(c => c.callOutcomeId);
      const included = callOutcomes.filter(u => out.includes(u.id));
      const list = [];
      included.forEach(element => {
        const len = ch.filter(c => c.callOutcomeId === element.id);
        const obj = {name:element.name,id:element.id,value:len.length, itemStyle:element.itemStyle};
        list.push(obj);
      });
      setCallOutcomesGraph(list);
    }
  },[callHistory, callOutcomes])

  const LiveCalls = {
    title: "Call Outcomes",
    data: callOutcomesGraph,
    maxValue: callOutcomes.length,
    loaded:chLoaded
  }

  //prospecting props
  const LeadsRing = {
    title: "Leads",
    data: [{ value: cold, name: "Cold Leads", itemStyle: { color: "#34AAFF" } }, { value: warm, name: "Warm Leads", itemStyle: { color: "#FEC400" } }, { value: hot, name: "Hot Leads", itemStyle: { color: "#F12B2C" } }],
    maxValue: tableData.length,
    loaded:chLoaded
  }
  
  //campaign chart props
  const n = reports.map(r => r.nums);

  const callCampaigns = camps.map(c => {return{name:c.name, num:reports.filter(f => f.id === c.id).length!==0?reports.find(f => f.id === c.id).num:0, custCount: history.filter(l => l.id === c.id).length!== 0?history.find(l => l.id === c.id).callCount:0}});

  const DUMMY_BAR = {
    yAxis: { data: callCampaigns.map(c => c.name) },
    xAxis: {
      type: 'value',
      minInterval: Math.max(n),
    },
    title: "Calls",
    callsMade: {
      name: 'Calls made',
      type: 'bar',
      stack: 'total',
      itemStyle: {
        color: "#455A64",
      },
      label: {
        show: true

      },
      emphasis: {
        focus: 'series'
      },
      data: callCampaigns.map(c => c.custCount)
    },
    callsToMake:
    {
      name: 'Calls to make',
      type: 'bar',
      stack: 'total',
      itemStyle: {
        color: "#B0BEC5",
        borderRadius: [0, 50, 50, 0],
      },
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: callCampaigns.map(c => c.num) 
    },
    barColour: "rgba(160, 29, 34, 1)",
    loaded:campLoaded
  }

  //change time frame
  const timeFrame = [{ id: 0, name: "This Week" }, { id: 1, name: "This Month" }, { id: 2, name: "This Year" }, { id: 3, name: "All Time" }];


  return (
    <TransitionWrapper show={show}>
      <div className="mx-auto max-w-7xl flex justify-start">
        <DashboardFilter timeFrame={timeFrame} />
      </div>
      <DashboardStats stats={stats} loaded={chLoaded}/>
      <FourCharts bar1={DUMMY_BAR} ring1={LiveCalls} ring2={LeadsRing} />
    </TransitionWrapper>
  )
}

export default Dashboard;