import { currentUserActions } from "../store/currentUser-slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SmallDropdown from "./UI/SmallDropdown";

function SiteSwitcher() {
    const userSites = useSelector((state) => state.currentUser.site);
    const sites = useSelector((state) => state.organisation.sites.filter((s) => userSites.includes(s.id)), shallowEqual);
    const cur = useSelector((state) => state.currentUser.currentSite);
    const dispatch = useDispatch();

    const items = sites.map((i) => ({ name: i.name, id: i.id, key: i.id, value: i.id }));

    const sorted = items.sort(function (x, y) { return x.id === cur ? -1 : y.id === cur ? 1 : 0; });

    function changeSite(site) {
            dispatch(currentUserActions.changeCurrentSite(site.value));
    }
    
    return (
        <SmallDropdown options={sorted} onChange={changeSite} />
    );
}

export default SiteSwitcher;