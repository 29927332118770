import TableColumn from "./TableColumn";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function TableHeader({ columns, onClick, sortConfig, multiple }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const fixedColumns = columns.map((col) => (
    <TableColumn
      name={col.name}
      id={col.id}
      onClick={onClick}
      sortConfig={sortConfig}
      key={col.id}
      multiple={multiple}
    />
  ));

  return (
    <thead className={` border-b-2 ${dark ? "bg-neutral-900 border-neutral-300" : "bg-white border-neutral-200"}`}>
      <tr>
        {fixedColumns}
      </tr>
    </thead>
  );
}

export default TableHeader;
