import { useContext } from "react";
import {ThemeContext} from "../../context/ThemeContext"

function FilterButton({openFilter}){
    const theme = useContext(ThemeContext);
    const {dark} = theme;

    return(
        <button
          type="button"
          className={`${
            dark ? "text-white " : "text-neutral-900"
          }focus:outline-none font-medium rounded-lg  right-0 p-5 text-center inline-flex items-center mr-2`}
          data-modal-target="filterModal"
          data-modal-toggle="filterModal"
          onClick={openFilter}
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="current" xmlns="http://www.w3.org/2000/svg" className={`fill-accent`}>
            <path d="M11.4369 0H0.563154C0.0635131 0 -0.188604 0.606234 0.165419 0.960258L4.5 5.29549V10.125C4.5 10.3085 4.58955 10.4805 4.73993 10.5858L6.61493 11.8979C6.98484 12.1568 7.5 11.8944 7.5 11.437V5.29549L11.8347 0.960258C12.188 0.606937 11.9376 0 11.4369 0Z"/>
          </svg>
          <p className={`${dark? "text-neutral-300":"text-neutral-300"} pl-2 w-full tracking-normal`}>Filter</p>
        </button>
    )
}

export default FilterButton;