import ProgressBar from "../UI/ProgressBar";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import LinkDropDown from "../UI/LinkDropDown";

function CampProgress({ name, data, width, progress }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;
  const [option, setOption] = useState(name);
  const [prog, setProg] = useState(progress);

  const options = [{
    name: "View Report",
    id: name
  }];

  function changeController(e) {
    setOption(e.value);
  }

  useEffect(() => {
    setProg(progress);
  }, [progress])

  return (
    <div className={`${width <= 640 ? "" : "w-2/6"}`}>
      <p className={`${dark ? "text-white" : "text-neutral-900"} text-sm pt-1 pb-0.5`}>Progress:</p>
      <ProgressBar percentage={prog} colour={"bg-green-1000"} />
      <div className="flex space-4 py-2 justify-between">
        <div
          id="sent"
          className={`${dark ? "text-neutral-100 bg-neutral-900" : "text-neutral-700 bg-white"} border-green-1000 border cursor-default  rounded-lg text-sm px-5 py-1 h-min text-center mr-2 `}>
          Sent
        </div>
        <LinkDropDown options={options} data={data} onChange={changeController} />
      </div>
    </div>
  )
}

export default CampProgress;