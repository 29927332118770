import EChartsReact from "echarts-for-react";
import RingKey from "./RingKey";
import { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function DonutChart({ data, colour, maxValue }) {
  const theme = useContext(ThemeContext);
  const { dark } = theme;

  const keyItems = data.map(d => {return{name:d.name, icon:'circle'}})

  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      padding: [20, 20],
      orient: 'vertical',
      itemGap: 15,
      top: 'center',
      left: '50%',
      height: '150px',
      data: keyItems,
      textStyle: {
        color: dark ? "#FFFFFF" : "#000000",
        fontSize: 12
      }
    },
    series: [
      {
        name: 'Outcomes',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        center: ['25%','50%'],
        emptyCircleStyle: {
          color: dark ? "#202125" : "#E9ECEF",
        },
        label: {
          show: false,
          position: 'center'

        },
        emphasis: {
          label: {
            show: true,
            fontSize: 18,
            fontWeight: 'bold',
            color: dark ? "#FFFFFF" : "#000000",
          }
        },
        labelLine: {
          show: false
        },
        data: data
      }
    ]
  };

  const key = data.reverse().map((d) => (
    <li key={d.name}>
      <RingKey
        name={d.name}
        colour={
          d.itemStyle.color !== "auto" ? d.itemStyle.color : option.color[0]
        }
        amount={d.value}
        dark={dark}
      />
    </li>
  ));
  return (
    <div className="flex items-center justify-start flex-shrink mx-auto w-full h-full">
      <EChartsReact
        option={option}
        opts={{ renderer: "svg" }}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </div>
  )
}

export default DonutChart;