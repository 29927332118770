import { createSlice } from "@reduxjs/toolkit";

const initialBookingState = {
    services: [],
    timeSlots: [],
    removedTimes: [],
    currentAppointment:{},
    foundAppointment:{}
}

const bookingSlice = createSlice({
    name: 'booking',
    initialState: initialBookingState,
    reducers: {
        setServices(state, action) {
            state.services = action.payload;
        },
        setTimeSlots(state, action) {
            state.timeSlots = action.payload;
        },
        setCurrentAppointment(state, action) {
            state.currentAppointment = action.payload;
        },
        findAppointment(state,actions){
            state.foundAppointment = actions.payload.app;
        }
    }
});

export const bookingActions = bookingSlice.actions;
export default bookingSlice;